import { LeagueType } from '../../types';
import { useCurrentSeason } from '../useCurrentSeason';

import useSplitGlobalId from './useSplitGlobalId';

const TEAMS_URL = `https://img.sportradar.com/ls/teams/ap250x188ut/{srGlobalId}.png`;
const CREST_URL = `https://img.sportradar.com/ls/crest/medium/{srGlobalId}.png`;

const LEAGUE_URL_MAP: Partial<Record<LeagueType, string>> = {
    cfb: TEAMS_URL,
    ncaafb: TEAMS_URL,
    nfl: CREST_URL,
    nba: CREST_URL,
    nhl: CREST_URL,
    ncaamb: TEAMS_URL,
    ncaawb: TEAMS_URL,
};

export default function useTeamCrest(teamGlobalId?: string | null) {
    const { league } = useCurrentSeason();
    const splitId = useSplitGlobalId(teamGlobalId);

    return (LEAGUE_URL_MAP[league] ?? TEAMS_URL).replace('{srGlobalId}', splitId ?? '');
}
