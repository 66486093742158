export class SportsMediaAuthError extends Error {
    constructor(cause?: any, message: string = 'error') {
        super();
        this.cause = cause;
        this.message = message;
    }
}

export class SportsMediaRefreshLoginFailed extends SportsMediaAuthError {}
export class SportsMediaRefreshLogoutFailed extends SportsMediaAuthError {}
export class SportsMediaRefreshAuthenticationFailed extends SportsMediaAuthError {}
