import DefaultGameCard from './basic';
import { GameCardProps } from './types';

const GameCard = (props: GameCardProps) => {
    switch (props.league) {
        case 'cfb':
        case 'ncaafb':
        case 'ncaawb':
        case 'nfl':
            return <DefaultGameCard {...props} minColumnCount={4} />;
        case 'ncaamb':
            return <DefaultGameCard {...props} minColumnCount={2} />;
        default:
            throw new Error('Given league is not yet supported');
    }
};

export default GameCard;
