import { StatusCodes } from 'http-status-codes';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import useLeagueDetails from 'app/useLeagueDetails';
import { SourceId } from 'types';

import { DirectLinkURLs } from '../../../types';

import { directLinksKeys } from './queries';

const isDirectLinksEnabled = (sources: SourceId[]): boolean =>
    sources.includes(SourceId.AWAY) || sources.includes(SourceId.HOME);

async function getDirectLinks(league: string, gameId: string) {
    type Data = {
        homeUrl: string | null;
        awayUrl: string | null;
    };

    const response = await client.get<Data>(`${API.MAPPINGS_API}/${league}/direct-links/${gameId}`);

    return response.data;
}

export type DirectLinksMutationParams = {
    league: string;
    seasonYear: string;
    seasonType: string;
    gameId: string;
    urls: DirectLinkURLs;
};

function updateDirectLinks({
    league,
    seasonYear,
    seasonType,
    gameId,
    urls,
}: DirectLinksMutationParams) {
    return client.put(
        `${API.MAPPINGS_API}/${league}/${seasonYear}/${seasonType}/direct-links/${gameId}`,
        urls,
    );
}

export function useDirectLinks(league: string, gameId: string) {
    const { sources } = useLeagueDetails(league);

    return useQuery(
        directLinksKeys.directLinksByLeagueAndGameId(league, gameId),
        () => getDirectLinks(league, gameId),
        {
            refetchOnWindowFocus: false,
            enabled: isDirectLinksEnabled(sources),
        },
    );
}

export function useDirectLinksMutation(league: string, gameId: string) {
    const queryClient = useQueryClient();

    return useMutation(updateDirectLinks, {
        onSuccess: (data) => {
            if (data.status === StatusCodes.OK) {
                queryClient.refetchQueries(
                    directLinksKeys.directLinksByLeagueAndGameId(league, gameId),
                );
            }
        },
        onError: async (error) => {
            console.error(error);
        },
        retry: 2,
    });
}
