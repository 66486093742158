import * as React from 'react';

import { Cell } from './types';

function useLabelCells(columnCount: number): Cell[] {
    return React.useMemo(() => {
        const firstCell = { key: 'start' };
        const lastCell = { key: 'total', value: 'T' };
        const labelCells: Cell[] = [];

        for (let index = 0; index < columnCount; index++) {
            labelCells.push({
                key: `col-label-${index + 1}`,
                value: index + 1,
            });
        }

        return [firstCell, ...labelCells, lastCell];
    }, [columnCount]);
}

export default useLabelCells;
