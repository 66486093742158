import * as React from 'react';

import { Cell, Score } from './types';

function useTeamCells(
    alias: string,
    scores: Score[],
    totalScore: Score,
    columnCount: number,
): Cell[] {
    return React.useMemo(() => {
        const firstCell = { key: 'team-alias', value: alias };
        const lastCell = { key: 'team-total', ...totalScore };
        const scoreCells: Cell[] = [];

        for (let index = 0; index < columnCount; index++) {
            const hasScore = index < scores.length;

            scoreCells.push({
                ...(hasScore && scores[index]),
                key: `score-section-${index}`,
            });
        }

        return [firstCell, ...scoreCells, lastCell];
    }, [alias, scores, totalScore, columnCount]);
}

export default useTeamCells;
