import styled from '@emotion/styled';

import { DialogButtons } from 'components/dialog/styles';

export const SubmitDialogContainer = styled.div`
    position: fixed;
    width: 52.125rem;
    height: 3.875rem;
    top: 71.375%;
    background-color: var(--color-team-mappings-submit-dialog-background);

    display: grid;
    grid-template-columns: 1fr 1fr;

    border-radius: 0.3125rem;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: var(--color-team-mappings-submit-dialog-border);

    padding: 0.9375rem 1.0625rem;

    & > .count-indicator-label {
        padding-top: 0.5625rem;
        padding-left: 1.0625rem;

        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: var(--color-team-mappings-submit-dialog-counter-label);
    }

    ${DialogButtons} {
        margin-top: 0;

        button {
            color: var(--color-team-mappings-submit-dialog-button-label);
        }

        & > .submit {
            border-color: var(--color-team-mappings-submit-dialog-button-border);
        }
    }
`;
