import { QueryClient } from 'react-query';

import { SystemEvents } from '../../api/types/system-events';
import { SystemEventsKeys } from '../../components/tasks/queries';
import { WSBackendEvent } from '../types';

enum SystemEventTopicTypes {
    NEW_EVENT = 'NEW_EVENT',
}

export const systemEventsProcessor = (queryClient: QueryClient, payload: object) => {
    const systemEventMessage = payload as WSBackendEvent<SystemEvents.SystemEvent>;

    if (systemEventMessage.type !== SystemEventTopicTypes.NEW_EVENT) {
        console.warn('systemEventsProcessor: unknown message type: %s', systemEventMessage.type);

        return;
    }

    const upsertSystemEvent = (oldData: SystemEvents.SystemEvent[] | undefined) => {
        if (!oldData) {
            return [systemEventMessage.payload];
        }

        const existingIndex = oldData.findIndex(
            (se) =>
                se.pk === systemEventMessage.payload.pk && se.sk === systemEventMessage.payload.sk,
        );

        if (existingIndex !== -1) {
            oldData.splice(existingIndex, 1, systemEventMessage.payload);

            return [...oldData];
        }

        return [systemEventMessage.payload, ...oldData];
    };

    queryClient.setQueryData(
        SystemEventsKeys.getSystemEvents(),
        (oldData: SystemEvents.SystemEvent[] | undefined) => {
            return upsertSystemEvent(oldData);
        },
    );

    queryClient.setQueryData(
        SystemEventsKeys.getSystemEvents(systemEventMessage.payload.pk),
        (oldData: SystemEvents.SystemEvent[] | undefined) => {
            return upsertSystemEvent(oldData);
        },
    );
};
