import * as React from 'react';

import { useColorSchemeConfig } from '../../../app/theme/useColorScheme';
import { IconDecorated } from '../../icons';

import { CheckboxUnchecked, CheckedWrapper } from './styles';

type CheckboxProps = {
    checked: boolean;
    onChange: () => void;
};

const Checkbox = ({ checked, onChange }: CheckboxProps) => {
    const [colorScheme] = useColorSchemeConfig();

    return checked ? (
        <CheckedWrapper>
            <IconDecorated
                onClick={onChange}
                sizeRem={0.7}
                name="CheckRounded"
                paletteColor={colorScheme === 'light' ? 'blue500' : 'blue300'}
            />
        </CheckedWrapper>
    ) : (
        <CheckboxUnchecked onClick={onChange} isDark={colorScheme === 'dark'} />
    );
};

export default Checkbox;
