import { routes } from 'app/router';
import { Icon } from 'components/icons';
import LeagueLogo from 'components/league-logo';
import useGamesGroupingStrategyLabel from 'hooks/useGameGroupingLabel';
import { FlagType, LeagueDetails, SeasonType } from 'types';

import { LeagueItemContainer } from './styles';

type Props = {
    leagueDetails: LeagueDetails;
};

const LeagueItem = ({ leagueDetails }: Props) => {
    const gamesLabel = useGamesGroupingStrategyLabel(leagueDetails.league);

    const seasonYear =
        leagueDetails.seasons.find(({ isCurrent }) => !!isCurrent)?.seasonYear ??
        leagueDetails.seasons[leagueDetails.seasons.length - 1].seasonYear;

    const leagueLinkProps = {
        league: leagueDetails.league,
        seasonType: SeasonType.Regular,
        seasonYear,
    };

    const supportsFlagType = (flagType: FlagType) => leagueDetails.flagTypes.includes(flagType);

    return (
        <LeagueItemContainer data-testid="league-item">
            <a
                {...routes.dashboard(leagueLinkProps).link}
                className="league-item league-item-header"
            >
                <div className="logo-container">
                    <LeagueLogo league={leagueDetails.league} />
                </div>
                <span className="title">{leagueDetails.name}</span>
            </a>
            <ul className="link-list">
                {supportsFlagType(FlagType.Boxscore) && (
                    <li className="link-item league-item">
                        <a {...routes.gameBoxscoresGames(leagueLinkProps).link}>
                            {gamesLabel}
                            <div className="link-icon-container">
                                <Icon name="ArrowRightAlt" />
                            </div>
                        </a>
                    </li>
                )}
                <li className="link-item league-item">
                    <a {...routes.gameBoxscores(leagueLinkProps).link}>
                        Post-Game
                        <div className="link-icon-container">
                            <Icon name="ArrowRightAlt" />
                        </div>
                    </a>
                </li>
                {supportsFlagType(FlagType.Seasonal) && (
                    <li className="link-item league-item">
                        <a {...routes.rosters(leagueLinkProps).link}>
                            Rosters
                            <div className="link-icon-container">
                                <Icon name="ArrowRightAlt" />
                            </div>
                        </a>
                    </li>
                )}
                {supportsFlagType(FlagType.Seasonal) && (
                    <li className="link-item league-item">
                        <a {...routes.seasonal(leagueLinkProps).link}>
                            Seasonals
                            <div className="link-icon-container">
                                <Icon name="ArrowRightAlt" />
                            </div>
                        </a>
                    </li>
                )}
                {supportsFlagType(FlagType.Schedule) && (
                    <li className="link-item league-item">
                        <a {...routes.schedule(leagueLinkProps).link}>
                            Schedule
                            <div className="link-icon-container">
                                <Icon name="ArrowRightAlt" />
                            </div>
                        </a>
                    </li>
                )}
                {supportsFlagType(FlagType.Standings) && (
                    <li className="link-item league-item">
                        <a {...routes.standings(leagueLinkProps).link}>
                            Standings
                            <div className="link-icon-container">
                                <Icon name="ArrowRightAlt" />
                            </div>
                        </a>
                    </li>
                )}
                {supportsFlagType(FlagType.Polls) && (
                    <li className="link-item league-item">
                        <a {...routes.polls(leagueLinkProps).link}>
                            Poll Rankings
                            <div className="link-icon-container">
                                <Icon name="ArrowRightAlt" />
                            </div>
                        </a>
                    </li>
                )}
            </ul>
        </LeagueItemContainer>
    );
};

export default LeagueItem;
