import { ReactComponent as IconShield } from 'assets/svg/icon-shield.svg';
import logoGolf from 'assets/svg/logo-golf.svg';
import logoNcaa from 'assets/svg/logo-ncaa.svg';
import logoNfl from 'assets/svg/logo-nfl.svg';
import logoNhl from 'assets/svg/logo-nhl.svg';
import LazyImage from 'components/lazy-image';
import { LeagueType } from 'types';

type LeagueLogoMap = Partial<{ [league in LeagueType]: string }>;

const leagueLogoMap: LeagueLogoMap = {
    cfb: logoNcaa,
    golf: logoGolf,
    ncaamb: logoNcaa,
    ncaawb: logoNcaa,
    nfl: logoNfl,
    nhl: logoNhl,
};

type Props = {
    league: LeagueType;
};

const LeagueLogo = ({ league }: Props) => {
    return (
        <LazyImage
            data-testid="league-logo"
            fallback={<IconShield />}
            src={leagueLogoMap[league]}
        />
    );
};

export default LeagueLogo;
