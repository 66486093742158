import TableCell from './table-cell';
import { Cell } from './types';

type Props = { cells: Cell[] };

const TableRow = ({ cells }: Props) => {
    return (
        <div data-testid="linescore-table-row" className="table-row">
            {cells.map(({ isMarked, key, value }) => (
                <TableCell isMarked={isMarked} key={key} value={value} />
            ))}
        </div>
    );
};

export default TableRow;
