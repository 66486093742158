import * as React from 'react';

import Checkbox from '../../../primitives/checkbox';
import BulkActions from '../../bulk-actions';
import useBulkActions from '../../bulk-actions/hooks/use-bulk-actions';
import Discrepancy from '../../discrepancy';
import { TeamSplitDiscrepancies } from '../types';

import { DiscrepancyRow, DiscrepanciesContainer } from './styles';

interface TeamDiscrepanciesProps {
    title: string;
    alias: string;
    discrepancies: TeamSplitDiscrepancies;
}

const TeamDiscrepancies = ({ title, alias, discrepancies }: TeamDiscrepanciesProps) => {
    const { checkedDiscrepancies, handleToggleCheckDiscrepancy, handleToggleAll, isActive } =
        useBulkActions(
            [...discrepancies.teamDiscrepancies, ...discrepancies.playerDiscrepancies].map(
                (d) => d.id,
            ),
        );

    const isChecked = (id: string) => {
        return checkedDiscrepancies.includes(id);
    };

    if (
        discrepancies.playerDiscrepancies.length === 0 &&
        discrepancies.teamDiscrepancies.length === 0
    ) {
        return null;
    }

    return (
        <DiscrepanciesContainer>
            <div className="header">{title}</div>
            <div className="list">
                <BulkActions
                    isActive={isActive}
                    checkedDiscrepancyIds={checkedDiscrepancies}
                    onToggleSelect={handleToggleAll}
                />
                {discrepancies.teamDiscrepancies.map((discrepancy) => (
                    <DiscrepancyRow
                        key={`discrepancy-${discrepancy.id}`}
                        onClick={() => handleToggleCheckDiscrepancy(discrepancy.id)}
                    >
                        <div className="title-wrapper">
                            <div className="title">{alias}</div>
                            <Checkbox checked={isChecked(discrepancy.id)} onChange={() => {}} />
                        </div>
                        <Discrepancy discrepancy={discrepancy} />
                    </DiscrepancyRow>
                ))}
                {discrepancies.playerDiscrepancies.map((discrepancy) => (
                    <DiscrepancyRow
                        key={`discrepancy-${discrepancy.id}`}
                        onClick={() => handleToggleCheckDiscrepancy(discrepancy.id)}
                    >
                        <div className="title-wrapper">
                            <div className="title">
                                {discrepancy.contextPlayer?.name ?? discrepancy.contextPlayer?.srId}
                            </div>
                            <Checkbox checked={isChecked(discrepancy.id)} onChange={() => {}} />
                        </div>
                        <Discrepancy discrepancy={discrepancy} />
                    </DiscrepancyRow>
                ))}
            </div>
        </DiscrepanciesContainer>
    );
};

export default TeamDiscrepancies;
