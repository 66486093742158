import styled from '@emotion/styled';

export type ColorScheme = 'light' | 'dark';

const colorSchemeStyles = {
    light: {
        borderColor: 'var(--black200)',
        markedBackgroundColor: 'var(--red200)',
    },
    dark: {
        borderColor: 'var(--blue500)',
        markedBackgroundColor: 'rgba(var(--red500-rgb), 0.25)',
    },
};

export const Table = styled.div<{ colorScheme: ColorScheme }>`
    min-width: fit-content;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-transform: uppercase;

    & > .table-row {
        display: flex;
        border-bottom: 0.0625rem solid
            ${({ colorScheme }) => colorSchemeStyles[colorScheme].borderColor};

        &:first-of-type {
            font-weight: 700;

            & > .table-cell {
                border-left-color: transparent;
            }
        }

        & > .table-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.6875rem;
            padding-top: 0.1875rem;
            padding-bottom: 0.1875rem;
            border-left: 0.0625rem solid
                ${({ colorScheme }) => colorSchemeStyles[colorScheme].borderColor};

            &[data-is-marked='true'] {
                background-color: ${({ colorScheme }) =>
                    colorSchemeStyles[colorScheme].markedBackgroundColor};
            }

            &:first-of-type,
            &:last-child {
                font-weight: 700;
            }

            &:first-of-type {
                flex-grow: 1;
                min-width: 2rem;
                width: auto;
                justify-content: flex-start;
                border-left: none;
                padding-right: 0.5rem;
            }
        }
    }
`;
