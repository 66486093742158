import { AttributesContainer, Name, Value } from './styles';

export type Attribute = {
    name: string;
    value: string | number;
};

type Props = {
    attributes: Attribute[];
};

export function Attributes({ attributes }: Props) {
    return (
        <AttributesContainer>
            {attributes.reduce((acc, { name, value }, idx) => {
                acc.push(<Name key={`name-${idx}`}>{name}</Name>);
                acc.push(<Value key={`value-${idx}`}>{value}</Value>);

                return acc;
            }, [] as JSX.Element[])}
        </AttributesContainer>
    );
}
