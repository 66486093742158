import * as React from 'react';

import { LiveFlags } from 'api/types/live-flags';
import Dialog from 'components/dialog';
import Dropdown from 'components/dropdown';
import Textarea from 'components/primitives/textarea';
import { Option } from 'types/controls';

import useUpdateFlag from '../../hooks/useUpdateFlag';
import { Discrepancy, DiscrepancyIgnoreReason } from '../../types';

import { Content, DiscrepancyValuesGrid, IgnoreReason } from './styles';
import SuccessfullyIgnoredModal from './successfully-ignored-modal';

type Props = {
    open: boolean;
    onClose: () => void;
    discrepancy: Discrepancy;
};

const ignoreDropdownOptions: Option<DiscrepancyIgnoreReason>[] = [
    {
        label: 'Calculated Differently',
        value: DiscrepancyIgnoreReason.CALCULATED_DIFFERENTLY,
    },
    {
        label: 'Not Important',
        value: DiscrepancyIgnoreReason.NOT_IMPORTANT,
    },
    {
        label: 'Technical Error',
        value: DiscrepancyIgnoreReason.TECHNICAL_ERROR,
    },
    {
        label: 'Other',
        value: DiscrepancyIgnoreReason.OTHER,
    },
];

const IgnoreDialog = ({ open, onClose, discrepancy }: Props) => {
    const [ignoreOptions, setIgnoreOptions] = React.useState(ignoreDropdownOptions);
    const flagStatusMutation = useUpdateFlag();
    const [successfullyIgnored, setSuccessfullyIgnored] = React.useState(false);
    const ignoreCommentRef = React.useRef<HTMLTextAreaElement>(null);

    const handleChangeIgnoreReason = React.useCallback((option: DiscrepancyIgnoreReason) => {
        setIgnoreOptions((prev) =>
            prev.map((prevOpt) => {
                return {
                    ...prevOpt,
                    selected: prevOpt.value === option,
                };
            }),
        );
    }, []);

    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);

    const handleConfirm = React.useCallback(() => {
        const ignoreOptionSelected = ignoreOptions.find((ignoreOption) => ignoreOption.selected);

        flagStatusMutation.mutate({
            id: discrepancy.id,
            status: LiveFlags.FlagStatus.IGNORED,
            ...(ignoreCommentRef?.current?.value
                ? { ignoreComment: ignoreCommentRef?.current?.value }
                : {}),
            ...(ignoreOptionSelected ? { ignoreReason: ignoreOptionSelected.value } : {}),
        });

        setSuccessfullyIgnored(true);
    }, [discrepancy, flagStatusMutation, ignoreOptions]);

    React.useEffect(() => {
        if (!open) {
            setIgnoreOptions((prev) => prev.map((prevOpt) => ({ ...prevOpt, selected: false })));
            setSuccessfullyIgnored(false);
        }
    }, [open]);

    React.useEffect(() => {
        setIgnoreOptions((prev) =>
            prev.map((prevOpt) => {
                return {
                    ...prevOpt,
                    selected: prevOpt.value === discrepancy?.ignoreReason,
                };
            }),
        );
    }, [discrepancy, open]);

    const displayIgnoreTextBox = ignoreOptions.some(
        (ignoreOption) => ignoreOption.selected === true,
    );

    if (open && successfullyIgnored) {
        return <SuccessfullyIgnoredModal open={open} onClose={onClose} />;
    }

    return (
        <Dialog
            open={open}
            onBackDropClick={onClose}
            title="Ignore"
            confirmButtonLabel="Confirm Ignore"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        >
            <Content>
                <div className="context">
                    {discrepancy.teamName && (
                        <>
                            <div className="title">Team</div>
                            <div className="value">{discrepancy.teamName}</div>
                        </>
                    )}
                    {discrepancy.playerName && (
                        <>
                            <div className="title">Player</div>
                            <div className="value">{discrepancy.playerName}</div>
                        </>
                    )}
                    <div className="title">Name</div>
                    <div className="value">{discrepancy.flagName}</div>
                </div>
                <DiscrepancyValuesGrid>
                    <div className="row">
                        <div className="source --header">Source</div>
                        <div className="value --header">Value</div>
                    </div>
                    <div className="row">
                        <div className="source">Sportradar</div>
                        <div className="value">{discrepancy.expectedValue}</div>
                    </div>
                    <div className="row">
                        <div className="source">External Source</div>
                        <div className="value">{discrepancy.actualValue}</div>
                    </div>
                </DiscrepancyValuesGrid>
                <IgnoreReason>
                    <div className="dropdown-container">
                        <Dropdown
                            options={ignoreOptions}
                            onChange={handleChangeIgnoreReason}
                            label="Reason to Ignore (Optional)"
                        />
                    </div>
                    {displayIgnoreTextBox && (
                        <div className="reason" key="ignore-reason-box">
                            <span className="title">Please write a reason</span>
                            <Textarea
                                ref={ignoreCommentRef}
                                defaultValue={discrepancy?.ignoreComment ?? ''}
                            />
                        </div>
                    )}
                </IgnoreReason>
            </Content>
        </Dialog>
    );
};

export default IgnoreDialog;
