import * as React from 'react';

import { DiscrepancyIgnoreReason, DiscrepancyStatus } from 'api/types/flags';
import Dialog from 'components/dialog';
import Dropdown from 'components/dropdown';
import { Content } from 'components/flag-accordion/discrepancy/ignore-dialog/styles';
import SuccessfullyIgnoredModal from 'components/flag-accordion/discrepancy/ignore-dialog/successfully-ignored-modal';
import ignoreDropdownOptions from 'components/flag-accordion/discrepancy/ignore-dropdown-options';
import Textarea from 'components/primitives/textarea';
import { IgnoreReason } from 'modules/game-boxscores/views/game/components/ignore-dialog/styles';

import useBulkUpdateDiscrepanciesState from '../../bulk-actions/hooks/use-bulk-update-discrepancies-state';

type Props = {
    open: boolean;
    onClose: () => void;
    discrepancyIds: Array<string>;
};

const BatchIgnoreDialog = ({ open, onClose, discrepancyIds }: Props) => {
    const [ignoreOptions, setIgnoreOptions] = React.useState(ignoreDropdownOptions);
    const [successfullyIgnored, setSuccessfullyIgnored] = React.useState(false);
    const ignoreCommentRef = React.useRef<HTMLTextAreaElement>(null);
    const discrepancyStateMutation = useBulkUpdateDiscrepanciesState();

    const handleChangeIgnoreReason = React.useCallback((option: DiscrepancyIgnoreReason) => {
        setIgnoreOptions((prev) =>
            prev.map((prevOpt) => {
                return {
                    ...prevOpt,
                    selected: prevOpt.value === option,
                };
            }),
        );
    }, []);

    const handleOnClose = React.useCallback(() => {
        setSuccessfullyIgnored(false);
        onClose();
    }, [onClose]);

    const handleConfirm = React.useCallback(() => {
        const ignoreOptionSelected = ignoreOptions.find((ignoreOption) => ignoreOption.selected);

        discrepancyStateMutation.mutate({
            ids: discrepancyIds,
            status: DiscrepancyStatus.IGNORED,
            ...(ignoreCommentRef?.current?.value
                ? { ignoreComment: ignoreCommentRef?.current?.value }
                : {}),
            ...(ignoreOptionSelected ? { ignoreReason: ignoreOptionSelected.value } : {}),
        });

        setSuccessfullyIgnored(true);
    }, [discrepancyIds, discrepancyStateMutation, ignoreOptions]);

    const displayIgnoreTextBox = ignoreOptions.some(
        (ignoreOption) => ignoreOption.selected === true,
    );

    if (open && successfullyIgnored) {
        return <SuccessfullyIgnoredModal open={open} onClose={handleOnClose} />;
    }

    return (
        <Dialog
            open={open}
            onBackDropClick={onClose}
            title="Ignore"
            confirmButtonLabel="Confirm Ignore"
            onConfirm={handleConfirm}
            onCancel={handleOnClose}
        >
            <Content>
                <IgnoreReason>
                    <div>
                        You are about to ignore <b>{discrepancyIds.length}</b> discrepanc
                        {discrepancyIds.length > 1 ? 'ies' : 'y'}, please provide a reason and
                        confirm
                    </div>
                    <div className="dropdown-container">
                        <Dropdown
                            options={ignoreOptions}
                            onChange={handleChangeIgnoreReason}
                            label="Reason to Ignore (Optional)"
                        />
                    </div>
                    {displayIgnoreTextBox && (
                        <div className="reason" key="ignore-reason-box">
                            <span className="title">Please write a reason</span>
                            <Textarea ref={ignoreCommentRef} defaultValue={''} />
                        </div>
                    )}
                </IgnoreReason>
            </Content>
        </Dialog>
    );
};

export default BatchIgnoreDialog;
