import React from 'react';

type Props = {
    isMarked?: boolean;
    value?: number | string;
};

const TableCell = ({ isMarked, value }: Props) => {
    return (
        <div className="table-cell" data-is-marked={isMarked}>
            {value}
        </div>
    );
};

export default TableCell;
