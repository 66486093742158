import { DiscrepancyIgnoreReason } from '../../../api/types/flags';
import { Option } from '../../../types/controls';

const ignoreDropdownOptions: Option<DiscrepancyIgnoreReason>[] = [
    {
        label: 'Calculated Differently',
        value: DiscrepancyIgnoreReason.CALCULATED_DIFFERENTLY,
    },
    {
        label: 'Not Important',
        value: DiscrepancyIgnoreReason.NOT_IMPORTANT,
    },
    {
        label: 'Technical Error',
        value: DiscrepancyIgnoreReason.TECHNICAL_ERROR,
    },
    {
        label: 'Other',
        value: DiscrepancyIgnoreReason.OTHER,
    },
];

export default ignoreDropdownOptions;
