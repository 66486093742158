import styled from '@emotion/styled';

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const DialogTitle = styled.div`
    font-size: 1.125rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    border-bottom: 0.0625rem solid var(--gray300);
    margin-bottom: 0.875rem;
`;

export const DialogContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const DialogButtons = styled.div`
    display: flex;
    gap: 0.5rem;
    margin-top: 1.5rem;
    justify-content: flex-end;
`;
