import { useCurrentSeason } from './useCurrentSeason';
import useLeagueDetails from './useLeagueDetails';

function useCurrentLeagueDetails() {
    const { league } = useCurrentSeason();
    const leagueDetails = useLeagueDetails(league);

    return leagueDetails;
}

export default useCurrentLeagueDetails;
