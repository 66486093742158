import { mqtt5 } from 'aws-iot-device-sdk-v2';
import dayjs from 'dayjs';
import * as React from 'react';

import { SystemEvents } from 'api/types/system-events';
import { useSubscribeTopicLazy } from 'app/useSubscribeTopic';
import Chip from 'components/chip';

import { AccordionDetails, AccordionSummary, Accordion } from '../accordion';
import { IconDecorated } from '../icons';
import Loading from '../loading';
import Text from '../primitives/text';

import useGroupTasks, { GroupedTask, Task } from './hooks/useGroupTasks';
import { GroupTaskWrapper, TaskContainer, TaskDetailsContainer } from './styles';

type Props = {
    onOpenTask?: (gt: GroupedTask) => void;
    onCloseTask?: (gt: GroupedTask) => void;
    onViewTaskDetails: (t: Task) => void;
    systemEvents: SystemEvents.SystemEvent[];
    loading?: boolean;
    expanded?: boolean;
};

const Tasks = (props: Props) => {
    const groupedTasks = useGroupTasks(props.systemEvents);
    const { subscribeTopic, unsubscribeTopic } = useSubscribeTopicLazy();

    const createTopic = React.useCallback((gt: GroupedTask) => {
        return `/system-events/${gt.pk.replaceAll('#', '-')}`;
    }, []);

    const extractMetadata = React.useCallback((gt: GroupedTask) => {
        const regex = /(?<league>\w+)#(?<seasonYear>\d+)#(?<seasonType>\w+)/gm;
        const match = regex.exec(gt.pk);

        if (!match) {
            return null;
        }

        const { league, seasonYear, seasonType } = match.groups as {
            league: string;
            seasonYear: string;
            seasonType: string;
        };

        return { league, seasonYear, seasonType };
    }, []);

    const handleOpenTask = React.useCallback(
        (gt: GroupedTask) => {
            if (props.onOpenTask) {
                props.onOpenTask(gt);
            }

            const metadata = extractMetadata(gt);

            if (!metadata) {
                return;
            }

            const { league, seasonYear, seasonType } = metadata;

            subscribeTopic(league, seasonYear, seasonType, createTopic(gt), mqtt5.QoS.AtMostOnce);
        },
        [createTopic, extractMetadata, props, subscribeTopic],
    );

    const handleCloseTask = React.useCallback(
        (gt: GroupedTask) => {
            if (props.onCloseTask) {
                props.onCloseTask(gt);
            }

            const metadata = extractMetadata(gt);

            if (!metadata) {
                return;
            }

            const { league, seasonYear, seasonType } = metadata;

            unsubscribeTopic(league, seasonYear, seasonType, createTopic(gt));
        },
        [createTopic, extractMetadata, props, unsubscribeTopic],
    );

    const handleChange = React.useCallback(
        (gt: GroupedTask) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            isExpanded ? handleOpenTask(gt) : handleCloseTask(gt);
        },
        [handleCloseTask, handleOpenTask],
    );

    return (
        <TaskContainer>
            {props.loading && (
                <div className="loading-container">
                    <Loading mt={2} />
                </div>
            )}
            {!props.loading &&
                groupedTasks.map((gt) => {
                    return (
                        <Accordion
                            key={`task-${gt.pk}`}
                            onChange={handleChange(gt)}
                            expanded={props.expanded}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <IconDecorated
                                        paletteColor="blue400"
                                        sizeRem={2}
                                        name="ArrowDropDown"
                                    />
                                }
                            >
                                <GroupTaskWrapper>
                                    <Chip variant="successPrimary">{gt.context.toUpperCase()}</Chip>
                                    <Chip variant="warningPrimary">{gt.pk}</Chip>
                                    <Text>
                                        {dayjs
                                            .unix(Number(gt.timestamp))
                                            .format('YYYY-MM-DD HH:mm:ss Z')}
                                    </Text>
                                </GroupTaskWrapper>
                            </AccordionSummary>
                            <AccordionDetails data-testid={`task-details-${gt.pk}`}>
                                {gt.tasks.map((task) => {
                                    const skElements = task.sk.split('_');

                                    return (
                                        <TaskDetailsContainer key={`task_${task.pk}_${task.sk}`}>
                                            <div>
                                                {dayjs.unix(Number(task.timestamp)).format('LLL')}
                                            </div>
                                            <div>
                                                <IconDecorated sizeRem={1} name="CloudRounded" />
                                                {skElements[1]}
                                            </div>
                                            <div>
                                                <IconDecorated sizeRem={1} name="Language" />{' '}
                                                {task.sourceId}
                                            </div>
                                            <a
                                                href="#view-details"
                                                className="view-details"
                                                onClick={() => props.onViewTaskDetails(task)}
                                            >
                                                <span>View task details</span>
                                                <IconDecorated sizeRem={2} name="VisibilityOn" />
                                            </a>
                                        </TaskDetailsContainer>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </TaskContainer>
    );
};

export default Tasks;
