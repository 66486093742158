import * as React from 'react';

import useOptions from 'hooks/options/useOptions';
import { SeasonType } from 'types';
import { Option } from 'types/controls';

function getOptions(seasonTypes: SeasonType[], seasonType: SeasonType): Option<SeasonType>[] {
    return seasonTypes.map((currentSeasonType) => ({
        label: currentSeasonType,
        selected: currentSeasonType === seasonType,
        value: currentSeasonType,
    }));
}

export default function useSeasonTypeOptions(seasonTypes: SeasonType[], seasonType: SeasonType) {
    return useOptions(
        React.useMemo(() => getOptions(seasonTypes, seasonType), [seasonTypes, seasonType]),
    );
}
