import styled from '@emotion/styled';

export const StatContainer = styled.div`
    display: flex;
    gap: 0.25rem;
    align-items: center;

    font-weight: 700;
    font-size: 0.875rem;
`;

export const IconContainer = styled.div<{ color: string; opacity: number }>`
    display: flex;
    background-color: rgba(var(--${(props) => props.color}-rgb), ${({ opacity }) => opacity});
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.15rem;
`;
