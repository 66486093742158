import * as React from 'react';

type UseBulkActionsReturn = {
    handleToggleCheckDiscrepancy: (id: string) => void;
    handleCheckAll: () => void;
    handleUncheckAll: () => void;
    handleToggleAll: () => void;
    checkedDiscrepancies: Array<string>;
    isActive: boolean;
};

export default function useBulkActions(discrepancyIds: Array<string>): UseBulkActionsReturn {
    const [checkedDiscrepancies, setCheckedDiscrepancies] = React.useState<Array<string>>([]);

    const handleToggleCheckDiscrepancy = React.useCallback((id: string) => {
        setCheckedDiscrepancies((prev) => {
            if (prev.includes(id)) {
                return prev.filter((did) => did !== id);
            }

            return [...prev, id];
        });
    }, []);

    const handleCheckAll = React.useCallback(() => {
        setCheckedDiscrepancies(discrepancyIds);
    }, [discrepancyIds]);

    const handleUncheckAll = React.useCallback(() => {
        setCheckedDiscrepancies([]);
    }, []);

    const handleToggleAll = React.useCallback(() => {
        if (checkedDiscrepancies.length) {
            handleUncheckAll();
        } else {
            handleCheckAll();
        }
    }, [checkedDiscrepancies.length, handleCheckAll, handleUncheckAll]);

    const isActive = React.useMemo(() => checkedDiscrepancies.length > 0, [checkedDiscrepancies]);

    return {
        isActive,
        checkedDiscrepancies,
        handleToggleCheckDiscrepancy,
        handleCheckAll,
        handleUncheckAll,
        handleToggleAll,
    };
}
