import styled from '@emotion/styled';

type ControlBarContainerProps = {
    spacing: 'small' | 'large';
};

const spacingStyles = {
    small: { columnGap: '0.5rem' },
    large: { columnGap: '1rem' },
};

export const ControlBarContainer = styled.div<ControlBarContainerProps>`
    display: flex;
    align-items: center;
    column-gap: ${({ spacing }) => spacingStyles[spacing].columnGap};
    font-size: 0.75rem;
    line-height: 0.875rem;
`;
