import * as React from 'react';

import { LeagueType } from 'types';

const FOOTBALL_URL = `https://lde.{league}.prod.srsmtcollect.com/index.html#/game/{gameId}/lde`;
const BASKETBALL_URL = `https://lde.{league}.prod.srsmtcollect.com/lde/game/{gameId}/show.html`;

const LEAGUE_URL_MAP: Partial<Record<LeagueType, string>> = {
    cfb: FOOTBALL_URL.replace('{league}', 'ncaafb'),
    ncaafb: FOOTBALL_URL.replace('{league}', 'ncaafb'),
    nfl: FOOTBALL_URL.replace('{league}', 'nfl'),
    ncaamb: BASKETBALL_URL.replace('{league}', 'ncaam'),
    ncaawb: BASKETBALL_URL.replace('{league}', 'ncaaw'),
};

export function useLdeLink(league: LeagueType): React.MouseEventHandler {
    return React.useCallback(
        (event: React.MouseEvent) => {
            const srusGameId = event.currentTarget.getAttribute('data-game-srus-id');
            const ldeUrl = LEAGUE_URL_MAP[league];

            if (!srusGameId || !ldeUrl) {
                throw new Error('Game ID or LDE URL is missing.');
            }

            window.open(ldeUrl.replace('{gameId}', srusGameId));
        },
        [league],
    );
}
