import * as React from 'react';

import { DiscrepancyType } from 'api/types/bff';
import { DiscrepancyStatus } from 'api/types/flags';
import { useCurrentSeason } from 'app/useCurrentSeason';

import { IconDecorated } from '../../icons';
import FlagContext from '../flag/context';
import { useDiscrepanciesTitle } from '../hooks/useDiscrepanciesTitle';
import useUpdateDiscrepancyState from '../hooks/useUpdateDiscrepancyState';
import { Control, DiscrepancyContainer } from '../styles';

import IgnoreDialog from './ignore-dialog';

interface DiscrepancyProps {
    discrepancy: DiscrepancyType;
    includeDiscrepancyName?: boolean;
}

const Discrepancy = ({ discrepancy, includeDiscrepancyName }: DiscrepancyProps) => {
    const { league } = useCurrentSeason();
    const { activeSource } = React.useContext(FlagContext);
    const makeDiscrepancyTitle = useDiscrepanciesTitle(activeSource, includeDiscrepancyName);
    const discrepancyStateMutation = useUpdateDiscrepancyState(league);
    const [showIgnoreDialog, setShowIgnoreDialog] = React.useState(false);

    const handleResolveDiscrepancyClick = React.useCallback(() => {
        discrepancyStateMutation.mutate({ ...discrepancy, status: DiscrepancyStatus.RESOLVED });
    }, [discrepancy, discrepancyStateMutation]);

    const handleIgnoreDiscrepancyClick = React.useCallback(() => {
        setShowIgnoreDialog(true);
    }, []);

    const handleCloseIgnoreDialog = React.useCallback(() => {
        setShowIgnoreDialog(false);
    }, []);

    return (
        <DiscrepancyContainer>
            <div className="value">{makeDiscrepancyTitle(discrepancy)}</div>
            <div className="controls">
                <Control
                    onClick={handleResolveDiscrepancyClick}
                    data-testid="discrepancy-resolve"
                    title="Mark as Complete"
                >
                    <IconDecorated sizeRem={1} name="CheckRounded" paletteColor="blue500" />
                </Control>
                <Control
                    onClick={handleIgnoreDiscrepancyClick}
                    data-testid="discrepancy-ignore"
                    title="Ignore"
                >
                    <IconDecorated sizeRem={1} name="CloseRounded" paletteColor="black300" />
                </Control>
            </div>
            <IgnoreDialog
                open={showIgnoreDialog}
                onClose={handleCloseIgnoreDialog}
                discrepancy={discrepancy}
            />
        </DiscrepancyContainer>
    );
};

export default Discrepancy;
