import { SystemEvents } from 'api/types/system-events';

export type Task = SystemEvents.SystemEvent & { timestamp: string };
export type GroupedTask = {
    timestamp: string;
    pk: string;
    context: string;
    tasks: Task[];
};
export type GroupedTasks = Array<GroupedTask>;

export default function useGroupTasks(tasks: SystemEvents.SystemEvent[]): GroupedTasks {
    return tasks.reduce((acc, task) => {
        const timestamp = task.sk.split('_')[0];
        const groupTaskIndex = acc.findIndex((t) => t.pk === task.pk);

        if (groupTaskIndex === -1) {
            acc.push({
                timestamp,
                pk: task.pk,
                context: task.context,
                tasks: [
                    {
                        ...task,
                        timestamp,
                    },
                ],
            });

            return acc;
        }

        acc[groupTaskIndex].tasks.push({
            ...task,
            timestamp,
        });

        acc[groupTaskIndex].tasks.sort((a, b) => {
            return a.timestamp > b.timestamp ? -1 : 1;
        });

        acc[groupTaskIndex].timestamp = acc[groupTaskIndex].tasks[0].timestamp;

        return acc;
    }, [] as GroupedTasks);
}
