import styled from '@emotion/styled';

export const PlayerInfoContainer = styled.div`
    & > .jersey,
    .position,
    .id-srid {
        color: var(--color-team-mappings-row-info-text);
        opacity: 0.6;
    }
`;
