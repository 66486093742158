import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

// eslint-disable-next-line no-restricted-imports
import ActionButton from 'components/action-button';
import { IconDecorated } from 'components/icons';

// eslint-disable-next-line no-restricted-imports
import { SourceId } from '../../../../../../../types';
// eslint-disable-next-line no-restricted-imports
import useColorBar from '../../../../hooks/useBarClass';
// eslint-disable-next-line no-restricted-imports
import useMappingActions from '../../../../hooks/useMappingActions';
// eslint-disable-next-line no-restricted-imports
import { Player } from '../../../../hooks/useTeamMappings';
// eslint-disable-next-line no-restricted-imports
import { MappingState } from '../../../../views/mapping';
import { ColorBar } from '../../color-bar/styles';
import { Attribute } from '../../dialog/attributes';
import { MappingDialog } from '../../dialog/map';
import { UnmappingDialog } from '../../dialog/unmap';
import { MappingContainer, MappingInfoContainer } from '../styles';

import { PlayerInfoContainer } from './styles';

type Props = {
    player: Player;
    onChange: (mapping: Player) => void;
};

const MISSING = 'n/a';

const isMapped = (player: Player): boolean => !!player.id && !!player.mapped;

const createAttributes = ({
    id,
    source,
    jersey,
    position,
    dateOfBirth = MISSING,
    year = MISSING,
    height = MISSING,
    weight = MISSING,
    birthPlace = MISSING,
}: Player): Attribute[] => {
    return [
        { name: 'Position:', value: position },
        { name: 'Number:', value: jersey },
        { name: 'DOB:', value: dateOfBirth },
        { name: 'Year:', value: year },
        { name: 'Height:', value: height },
        { name: 'Weight:', value: weight },
        { name: 'Birthplace:', value: birthPlace },
        { name: 'ID:', value: id },
        { name: 'Source:', value: source },
    ];
};

const PlayerMapping = ({ player, onChange }: Props) => {
    const [action, setAction] = useMappingActions();
    const barColor = useColorBar(player.source, isMapped(player));

    const { control, setValue } = useFormContext<MappingState>();
    const playersState = useWatch({
        control,
        name: 'players',
    });

    const options = useMemo(
        () =>
            Object.keys(playersState ?? {})
                .map((id) => playersState[id])
                .filter(({ source }) => source !== player.source)
                .filter(({ mapped }) => !mapped)
                .map((p) => ({
                    label: p.name,
                    value: p,
                    selected: false,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [player.source, playersState],
    );

    const onCancel = useCallback(() => {
        setAction('none');
    }, [setAction]);

    const onRemap = useCallback(() => {
        setAction('remap');
    }, [setAction]);

    const onConfirmMapping = useCallback(
        (selectedPlayer: Player) => {
            if (action === 'remap') {
                const previousMappedPlayer = playersState[player.mapped as string];

                setValue(
                    `players.${previousMappedPlayer.id}`,
                    { ...previousMappedPlayer, mapped: null },
                    { shouldDirty: false },
                );
            }

            setAction('none');

            if (player.source === SourceId.SR) {
                setValue(
                    `players.${player.id}`,
                    { ...player, mapped: selectedPlayer.id },
                    { shouldDirty: false },
                );
                setValue(
                    `players.${selectedPlayer.id}`,
                    { ...selectedPlayer, mapped: player.id },
                    { shouldDirty: true },
                );

                return;
            }

            setValue(
                `players.${selectedPlayer.id}`,
                { ...selectedPlayer, mapped: player.id },
                { shouldDirty: false },
            );
            onChange({ ...player, mapped: selectedPlayer.id });
        },
        [action, onChange, player, playersState, setAction, setValue],
    );

    const onConfirmUnmapping = useCallback(() => {
        setAction('none');
        const mappedPlayer = playersState[player.mapped as string];

        if (player.source === SourceId.SR) {
            setValue(
                `players.${mappedPlayer.id}`,
                { ...mappedPlayer, mapped: null },
                { shouldDirty: true },
            );
            setValue(`players.${player.id}`, { ...player, mapped: null }, { shouldDirty: false });

            return;
        }

        setValue(
            `players.${mappedPlayer.id}`,
            { ...mappedPlayer, mapped: null },
            { shouldDirty: false },
        );
        onChange({ ...player, mapped: null });
    }, [onChange, player, playersState, setAction, setValue]);

    return (
        <MappingContainer>
            <ColorBar color={barColor} />
            <MappingInfoContainer>
                <PlayerInfoContainer className="left">
                    <IconDecorated sizeRem={1} name="PersonRounded" paletteColor="purple" />
                    <div className="name">{player.name}</div>
                    <div className="jersey">#{player.jersey}</div>
                    <div className="position">{player.position}</div>
                    <div className="id-srid">
                        {player.source === SourceId.SR ? 'SRID: ' : 'ID: '}
                        {player.id}
                    </div>
                </PlayerInfoContainer>
                <ActionButton
                    iconName="SyncAlt"
                    content={isMapped(player) ? 'Unmap' : 'Map'}
                    onClick={() => setAction(isMapped(player) ? 'unmap' : 'map')}
                    variant="default"
                />
            </MappingInfoContainer>
            {action === 'map' && (
                <MappingDialog
                    open
                    isRemap={false}
                    title="Map to Existing Player"
                    leftCard={{
                        title: 'UNMAPPED PLAYER',
                        name: player.name,
                        attributes: createAttributes(player),
                    }}
                    rightCard={{
                        title: 'MAP TO EXISTING PLAYER',
                        name: ({ name }: Player) => name,
                        attributes: (p) => (p ? createAttributes(p) : []),
                    }}
                    options={options}
                    onConfirm={onConfirmMapping}
                    onCancel={onCancel}
                />
            )}
            {action === 'unmap' && (
                <UnmappingDialog
                    open
                    title="Unmap a Player"
                    card={{
                        title: 'PLAYER TO BE UNMAPPED',
                        name: player.name,
                        attributes: createAttributes(player),
                    }}
                    onConfirm={onConfirmUnmapping}
                    onCancel={onCancel}
                    onRemap={onRemap}
                />
            )}
            {action === 'remap' && (
                <MappingDialog
                    open
                    isRemap={true}
                    title="Remap a Player"
                    leftCard={{
                        title: 'PLAYER TO BE REMAPPED',
                        name: player.name,
                        attributes: createAttributes(player),
                    }}
                    rightCard={{
                        title: 'MAP TO EXISTING PLAYER',
                        name: ({ name }: Player) => name,
                        attributes: (p) => (p ? createAttributes(p) : []),
                    }}
                    options={options}
                    onConfirm={onConfirmMapping}
                    onCancel={onCancel}
                />
            )}
        </MappingContainer>
    );
};

export default PlayerMapping;
