import * as React from 'react';

import { DiscrepancyType } from 'api/types/bff';
import { byName } from 'components/flag-accordion/compare-discrepancies';
import useSorting from 'hooks/useSorting';

import { BoxScoreFlagHomeAwayDiscrepanciesSplit } from '../types';

export function useSplitDiscrepanciesByHomeAway(discrepancies: DiscrepancyType[]) {
    const sortDiscrepancies = useSorting(byName);

    return React.useMemo(() => {
        const splitDiscrepancies = discrepancies.reduce(
            (acc, currentValue) => {
                const homeTeamSrId = currentValue.contextGame?.homeTeam?.srId;

                if (currentValue.contextPlayer) {
                    // we have a player discrepancy we need to find its team id and whether its home or away
                    if (currentValue.contextTeam?.srId === homeTeamSrId) {
                        acc.home.playerDiscrepancies.push(currentValue);
                    } else {
                        acc.away.playerDiscrepancies.push(currentValue);
                    }
                } else if (currentValue.contextTeam) {
                    // we have a team related discrepancy we need to find if its home or away
                    if (currentValue.contextTeam?.srId === homeTeamSrId) {
                        acc.home.teamDiscrepancies.push(currentValue);
                    } else {
                        acc.away.teamDiscrepancies.push(currentValue);
                    }
                } else {
                    acc.rest.push(currentValue);
                }

                return acc;
            },
            {
                home: {
                    teamDiscrepancies: [],
                    playerDiscrepancies: [],
                },
                away: {
                    teamDiscrepancies: [],
                    playerDiscrepancies: [],
                },
                rest: [],
            } as BoxScoreFlagHomeAwayDiscrepanciesSplit,
        );

        sortDiscrepancies(splitDiscrepancies.home.playerDiscrepancies);
        sortDiscrepancies(splitDiscrepancies.home.teamDiscrepancies);
        sortDiscrepancies(splitDiscrepancies.away.teamDiscrepancies);
        sortDiscrepancies(splitDiscrepancies.away.teamDiscrepancies);
        sortDiscrepancies(splitDiscrepancies.rest);

        return splitDiscrepancies;
    }, [discrepancies, sortDiscrepancies]);
}
