const CODE = /[?&]code=[^&]+/;
const STATE = /[?&]state=[^&]+/;
const ERROR = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search): boolean =>
    (CODE.test(searchParams) || ERROR.test(searchParams)) && STATE.test(searchParams);

export const sha256 = async (data: string): Promise<ArrayBuffer> =>
    crypto.subtle.digest('SHA-256', new TextEncoder().encode(data));

export const base64URLEncode = (buf: ArrayBuffer): string => {
    const a = Array.from(new Uint8Array(buf));

    return btoa(String.fromCharCode.apply(null, a))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
};
