import * as React from 'react';

import { groups, routes, useRoute } from 'app/router';
import ControlBar from 'components/control-bar';
import { ExternalSourceId } from 'types';

import useTimeDifference from '../../hooks/useTimeDifference';
import { TeamInterface } from '../../types';
import GameSelectTeam from '../game-select-team';

import { DiscrepancyControlBarContainer } from './styles';

function getTimeLabel(unit: string, value: number): string {
    const suffix = value !== 1 ? 's' : '';

    return `${value} ${unit}${suffix}`;
}

type Props = {
    defaultSource: ExternalSourceId;
    updatedAt: string;
    teams: TeamInterface[];
    onRepullClick: () => void;
};

const DiscrepancyControlBar = ({ defaultSource, updatedAt, teams, onRepullClick }: Props) => {
    const route = useRoute();

    const [updateLabel, setUpdateLabel] = React.useState('Updated 0 seconds ago');
    const difference = useTimeDifference(updatedAt);
    const [mapModalOpen, setMapModalOpen] = React.useState(false);

    React.useEffect(() => {
        const differenceTotalSeconds = Math.floor(difference / 1000);

        const minuteDifference = Math.floor(differenceTotalSeconds / 60);
        const minuteLabel =
            minuteDifference !== 0 ? `${getTimeLabel('minute', minuteDifference)}, ` : '';

        const secondDifference = differenceTotalSeconds % ((minuteDifference || 1) * 60);
        const secondLabel = getTimeLabel('second', secondDifference);

        setUpdateLabel(`Updated ${minuteLabel}${secondLabel} ago`);
    }, [difference]);

    const handleOpenMappingModal = React.useCallback(() => {
        setMapModalOpen(true);
    }, []);

    const handleCloseMappingModal = React.useCallback(() => {
        setMapModalOpen(false);
    }, []);

    const handleMapGameOrPlayerClick = React.useCallback(
        (id: string) => {
            if (groups.leagueGameBoxscores.has(route)) {
                routes
                    .teamsTeamMapping({
                        league: route.params.league,
                        seasonYear: route.params.seasonYear,
                        seasonType: route.params.seasonType,
                        id,
                        source: defaultSource,
                    })
                    .push();
            }
        },
        [route, defaultSource],
    );

    return (
        <DiscrepancyControlBarContainer>
            <ControlBar spacing="large">
                <ControlBar.Text>{updateLabel}</ControlBar.Text>
                <ControlBar.Button onClick={onRepullClick}>Repull</ControlBar.Button>
                <ControlBar.Button
                    onClick={handleOpenMappingModal}
                    data-testid="map-game-or-player-btn"
                >
                    Map Game or Player
                </ControlBar.Button>
            </ControlBar>
            <GameSelectTeam
                open={mapModalOpen}
                onClose={handleCloseMappingModal}
                onOpenTeamMappingClick={handleMapGameOrPlayerClick}
                teams={teams}
            />
        </DiscrepancyControlBarContainer>
    );
};

export default DiscrepancyControlBar;
