import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { RouteInstance, RouteName, groups, useRoute } from 'app/router';
import View from 'components/view';

import { ErrorContainer } from './styles';

type Props<T extends RouteName> = {
    name: T | T[] | false;
    component: React.ComponentType<{ route: RouteInstance<T> }>;
};

const Route = <T extends RouteName>(props: Props<T>) => {
    const route = useRoute();

    if ([props.name].flat().includes(route.name as T)) {
        return (
            <ErrorBoundary
                fallback={
                    groups.league.has(route) ? (
                        <View route={route}>
                            <ErrorContainer>
                                Error loading this page. Please try again.
                            </ErrorContainer>
                        </View>
                    ) : (
                        <ErrorContainer>Error loading this page. Please try again.</ErrorContainer>
                    )
                }
            >
                {/* @ts-ignore */}
                <props.component route={route} />
            </ErrorBoundary>
        );
    }

    return null;
};

export default Route;
