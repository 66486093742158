import { Context } from '../../api/types/bff';

export function teamName(team: Context.Team | undefined | null) {
    if (!team) {
        return 'N/A';
    }

    const { name, market } = team;

    return `${market} ${name}`;
}
