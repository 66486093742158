import * as React from 'react';

import { LazyImageContainer } from './styles';

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
    fallback: JSX.Element;
};

const LazyImage = ({ fallback, ...props }: Props) => {
    const [isReady, setIsReady] = React.useState(false);

    const handleLoad: React.ReactEventHandler<HTMLImageElement> = React.useCallback(
        (event) => {
            const imageElement = event.target as HTMLImageElement;

            const isComplete = imageElement.complete;
            const hasWidth = imageElement.naturalWidth > 1;
            const hasHeight = imageElement.naturalHeight > 1;

            setIsReady(isComplete && hasWidth && hasHeight);
        },
        [setIsReady],
    );

    return (
        <LazyImageContainer isReady={isReady}>
            <div className="fallback-container">{fallback}</div>
            <img alt="" {...props} onLoad={handleLoad} />
        </LazyImageContainer>
    );
};

export default LazyImage;
