import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div<{ split?: boolean }>`
    display: flex;
    flex-direction: ${({ split }) => (split ? 'column' : 'row')};
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;

    ${({ split }) =>
        !split &&
        css`
        .property-section:not(:last-of-type)::after {
            content: '\\002f'; // Displays as '/'
            margin-left: 0.5rem;
            color: var(--blue400);
        `}

    & .property-group {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }

    & .property-group:not(:last-of-type, .property-section)::after {
        content: '\\002f'; // Displays as '/'
        margin: 0 0.5rem;
        color: var(--blue400);
    }

    & .property {
        min-width: fit-content;
    }

    & .property .property:not(:last-of-type)::after {
        content: '\\2022'; // Displays as '•'
        margin: 0 0.25rem;
    }

    & .property-label::after {
        content: '\\003a\\00a0'; // Displays as ': '
    }

    & .property-label:has(+ .property-group) {
        font-weight: 700;
    }
`;

export const NullTag = styled.div`
    display: inline-block;
    background: rgba(var(--red500-rgb), 0.5);
    padding: 0.0625rem 0.5rem;
    color: var(--blue200);
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
`;
