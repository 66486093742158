import * as React from 'react';

import { FlagType } from 'api/types/bff';

type DiscrepancyCount = {
    ignored: number;
    resolved: number;
    created: number;
};

export default function useDiscrepancyCount(flags: FlagType[]) {
    return React.useMemo(() => {
        return flags.reduce<DiscrepancyCount>(
            (sum, { discrepanciesSummary: { ignoredCount, resolvedCount, createdCount } }) => {
                sum.ignored += ignoredCount;
                sum.resolved += resolvedCount;
                sum.created += createdCount;

                return sum;
            },
            {
                ignored: 0,
                resolved: 0,
                created: 0,
            },
        );
    }, [flags]);
}
