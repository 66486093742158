import * as React from 'react';

import { Option } from 'types/controls';
import { Coverage, coverageMap } from 'types/coverage';

const DEFAULT_OPTIONS: Option<Coverage | undefined>[] = [
    { label: 'All', value: undefined },
    ...Object.values(Coverage).map((coverage) => ({
        label: coverageMap[coverage],
        value: coverage,
    })),
];

export default function useCoverageOptions(current?: Coverage): Option<Coverage | undefined>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: current === option.value,
            })),
        [current],
    );
}
