import styled from '@emotion/styled';

export const FlagTeamImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.25rem;

    .image-container {
        width: 1.5rem;
        height: 1.5rem;
    }

    .divider {
        color: var(--color-flag-team-images-divider);
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1rem;
    }
`;
