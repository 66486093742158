import styled from '@emotion/styled';

import { DialogButtons } from 'components/dialog/styles';
import { DropdownPlaceholder } from 'components/dropdown/styles';
import { PopoverContainer } from 'components/popover/styles';

import { AttributesContainer, Name, Value } from './attributes/styles';

export const Title = styled.div`
    font-weight: 700;
    color: var(--color-team-mappings-dialog-title);
`;

export const MappingDialogContainer = styled.div`
    display: grid;
    padding: 1.5rem;

    & > ${Title} {
        font-size: 1.125rem;
    }

    & > ${DialogButtons} {
        & > button {
            color: var(--color-team-mappings-submit-dialog-button-label);
        }
        & > .submit {
            border-color: var(--color-team-mappings-submit-dialog-button-border);
        }
    }
`;

export const Card = styled.div`
    display: grid;
    grid-template-rows: min-content;
    min-width: 21.4375rem;
    min-height: 21.8rem;
    padding: 1.25rem 1.4375rem;
    margin-top: 1.5rem;

    & > ${Title} {
        font-size: 0.625rem;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }
`;

export const Info = styled.div`
    display: grid;
    grid-template-rows: min-content;

    & > ${Title} {
        font-size: 0.875rem;
        font-weight: 700;
        height: 2rem;
        padding: 0.375rem 0.5rem;
        border: 0.0625rem solid var(--color-team-mappings-row-info-title);
        border-radius: 0.2rem;
    }

    & > ${PopoverContainer} {
        width: 100%;
    }
`;

export const Cards = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 1rem;

    & > ${Card}.map {
        background-color: var(--color-team-mappings-map-card);
    }

    & > ${Card}.unmap {
        background-color: var(--color-team-mappings-unmap-card);
        & > ${Info} {
            & > ${Title} {
                color: var(--color-team-mappings-card-info-title);
                background-color: var(--color-team-mappings-unmap-card-title);
            }
        }
    }

    & > ${Card}.remap {
        background-color: var(--color-team-mappings-remap-card);
        & > ${Info} {
            & > ${Title} {
                color: var(--color-team-mappings-card-info-title);
                background-color: var(--color-team-mappings-remap-card-title);
            }
        }
    }

    & > ${Card}.right {
        border: 0.0625rem solid var(--color-team-mappings-right-card-border);
    }

    & > ${Card}.right.selected {
        background-color: var(--color-team-mappings-right-card-selected-background);
        border: none;

        & > ${Title} {
            font-size: 0.625rem;
            font-weight: 700;
            color: var(--color-team-mappings-right-card-selected-attributes-name);
            text-transform: uppercase;
            margin-bottom: 0.4375rem;
        }

        ${Info} {
            & > ${AttributesContainer} {
                & > ${Name} {
                    color: var(--color-team-mappings-right-card-selected-attributes-name);
                }
                & > ${Value} {
                    font-weight: 600;
                    color: var(--color-team-mappings-right-card-selected-attributes-value);
                }
            }
        }

        ${PopoverContainer} {
            width: 100%;
            height: fit-content;
            background-color: var(--color-team-mappings-right-card-selected-dropdown-background);

            ${DropdownPlaceholder} {
                color: var(--color-team-mappings-right-card-selected-attributes-name);
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1rem;
                border: 0.0625rem solid
                    var(--color-team-mappings-right-card-selected-dropdown-border);
            }
        }
    }

    ${PopoverContainer} {
        width: 100%;
        height: fit-content;

        ${DropdownPlaceholder} {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1rem;
            border: 0.0625rem solid var(--color-team-mappings-right-card-border);
        }
    }
`;
