import * as React from 'react';

import { Option } from 'types/controls';

export function useLiveFlagCategoryTypeOptions(
    types?: string[],
    current?: string,
): Option<string | undefined>[] {
    return React.useMemo(
        () => [
            {
                label: 'All',
                value: undefined,
                selected: current === undefined,
            },
            ...(types ?? []).map((type) => ({
                label: type,
                value: type,
                selected: type === current,
            })),
        ],
        [current, types],
    );
}
