import { LeagueType, SeasonType, SeasonYear } from 'types';

export const teamMappingsKeys = {
    teamMappingsStatsByLeagueTeamId: (league: string, teamId: string) => [
        league,
        'mappings',
        'team',
        'stats',
        teamId,
    ],

    teamMappingsByLeagueSourceUnmapped: (league: string, source: string, teamId: string) => [
        league,
        source,
        teamId,
        'mappings',
        'team',
        'unmapped',
    ],

    teamMappingsByLeagueSourceAutoMapped: (league: string, source: string, teamId: string) => [
        league,
        source,
        teamId,
        'mappings',
        'team',
        'automatic',
    ],

    teamMappingsByLeagueSourceManuallyMapped: (league: string, source: string, teamId: string) => [
        league,
        source,
        teamId,
        'mappings',
        'team',
        'manual',
    ],
    teamMappingsByLeagueSourceAll: (league: string, source: string, teamId: string) => [
        league,
        source,
        teamId,
        'mappings',
        'team',
        'all',
    ],

    teamMappingsAll: (league: string, source: string, teamId: string) => [
        league,
        source,
        teamId,
        'mappings',
        'team',
    ],
};

export const teamScheduleAdminKeys = {
    teamScheduleAdmin: (league: string, teamId: string, scheduleType: string) => [
        league,
        teamId,
        scheduleType,
        'admin',
    ],
};

export type GetLeagueTeamsParameters = {
    league: LeagueType;
    seasonYear: SeasonYear;
    seasonType: SeasonType;
};

export const leagueTeamsKeys = {
    leagueTeams: ({ league, seasonYear, seasonType }: GetLeagueTeamsParameters) => [
        league,
        'league',
        'teams',
        seasonYear,
        seasonType,
    ],
};
