import * as React from 'react';

import { TeamStatus } from 'api/types/bff';
import { Option } from 'types/controls';

const DEFAULT_OPTIONS: Option<TeamStatus>[] = [
    { label: 'All', value: TeamStatus.All },
    { label: 'Flagged', value: TeamStatus.Flagged },
    { label: 'Ignored', value: TeamStatus.Ignored },
];

export function useTeamStatusOptions(current: TeamStatus): Option<TeamStatus>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: current === option.value,
            })),
        [current],
    );
}
