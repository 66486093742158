import { SportsMediaAuthError } from './errors';
import { User } from './user';

export interface AuthState<T extends User = User> {
    user?: T;
    error?: SportsMediaAuthError;
    isLoading: boolean;
    isAuthenticated: boolean;
    isInitialized: boolean;
}

export const initialAuthState: AuthState = {
    isLoading: false,
    isAuthenticated: false,
    isInitialized: false,
};
