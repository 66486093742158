import * as React from 'react';
import { Route } from 'type-route';

import { FlagsSortingAttribute } from 'api/types/bff';
import { DiscrepancyStatusFilter } from 'api/types/flags';
import { routes } from 'app/router';
import ControlBar from 'components/control-bar';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import FlagAccordion from 'components/flag-accordion';
import FlagStats from 'components/flag-stats';
import { IconDecorated } from 'components/icons';
import View from 'components/view';
import ViewHeader from 'components/view-header';
import { useDiscrepancyStatusOptions } from 'hooks/options/useDiscrepancyStatusOptions';
import { useFlagsSortingOptions } from 'hooks/options/useFlagsSortingOptions';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import useFlags from 'hooks/useFlagStats/useFlags';
import useFlagStatsSummary from 'hooks/useFlagStats/useGetFlagStatsSummary';
import { Sorting } from 'types/sorting';

type Props = {
    route: Route<typeof routes.standings>;
};

const Standings = ({ route }: Props) => {
    const statusOptions = useDiscrepancyStatusOptions(route.params.status);

    const sortingOptions = useFlagsSortingOptions(
        [FlagsSortingAttribute.DiscrepancyCount, FlagsSortingAttribute.DiscrepancyDate],
        { attribute: route.params.sortBy, order: route.params.sortOrder },
    );

    const flagsQuery = useFlags({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        flagType: 'standings',
        discrepancyStatus: route.params.status,
        sortBy: route.params.sortBy,
        sortOrder: route.params.sortOrder,
    });
    const flagStatsSummary = useFlagStatsSummary();

    const updateDate = useDateSnapshot(flagsQuery.dataUpdatedAt);

    const handleParamsChange = useChangeParams(route);

    const handleSortingChange = React.useCallback(
        (sorting: Sorting<FlagsSortingAttribute>) =>
            handleParamsChange({ sortBy: sorting.attribute, sortOrder: sorting.order }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status: DiscrepancyStatusFilter) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    return (
        <View route={route}>
            <ViewHeader
                iconElement={
                    <IconDecorated
                        paletteColor="blue400"
                        sizeRem={1.5}
                        name="HorizontalSplitRounded"
                    />
                }
                iconFormat="inline"
                titleText="Standings"
            >
                <ControlBar spacing="small">
                    <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                    <ControlBar.Button>Repull</ControlBar.Button>
                </ControlBar>
            </ViewHeader>
            <FlagStats
                flagged={flagStatsSummary.standings.created}
                ignored={flagStatsSummary.standings.ignored}
            />
            <ControlPanel>
                <ControlPanel.Group>
                    <Dropdown
                        label="Sort"
                        options={sortingOptions}
                        onChange={handleSortingChange}
                    />
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        onChange={handleStatusChange}
                    />
                </ControlPanel.Group>
            </ControlPanel>
            <FlagAccordion
                loading={flagsQuery.isLoading}
                flags={flagsQuery.data}
                status={route.params.status}
            />
        </View>
    );
};

export default Standings;
