import * as React from 'react';
import { Route } from 'type-route';

import { routes } from 'app/router';

export function useChangeParams<TRoute extends Route<(typeof routes)[keyof typeof routes]>>(
    route: TRoute,
    method: 'push' | 'replace' | 'pop' = 'push',
) {
    return React.useCallback(
        (params: Partial<(typeof route)['params']>) =>
            // @ts-ignore
            routes[route.name]({ ...route.params, ...params })[method](),
        [route, method],
    );
}
