import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BulkActionsContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 9fr;
    gap: 1.5rem;
    margin-bottom: 0.5rem;

    & > .select-all-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
`;

export const ActionsWrapper = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.625rem;
    column-gap: 0.5rem;

    opacity: ${({ active }) => (active ? 1 : 0.4)};

    & > .action-name {
        color: var(--blue500);

        cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
    }
`;

const checkboxStyles = css`
    width: 1rem;
    height: 1rem;
`;

export const CheckboxUnchecked = styled.div<{ isDark: boolean }>`
    ${checkboxStyles};
    border: 0.0625rem solid var(--${({ isDark }) => (isDark ? 'blue300' : 'black400')});
`;

export const CheckboxChecked = styled.div<{ isDark: boolean }>`
    ${checkboxStyles};
    background-color: var(--blue2000);
    position: relative;

    &:after {
        position: absolute;
        left: 0.275rem;
        top: 0.45rem;
        width: 0.375rem;
        height: 0.1rem;
        background: var(--${({ isDark }) => (isDark ? 'blue300' : 'blue500')});
        content: '';
    }
`;
