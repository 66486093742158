import { useCallback, useState } from 'react';

export type Action = 'map' | 'unmap' | 'remap' | 'none';

function useMappingActions(init: Action = 'none'): [Action, (action: Action) => void] {
    const [selectedAction, setActiveAction] = useState<Action>(init);

    const handleChange = useCallback(
        (action: Action) => setActiveAction(action),
        [setActiveAction],
    );

    return [selectedAction, handleChange];
}

export default useMappingActions;
