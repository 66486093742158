import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { SystemEvents } from 'api/types/system-events';

import { SystemEventsKeys } from '../queries';

const getSystemEvents = async (pk?: string): Promise<SystemEvents.SystemEvent[]> => {
    let endpoint = `${API.SYSTEM_EVENTS_API}/events`;

    if (pk) {
        endpoint += `/pk/${encodeURIComponent(pk)}`;
    }

    const response = await client.get(endpoint);

    return response?.data;
};

export default function useSystemEvents(enabled = true, pk?: string) {
    return useQuery(SystemEventsKeys.getSystemEvents(pk), () => getSystemEvents(pk), {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
        enabled,
    });
}
