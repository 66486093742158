import { TeamStatus } from 'api/types/bff';
import { SourceId } from 'types';

export const LeagueKeys = {
    teams: (
        league: string,
        seasonYear: string,
        seasonType: string,
        source: SourceId,
        sort: string,
        status: TeamStatus,
        division: string,
    ) => [league, 'teams', seasonYear, seasonType, source, sort, status, division],
};
