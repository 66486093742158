import * as React from 'react';

type GroupByResult<K extends string | number, T> = {
    groups: Partial<Record<K, T[]>>;
    other: T[];
};

/**
 * Custom hook for grouping an array of items based on a callback function.
 *
 * @template K - Type of the grouping key (string or number).
 * @template T - Type of the items in the array.
 * @param {T[]} data - The array of items to be grouped.
 * @param {(item: T) => K | null | undefined} callback - A callback function that returns the grouping key for each item.
 * @returns {GroupByResult<K, T>} - An object containing grouped items and items without a valid key.
 */
export function useGroupBy<K extends string | number, T>(
    data: T[],
    callback: (item: T) => K | null | undefined,
): GroupByResult<K, T> {
    return React.useMemo(
        () =>
            data.reduce<GroupByResult<K, T>>(
                (result, item) => {
                    const key = callback(item);
                    const isValidKey = typeof key === 'string' || typeof key === 'number';

                    if (isValidKey) {
                        result.groups[key] ??= [];
                        result.groups[key]?.push(item);
                    } else {
                        result.other.push(item);
                    }

                    return result;
                },
                { groups: {}, other: [] },
            ),
        [data, callback],
    );
}
