import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';

export interface PopoverContainerProps extends HTMLAttributes<HTMLDivElement> {}

export const PopoverContainer = styled.div<PopoverContainerProps>`
    width: fit-content;
    :hover {
        cursor: pointer;
    }
`;
