import { Button } from './styles';

type Props = {
    className?: string;
    content: string;
    onClick?: () => void;
};

const GoBackButton = ({ className, content, onClick }: Props) => {
    return (
        <Button
            className={className}
            data-testid="go-back-button"
            onClick={onClick}
        >{`← ${content}`}</Button>
    );
};

export default GoBackButton;
