import dayjs from 'dayjs';
import * as React from 'react';
import { Route } from 'type-route';

import { FlagsSortingAttribute } from 'api/types/bff';
import { DiscrepancyStatusFilter } from 'api/types/flags';
import { Conference, Division } from 'api/types/leagues';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import useLeagueInfo from 'app/useLeagueInfo';
import ControlBar from 'components/control-bar';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import FlagAccordion from 'components/flag-accordion';
import FlagStats from 'components/flag-stats';
import { IconDecorated } from 'components/icons';
import DateSelector, { DateSelectorValue } from 'components/primitives/date-selector';
import View from 'components/view';
import ViewHeader from 'components/view-header';
import { useDiscrepancyStatusOptions } from 'hooks/options/useDiscrepancyStatusOptions';
import { useFlagsSortingOptions } from 'hooks/options/useFlagsSortingOptions';
import { useLeagueHierarchyOptions } from 'hooks/options/useLeagueHierarchyOptions';
import { useWeekOptions } from 'hooks/options/useWeekOptions';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import useDivisions from 'hooks/useDivisions';
import useFlags from 'hooks/useFlagStats/useFlags';
import useFlagStatsSummary from 'hooks/useFlagStats/useGetFlagStatsSummary';
import { Sorting } from 'types/sorting';

type Props = {
    route: Route<typeof routes.schedule>;
};

const Schedule = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const leagueInfoQuery = useLeagueInfo({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
    });

    const statusOptions = useDiscrepancyStatusOptions(route.params.status);

    const divisionsQuery = useDivisions(
        { league: route.params.league },
        { enabled: leagueDetails.filters.leagueHierarchy },
    );
    const divisionOptions = useLeagueHierarchyOptions(divisionsQuery.data, route.params.division);
    const conferenceOptions = useLeagueHierarchyOptions(
        divisionsQuery.data?.find(({ alias }) => alias === route.params.division)?.conferences,
        route.params.conference,
    );

    const weekOptions = useWeekOptions(leagueInfoQuery.data?.weeks ?? [], route.params.week);
    const dateFrom = route.params.dateFrom ?? dayjs();
    const dateTo = route.params.dateTo ?? dayjs();
    const dateValues: DateSelectorValue = dateFrom.isSame(dateTo) ? [dateFrom] : [dateFrom, dateTo];

    const sortingOptions = useFlagsSortingOptions([FlagsSortingAttribute.DiscrepancyDate], {
        attribute: route.params.sortBy,
        order: route.params.sortOrder,
    });

    const flagsQuery = useFlags({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        flagType: 'schedule',
        discrepancyStatus: route.params.status,
        sortBy: route.params.sortBy,
        sortOrder: route.params.sortOrder,
        ...(leagueDetails.filters.leagueHierarchy && {
            divisionAlias: route.params.division,
            conferenceAlias: route.params.conference,
        }),
        ...(leagueDetails.filters.gameWeek && {
            week: route.params.week,
        }),
        ...(leagueDetails.filters.gameDate && {
            dateFrom: dateFrom.format('YYYY-MM-DD'),
            dateTo: dateTo.format('YYYY-MM-DD'),
        }),
    });
    const flagStatsSummary = useFlagStatsSummary();

    const updateDate = useDateSnapshot(flagsQuery.dataUpdatedAt);

    const handleParamsChange = useChangeParams(route);

    const handleDateChange = React.useCallback(
        (dates: DateSelectorValue) =>
            handleParamsChange({ dateFrom: dates[0], dateTo: dates[1] ?? dates[0] }),
        [handleParamsChange],
    );
    const handleWeekChange = React.useCallback(
        (week?: string) => handleParamsChange({ week }),
        [handleParamsChange],
    );
    const handleSortingChange = React.useCallback(
        (sorting: Sorting<FlagsSortingAttribute>) =>
            handleParamsChange({ sortBy: sorting.attribute, sortOrder: sorting.order }),
        [handleParamsChange],
    );
    const handleDivisionChange = React.useCallback(
        (division?: Division) =>
            handleParamsChange({ division: division?.alias, conference: undefined }),
        [handleParamsChange],
    );
    const handleConferenceChange = React.useCallback(
        (conference?: Conference) => handleParamsChange({ conference: conference?.alias }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status: DiscrepancyStatusFilter) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    return (
        <View route={route}>
            <ViewHeader
                iconElement={
                    <IconDecorated
                        paletteColor="yellow600"
                        sizeRem={1.5}
                        name="CalendarTodayRounded"
                    />
                }
                iconFormat="inline"
                titleText="Schedule"
            >
                <ControlBar spacing="small">
                    <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                    <ControlBar.Button>Repull</ControlBar.Button>
                </ControlBar>
            </ViewHeader>
            <FlagStats
                flagged={flagStatsSummary.schedule.created}
                ignored={flagStatsSummary.schedule.ignored}
            />
            <ControlPanel>
                <ControlPanel.Group>
                    {leagueDetails.filters.gameDate && (
                        <DateSelector value={dateValues} onChange={handleDateChange} />
                    )}
                    {leagueDetails.filters.gameWeek && (
                        <Dropdown label="Week" options={weekOptions} onChange={handleWeekChange} />
                    )}
                    <Dropdown
                        label="Sort"
                        options={sortingOptions}
                        onChange={handleSortingChange}
                    />
                    {leagueDetails.filters.leagueHierarchy && (
                        <React.Fragment>
                            <Dropdown
                                label="Division"
                                options={divisionOptions}
                                onChange={handleDivisionChange}
                            />
                            <Dropdown
                                label="Conference"
                                options={conferenceOptions}
                                onChange={handleConferenceChange}
                            />
                        </React.Fragment>
                    )}
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        onChange={handleStatusChange}
                    />
                </ControlPanel.Group>
            </ControlPanel>
            <FlagAccordion
                moduleHeader
                loading={flagsQuery.isLoading}
                flags={flagsQuery.data}
                status={route.params.status}
            />
        </View>
    );
};

export default Schedule;
