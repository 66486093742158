import styled from '@emotion/styled';

export const GameSelectTeamContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Title = styled.div`
    font-size: 1rem;
`;

export const TeamContainer = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
`;
