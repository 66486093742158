import { colours } from 'app/theme/color-scheme';
import { IconDecorated, IconKeys } from 'components/icons';

import { FlagContent, VisibleContent } from '../styles';

type Props = {
    count: number;
    flagType: string;
    handleToggle: () => void;
    iconColor: keyof typeof colours;
    iconName: IconKeys;
    isToggled: boolean;
    text: string;
};

const HeaderStandard = ({
    count,
    flagType,
    handleToggle,
    iconColor,
    iconName,
    isToggled,
    text,
}: Props) => {
    return (
        <VisibleContent
            visible={isToggled}
            onClick={handleToggle}
            data-testid="flag-header-standard"
        >
            <IconDecorated
                sizeRem={1}
                name={isToggled ? 'KeyboardArrowUp' : iconName}
                paletteColor={iconColor}
            />
            <FlagContent>
                <div className="flag-type">{flagType}</div>
                <div className="flag-context">{text}</div>
                <div className="discrepancies">{count}</div>
            </FlagContent>
        </VisibleContent>
    );
};

export default HeaderStandard;
