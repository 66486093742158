import PropertyValue from './property-value';
import { Container } from './styles';

type Props = {
    properties?: Record<string, string | number | undefined>;
    awayTeamAlias?: string;
    awayTeamProperties?: Record<string, string | number | undefined>;
    homeTeamAlias?: string;
    homeTeamProperties?: Record<string, string | number | undefined>;
    split?: boolean;
};

const GameProperties = ({
    properties,
    awayTeamAlias,
    awayTeamProperties,
    homeTeamAlias,
    homeTeamProperties,
    split = false,
}: Props) => {
    return (
        <Container data-testid="game-properties" split={split}>
            {awayTeamAlias && awayTeamProperties && (
                <div className="property-section property-group">
                    <div className="property">
                        <span className="property-label">{awayTeamAlias}</span>
                        <div className="property-group">
                            {Object.entries(awayTeamProperties).map(([property, value]) => (
                                <div className="property" key={property}>
                                    <span className="property-label">{property}</span>
                                    <PropertyValue value={value} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {homeTeamAlias && homeTeamProperties && (
                <div className="property-section property-group">
                    <div className="property">
                        <span className="property-label">{homeTeamAlias}</span>
                        <div className="property-group">
                            {Object.entries(homeTeamProperties).map(([property, value]) => (
                                <div className="property" key={property}>
                                    <span className="property-label">{property}</span>
                                    <PropertyValue value={value} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {properties && (
                <div className="property-section property-group">
                    {Object.entries(properties).map(([property, value]) => (
                        <div className="property-group" key={property}>
                            <div className="property">
                                <span className="property-label">{property}</span>
                                <PropertyValue value={value} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Container>
    );
};

export default GameProperties;
