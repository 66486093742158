import Button from 'components/button';
import Modal from 'components/modal';

import { TeamInterface } from '../../types';

import { GameSelectTeamContainer, TeamContainer, Title } from './styles';

type Props = {
    open: boolean;
    onClose: () => void;
    onOpenTeamMappingClick: (id: string) => void;
    teams: TeamInterface[];
};

const GameSelectTeam = ({ onOpenTeamMappingClick, open, onClose, teams }: Props) => (
    <Modal open={open} onBackDropClick={onClose}>
        <GameSelectTeamContainer data-testid={'game-select-team-container'}>
            <Title>Click on the team you would like to change the mappings:</Title>
            <TeamContainer>
                {teams
                    .filter((team) => typeof team.id === 'string' && typeof team.name === 'string')
                    .map((team) => (
                        <Button
                            variant="variant5"
                            key={`team-${team.id}`}
                            onClick={() => onOpenTeamMappingClick(team.id as string)}
                        >
                            {team.name}
                        </Button>
                    ))}
            </TeamContainer>
        </GameSelectTeamContainer>
    </Modal>
);

export default GameSelectTeam;
