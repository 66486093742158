import { useCallback, useMemo, useState } from 'react';

export type Comparator<A, B> = (a: A, b: B) => boolean;

type SelectorOpts<T> = {
    initial?: T;
    options: T[];
    compare?: Comparator<T, T>;
};

export function useSelector<T>({
    initial,
    options,
    compare = (left: T, right: T) => left === right,
}: SelectorOpts<T>): [T | undefined, T[], (option: T | undefined) => void] {
    const [selectedOption, setSelectedOption] = useState(initial);

    const handleChange = useCallback(
        (option: T | undefined) => {
            setSelectedOption(option);
        },
        [setSelectedOption],
    );

    const opts = useMemo(() => {
        return options.map((option: T) => ({
            ...option,
            selected: compare && selectedOption && compare(option, selectedOption),
        }));
    }, [selectedOption, options, compare]);

    return [selectedOption, opts, handleChange];
}
