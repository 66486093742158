import { Route } from 'type-route';

import { Team } from 'api/types/leagues';
import useTeamCrest from 'app/hooks/useTeamCrest';
import { routes } from 'app/router';
import { IconDecorated } from 'components/icons';
import SportradarImage from 'components/sr-logo';

import FlagCount from '../flag-count';

import { TeamContainer, TeamContent } from './styles';

type FlagProps = {
    team: Team;
    route: Route<typeof routes.teams>;
    createdNumber: number | null;
    ignoredNumber: number | null;
};

const TeamListItem = ({ team, route, createdNumber, ignoredNumber }: FlagProps) => {
    const teamCrest = useTeamCrest(team.srGlobalId);

    return (
        <TeamContainer>
            <TeamContent
                {...routes.teamsTeam({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: team.srId,
                }).link}
            >
                <div className="logo">
                    {teamCrest && (
                        <SportradarImage
                            src={teamCrest}
                            heightRem={1.5}
                            placeholder={<IconDecorated name="GroupWorkRounded" sizeRem={1.5} />}
                        />
                    )}
                </div>
                <div>
                    {team.market ?? 'N/A'} {team.name}
                </div>
                <FlagCount
                    count={createdNumber}
                    icon={
                        <IconDecorated
                            sizeRem={1}
                            name="OutlinedFlag"
                            schemeColor="--color-flagged-discrepancy-count"
                        />
                    }
                    color="--color-flagged-discrepancy-count"
                />
                <FlagCount
                    count={ignoredNumber}
                    icon={<IconDecorated sizeRem={1} name="IgnoredFlag" paletteColor="black300" />}
                    color="--color-ignored-discrepancy-count"
                />
                <IconDecorated
                    sizeRem={1}
                    color="var(--color-flags-accordion-borderline)"
                    name="KeyboardArrowRight"
                />
            </TeamContent>
        </TeamContainer>
    );
};

export default TeamListItem;
