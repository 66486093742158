import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { LiveFlags } from 'api/types/live-flags';
import { useCurrentSeason } from 'app/useCurrentSeason';
import { LeagueType } from 'types';

import { LeagueKeys } from './queries';

const getGameFlagStats = async (
    league: LeagueType,
    gameId: string,
): Promise<LiveFlags.GameFlagStats> => {
    const { data } = await client.post<LiveFlags.GameFlagStats>(
        `${API.BFF_API}/${league}/games/flag-stats`,
        [gameId],
    );

    return data;
};

export type Settings = {
    gameId: string;
    enabled?: boolean;
};

export default function useGameFlagStats({ gameId, enabled }: Readonly<Settings>) {
    const { league } = useCurrentSeason();

    return useQuery(LeagueKeys.flagStats(league, gameId), () => getGameFlagStats(league, gameId), {
        refetchOnWindowFocus: false,
        enabled,
    });
}
