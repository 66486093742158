import { TabButtonContainer } from './styles';

type Props = {
    active?: boolean;
    dotted?: boolean;
    children: JSX.Element | string;
    onClick?: () => void;
};

const TabButton = (props: Props) => <TabButtonContainer data-testid="button" {...props} />;

export default TabButton;
