import * as React from 'react';
import { Route } from 'type-route';

import { routes } from 'app/router';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';

type Props = {
    gamesLabel: string;
    route: Route<typeof routes.gameBoxscores | typeof routes.gameBoxscoresGames>;
};

const Navigation = ({ gamesLabel, route }: Props) => {
    const handleGameBoxscoresRedirect = React.useCallback(
        () =>
            routes
                .gameBoxscores({
                    league: route.params.league,
                    seasonYear: route.params.seasonYear,
                    seasonType: route.params.seasonType,
                    week: route.params?.week,
                })
                .push(),
        [route.params],
    );
    const handleGameBoxscoresGamesRedirect = React.useCallback(
        () =>
            routes
                .gameBoxscoresGames({
                    league: route.params.league,
                    seasonYear: route.params.seasonYear,
                    seasonType: route.params.seasonType,
                    week: route.params?.week,
                })
                .push(),
        [route.params],
    );

    return (
        <TabMenu data-testid="navigation">
            <TabButton
                data-testid="all"
                active={route.name === 'gameBoxscores'}
                onClick={handleGameBoxscoresRedirect}
            >
                All Boxscores
            </TabButton>
            <TabButton
                data-testid="games"
                active={route.name === 'gameBoxscoresGames'}
                onClick={handleGameBoxscoresGamesRedirect}
            >
                {gamesLabel}
            </TabButton>
        </TabMenu>
    );
};

export default Navigation;
