import { createContext } from 'react';

import { AuthState, initialAuthState } from './auth-state';

export interface SportsMediaAuthContextInterface extends AuthState {
    loginWithRedirect: () => Promise<void>;
    logout: () => Promise<void>;
    getIdToken: () => string;
    getAccessToken: () => string;
    refreshTokens: () => Promise<void>;
}

const stub = (): never => {
    throw new Error('Wrap your component in <SportsMediaAuthProvider>');
};

const initialContext: SportsMediaAuthContextInterface = {
    ...initialAuthState,
    loginWithRedirect: stub,
    logout: stub,
    getIdToken: stub,
    getAccessToken: stub,
    refreshTokens: stub,
};

const SportsMediaAuthContext = createContext<SportsMediaAuthContextInterface>(initialContext);

export default SportsMediaAuthContext;
