import { css, Global } from '@emotion/react';

import BgContentTopRight from '../../assets/body-bg-top-right.png';
import BgContentTopRightDark from '../../assets/body-bg-top-right_dark.png';
import BgSidebarBottomLeft from '../../assets/sidebar-bg-bottom-left.png';
import BgSidebarBottomLeftDark from '../../assets/sidebar-bg-bottom-left_dark.png';

export const GlobalAssets = () => (
    <Global
        styles={css`
            --bg-sidebar-bottom-left: var(--color-container-background-color)
                url(${BgSidebarBottomLeft}) no-repeat bottom;
            --bg-content-top-right: url(${BgContentTopRight}) no-repeat top right;

            @media (prefers-color-scheme: dark) {
                --bg-sidebar-bottom-left: var(--color-container-background-color)
                    url(${BgSidebarBottomLeftDark}) no-repeat bottom;
                --bg-content-top-right: url(${BgContentTopRightDark}) no-repeat top right;
            }

            :root[data-color-scheme-config='dark'] {
                --bg-sidebar-bottom-left: var(--color-container-background-color)
                    url(${BgSidebarBottomLeftDark}) no-repeat bottom;
                --bg-content-top-right: url(${BgContentTopRightDark}) no-repeat top right;
            }

            :root[data-color-scheme-config='light'] {
                --bg-sidebar-bottom-left: var(--color-container-background-color)
                    url(${BgSidebarBottomLeft}) no-repeat bottom;
                --bg-content-top-right: url(${BgContentTopRight}) no-repeat top right;
            }
        `}
    />
);
