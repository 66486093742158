import * as React from 'react';

import { DiscrepancyType } from 'api/types/bff';
import Discrepancy from 'components/flag-accordion/discrepancy';

import BulkActions from '../../bulk-actions';
import useBulkActions from '../../bulk-actions/hooks/use-bulk-actions';
import { DiscrepanciesContainer, DiscrepancyRow } from '../boxscore/styles';

type Props = {
    discrepancies: DiscrepancyType[];
};

const GameDiscrepancies = ({ discrepancies }: Props) => {
    const { checkedDiscrepancies, handleToggleCheckDiscrepancy, handleToggleAll, isActive } =
        useBulkActions([...discrepancies].map((d) => d.id));

    if (!discrepancies.length) {
        return null;
    }

    return (
        <DiscrepanciesContainer>
            <div className="header">Game Discrepancies ({discrepancies.length}):</div>
            <div className="list">
                <BulkActions
                    isActive={isActive}
                    checkedDiscrepancyIds={checkedDiscrepancies}
                    onToggleSelect={handleToggleAll}
                />
                {discrepancies.map((discrepancy) => (
                    <DiscrepancyRow
                        key={`d-row-${discrepancy.id}`}
                        onClick={() => handleToggleCheckDiscrepancy(discrepancy.id)}
                    >
                        <div className="title">{discrepancy.name}</div>
                        <Discrepancy discrepancy={discrepancy} includeDiscrepancyName={false} />
                    </DiscrepancyRow>
                ))}
            </div>
        </DiscrepanciesContainer>
    );
};

export default GameDiscrepancies;
