import * as React from 'react';

import { FlagsSortingAttribute } from 'api/types/bff';
import { Option } from 'types/controls';
import { Order, Sorting } from 'types/sorting';

const DEFAULT_OPTIONS: Option<Sorting<FlagsSortingAttribute>>[] = [
    {
        label: 'Alphabetically (A to Z)',
        value: { attribute: FlagsSortingAttribute.DisplayName, order: Order.Asc },
    },
    {
        label: 'Alphabetically (Z to A)',
        value: { attribute: FlagsSortingAttribute.DisplayName, order: Order.Desc },
    },
    {
        label: 'Flag - Most Recent',
        value: { attribute: FlagsSortingAttribute.DiscrepancyDate, order: Order.Desc },
    },
    {
        label: 'Flag - Oldest',
        value: { attribute: FlagsSortingAttribute.DiscrepancyDate, order: Order.Asc },
    },
    {
        label: 'Event - Most Recent',
        value: { attribute: FlagsSortingAttribute.EventDate, order: Order.Desc },
    },
    {
        label: 'Event - Oldest',
        value: { attribute: FlagsSortingAttribute.EventDate, order: Order.Asc },
    },
    {
        label: 'Most Flags',
        value: { attribute: FlagsSortingAttribute.DiscrepancyCount, order: Order.Desc },
    },
    {
        label: 'Least Flags',
        value: { attribute: FlagsSortingAttribute.DiscrepancyCount, order: Order.Asc },
    },
];

export function useFlagsSortingOptions(
    attributes: FlagsSortingAttribute[],
    current?: Sorting<FlagsSortingAttribute>,
): Option<Sorting<FlagsSortingAttribute>>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.filter(
                (option) => attributes?.includes(option.value.attribute) ?? true,
            ).map((option) => ({
                label: option.label,
                value: option.value,
                selected:
                    option.value.attribute === current?.attribute &&
                    option.value.order === current?.order,
            })),
        [current, attributes],
    );
}
