import dayjs from 'dayjs';
import * as React from 'react';

import { ContextType, LeagueType } from 'types';

import { IconDecorated } from '../icons';
import Popover from '../popover';
import Button from '../primitives/button';
import Tooltip from '../primitives/tooltip';
import Tasks from '../tasks';
import TaskDetailModal from '../tasks/components/task-detail-modal';
import { Task } from '../tasks/hooks/useGroupTasks';
import useSystemEvent from '../tasks/hooks/useSystemEvent';
import useSystemEvents from '../tasks/hooks/useSystemEvents';

import { FlagSystemEventsContainer, Header } from './styles';

type Props = {
    league: LeagueType;
    seasonYear: string;
    seasonType: string;
    contextObjectId: string;
    context: ContextType;
};

const FlagSystemEvents = ({ league, seasonType, seasonYear, context, contextObjectId }: Props) => {
    const pk = React.useMemo(() => {
        return `${league}#${seasonYear}#${seasonType}#${context}#${contextObjectId}`;
    }, [context, contextObjectId, league, seasonType, seasonYear]);
    const [task, setTask] = React.useState<Task | null>(null);
    const [expanded, setExpanded] = React.useState(false);
    const { isLoading: loadingSystemEvent } = useSystemEvent(task?.pk, task?.sk);
    const { isLoading, data, dataUpdatedAt, isRefetching, refetch } = useSystemEvents(expanded, pk);
    const placeholderRef = React.useRef<HTMLDivElement>(null!);
    const placeholder = (
        <Tooltip title="See the system events related to this flag">
            <div ref={placeholderRef} data-testid="system-events-list">
                <IconDecorated sizeRem={1.25} name="FormatListBulleted" />
            </div>
        </Tooltip>
    );

    const handleOpen = () => {
        setExpanded(true);
    };

    const handleClose = () => {
        setExpanded(false);
        setTask(null);
    };

    const handleViewTaskDetails = React.useCallback((t: Task) => {
        setTask(t);
    }, []);

    const handleCloseModal = () => {
        setTask(null);
    };

    const anchorVerticalOrigin = placeholderRef.current
        ? placeholderRef.current.offsetHeight + 2
        : 'bottom';

    const updated = dayjs(dataUpdatedAt !== 0 ? dataUpdatedAt : undefined).format(
        'MM/DD/YY hh:mm A z',
    );

    return (
        <Popover
            placeholder={placeholder}
            anchorOrigin={{ horizontal: 'right', vertical: anchorVerticalOrigin }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            {() => {
                return (
                    <FlagSystemEventsContainer>
                        <Header>
                            <span>Tasks</span>
                            <div className="controls">
                                <Tooltip title="Last time the data was pulled from the system">
                                    <span>Updated {updated}</span>
                                </Tooltip>
                                <Button
                                    noBorder
                                    onClick={() => !isRefetching && refetch()}
                                    disabled={isLoading}
                                >
                                    {isRefetching ? 'Repulling..' : 'Repull'}
                                </Button>
                            </div>
                        </Header>
                        <Tasks
                            expanded
                            loading={isLoading}
                            systemEvents={data ?? []}
                            onViewTaskDetails={handleViewTaskDetails}
                        />
                        <TaskDetailModal
                            open={task !== null}
                            task={task}
                            onClose={handleCloseModal}
                            loading={loadingSystemEvent}
                        />
                    </FlagSystemEventsContainer>
                );
            }}
        </Popover>
    );
};

export default FlagSystemEvents;
