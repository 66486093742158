const STAGE = process.env.REACT_APP_STAGE;

const domain =
    STAGE === 'prod' ? `${STAGE}-api.smdatamonitoring.com` : `${STAGE}-api.smdatamonitoring.dev`;

const API = {
    PERMISSIONS_API: `https://${domain}`,
    FLAGS_API: `https://${domain}/flags`,
    MAPPINGS_API: `https://${domain}/mapper`,
    USERS_API: `https://${domain}`,
    LEAGUES_API: `https://${domain}/leagues`,
    BFF_API: `https://${domain}/bff`,
    LIVE_FLAGS_API: `https://${domain}/live-flags`,
    USER_MANAGEMENT_API: `https://${domain}/user-management`,
    SYSTEM_EVENTS_API: `https://${domain}/system-events`,
};

export default API;
