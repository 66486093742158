import { Container, Spinner } from './styles';

type Props = {
    mt?: number;
};
const Loading = ({ mt }: Props) => (
    <Container mt={mt} data-testid="loading-spinner">
        <Spinner />
    </Container>
);

export default Loading;
