import styled from '@emotion/styled';

export const ViewHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.1875rem;
`;

export const BackButton = styled.span`
    cursor: pointer;
    color: var(--color-button);
    text-decoration: none;
    text-transform: uppercase;
    font-family: Roboto, serif;
    font-size: 0.5625rem;
    font-weight: 700;
    line-height: 0.6875rem;
`;

export const LayoutContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    & > .content-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        & .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 0.5rem;
        }

        & .title {
            font-family: Calistoga, serif;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.9375rem;
        }
    }
`;
