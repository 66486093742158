import { GameStatus } from 'api/types/leagues';
import LinescoreTable from 'components/linescore-table';
import { Score } from 'components/linescore-table/types';

import GameProperties from '../../game-properties';
import GameSummary from '../../game-summary';

import { GameOverviewContainer, Header } from './styles';

type Props = {
    status?: GameStatus;
    scheduledDate?: string;
    sequenceInfo?: string;
    properties?: Record<string, string | number | undefined>;
    minColumnCount?: number;
    splitProperties?: boolean;
    awayTeamAlias?: string;
    awayTeamName?: string;
    awayTeamScores?: Score[];
    awayTeamSrGlobalId?: string | null;
    awayTeamTotalScore?: Score;
    awayTeamProperties?: Record<string, string | number | undefined>;
    homeTeamAlias?: string;
    homeTeamName?: string;
    homeTeamScores?: Score[];
    homeTeamSrGlobalId?: string | null;
    homeTeamTotalScore?: Score;
    homeTeamProperties?: Record<string, string | number | undefined>;
};

const BasicGameOverviewHeader = ({
    status,
    scheduledDate,
    sequenceInfo,
    properties,
    minColumnCount = 4,
    splitProperties,
    awayTeamAlias,
    awayTeamName,
    awayTeamScores = [],
    awayTeamSrGlobalId,
    awayTeamTotalScore,
    awayTeamProperties,
    homeTeamAlias,
    homeTeamName,
    homeTeamScores = [],
    homeTeamSrGlobalId,
    homeTeamTotalScore,
    homeTeamProperties,
}: Props) => {
    const hasOvertime =
        homeTeamScores.length > minColumnCount || awayTeamScores.length > minColumnCount;

    return (
        <Header data-testid="game-overview-header">
            <div className="inner-container">
                <GameOverviewContainer>
                    <GameSummary
                        awayTeamName={awayTeamName}
                        awayTeamScore={awayTeamTotalScore?.value}
                        awayTeamSrGlobalId={awayTeamSrGlobalId}
                        gameDate={scheduledDate}
                        gameStatus={status}
                        sequenceInfo={sequenceInfo}
                        homeTeamName={homeTeamName}
                        homeTeamScore={homeTeamTotalScore?.value}
                        homeTeamSrGlobalId={homeTeamSrGlobalId}
                    />
                    <div className="linescore-table-container" data-has-overtime={hasOvertime}>
                        <LinescoreTable
                            awayTeamAlias={awayTeamAlias}
                            awayTeamScores={awayTeamScores}
                            awayTeamTotalScore={awayTeamTotalScore}
                            colorScheme="dark"
                            homeTeamAlias={homeTeamAlias}
                            homeTeamScores={homeTeamScores}
                            homeTeamTotalScore={homeTeamTotalScore}
                            minColumnCount={minColumnCount}
                        />
                    </div>
                </GameOverviewContainer>
                <div className="divider" />
                <div className="game-properties-container">
                    <GameProperties
                        properties={properties}
                        awayTeamAlias={awayTeamAlias}
                        awayTeamProperties={awayTeamProperties}
                        homeTeamAlias={homeTeamAlias}
                        homeTeamProperties={homeTeamProperties}
                        split={splitProperties}
                    />
                </div>
            </div>
        </Header>
    );
};

export default BasicGameOverviewHeader;
