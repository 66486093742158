import { GameStatus } from 'api/types/leagues';
import GameStatusChip from 'components/game-status-chip';

import { CurrentStatus, Summary } from './styles';
import TeamSection from './team-section';

type Props = {
    awayTeamName?: string;
    awayTeamScore?: number;
    awayTeamSrGlobalId?: string | null;
    gameDate?: string;
    gameStatus?: GameStatus;
    sequenceInfo?: string;
    homeTeamName?: string;
    homeTeamScore?: number;
    homeTeamSrGlobalId?: string | null;
};

const GameSummary = ({
    awayTeamName,
    awayTeamScore,
    awayTeamSrGlobalId,
    gameDate = '-',
    gameStatus = GameStatus.Closed,
    sequenceInfo,
    homeTeamName,
    homeTeamScore,
    homeTeamSrGlobalId,
}: Props) => {
    return (
        <Summary>
            <div className="content-layout" data-testid="game-overview">
                <TeamSection
                    isHome={false}
                    name={awayTeamName}
                    score={awayTeamScore}
                    srGlobalId={awayTeamSrGlobalId}
                />
                <CurrentStatus>
                    <span className="game-date">{gameDate}</span>
                    <GameStatusChip status={gameStatus} />
                    {sequenceInfo && <span>{sequenceInfo}</span>}
                </CurrentStatus>
                <TeamSection
                    isHome={true}
                    name={homeTeamName}
                    score={homeTeamScore}
                    srGlobalId={homeTeamSrGlobalId}
                />
            </div>
        </Summary>
    );
};

export default GameSummary;
