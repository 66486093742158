import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import { SourceId } from 'types';

import { teamMappingsKeys } from './queries';

async function updateMappings({
    league,
    source,
    seasons,
    teams,
    players,
    schedules,
}: MappingsMutationParams): Promise<MappingsMutationResponse> {
    const response = await client.put<MappingsMutationResponse>(
        `${API.MAPPINGS_API}/map/${league}/manual/${source}`,
        {
            league,
            source,
            seasons,
            teams,
            players,
            schedules,
        },
    );

    return response?.data;
}

export type Mapping = {
    id: string;
    srId: string | null;
};

export type MappingsMutationParams = {
    league: string;
    source: SourceId;
    seasons?: Mapping[];
    teams?: Mapping[];
    players?: Mapping[];
    schedules?: Mapping[];
};

export type MappingsMutationResponse = {
    test: string;
};

export function useMappingsMutation(league: string, source: string, teamId: string) {
    const queryClient = useQueryClient();

    return useMutation(updateMappings, {
        onSuccess: () => {
            queryClient.refetchQueries(
                teamMappingsKeys.teamMappingsStatsByLeagueTeamId(league, teamId),
            );
            queryClient.refetchQueries(teamMappingsKeys.teamMappingsAll(league, source, teamId));
        },
        onError: async (error) => {
            console.error(error);
        },
        retry: 2,
    });
}
