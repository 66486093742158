import { ColorScheme } from 'app/theme/color-scheme';

import { FlagCountContainer } from './styles';

type FlagCountProps = {
    count: number | null;
    icon: JSX.Element;
    color: keyof ColorScheme;
};

const FlagCount = ({ count, icon, color }: FlagCountProps) => {
    return (
        <FlagCountContainer color={color}>
            {!!count && (
                <>
                    {icon}
                    <div className="text">{count}</div>
                </>
            )}
        </FlagCountContainer>
    );
};

export default FlagCount;
