import { useCallback } from 'react';

import { ColorSchemeConfig, useColorSchemeConfig } from '../../app/theme/useColorScheme';
import { Icon } from '../icons';

import { ThemeIconContainer, ThemeOption } from './styles';

const isActiveMode = (mode: ColorSchemeConfig, checkMode: 'dark' | 'light') => {
    if (mode !== 'system') {
        return mode === checkMode;
    }

    // in case of system theme setting we need to know which one is chosen
    if (checkMode === 'dark') {
        const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

        return darkThemeMq.matches;
    }

    if (checkMode === 'light') {
        const lightThemeMq = window.matchMedia('(prefers-color-scheme: light)');

        return lightThemeMq.matches;
    }
};

const ThemeSwitch = () => {
    const [colorSchemeConfig, setColourSchemeConfig] = useColorSchemeConfig();

    const handleChangeDarkMode = useCallback(() => {
        setColourSchemeConfig('dark');
    }, [setColourSchemeConfig]);

    const handleChangeLightMode = useCallback(() => {
        setColourSchemeConfig('light');
    }, [setColourSchemeConfig]);

    return (
        <ThemeIconContainer data-testid="theme-switch">
            <ThemeOption
                onClick={handleChangeLightMode}
                active={isActiveMode(colorSchemeConfig, 'light')}
                data-testid="theme-switch-light"
            >
                <div className="icon-wrapper">
                    <Icon name="LightMode" />
                </div>
            </ThemeOption>
            <ThemeOption
                onClick={handleChangeDarkMode}
                active={isActiveMode(colorSchemeConfig, 'dark')}
                data-testid="theme-switch-dark"
            >
                <div className="icon-wrapper">
                    <Icon name="DarkMode" />
                </div>
            </ThemeOption>
        </ThemeIconContainer>
    );
};

export default ThemeSwitch;
