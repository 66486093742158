import styled from '@emotion/styled';

export const FlagSystemEventsContainer = styled.div`
    width: 100%;
    min-width: 30vw;
    display: flex;
    background-color: var(--color-background);
    border-radius: 0.2rem;
    border: 0.0625rem solid var(--color-dropdown-border);
    padding: 0.5rem 1rem;
    flex-direction: column;
`;

export const Header = styled.div`
    color: var(--color-text);
    font-size: 0.875rem;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    width: 100%;
    align-items: center;

    & > .controls {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
`;
