import * as React from 'react';

import { ExternalSourceId, SourceId } from 'types';

import useMappingStats, { MappingsStats, MappingsType } from './useMappingsStats';

function getUnmappedCount(
    data: MappingsStats,
    league: string,
    source: SourceId,
    mappingsType: MappingsType[],
) {
    return {
        source,
        value: mappingsType
            .map(
                (mappingsType) =>
                    (data[league][mappingsType][source]?.autoUnmapped ?? 0) +
                    (data[league][mappingsType][source]?.manuallyUnmapped ?? 0),
            )
            .reduce((sum, value) => sum + value, 0),
    };
}

function useMappingsSourceSelector(sources: ExternalSourceId[], league: string, teamId: string) {
    const mappingStatsQuery = useMappingStats(league, teamId);

    return React.useMemo(() => {
        if (!mappingStatsQuery.data) {
            return sources.map((source) => ({ source, value: 0 }));
        }

        return sources.map((source) =>
            getUnmappedCount(mappingStatsQuery.data, league, source, [
                MappingsType.Players,
                MappingsType.Schedules,
            ]),
        );
    }, [mappingStatsQuery.data, sources, league]);
}

export default useMappingsSourceSelector;
