import styled from '@emotion/styled';

export const MappingInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    font-weight: bold;

    width: 100%;
    border-width: 0 0 0.0625rem 0;
    border-style: solid;
    border-color: var(--color-team-mappings-row-separator);
    padding-top: 0.5rem;
    padding-bottom: 0.375rem;

    & > .left {
        display: flex;
        gap: 0.5rem;
    }
`;

export const MappingContainer = styled.div`
    display: flex;
`;
