import { LiveFlags } from 'api/types/live-flags';
import ActionButton from 'components/action-button';

import { DiscrepancyStatus } from '../../types';

import { DiscrepancyStatusContainer } from './styles';

type Props = {
    onClick: () => void;
    status: DiscrepancyStatus;
};

const DiscrepancyActionButton = ({ status, onClick }: Props) => {
    if (status !== LiveFlags.FlagStatus.CREATED) {
        return (
            <DiscrepancyStatusContainer>
                {LiveFlags.FLAG_STATUS_MAP[status]}
            </DiscrepancyStatusContainer>
        );
    }

    return (
        <DiscrepancyStatusContainer>
            <ActionButton onClick={onClick} content="Ignore" iconName="Texture" variant="primary" />
        </DiscrepancyStatusContainer>
    );
};

export default DiscrepancyActionButton;
