import * as React from 'react';

export function useToggle(expanded?: boolean) {
    const [isToggled, setToggled] = React.useState(false);

    const handleToggle = React.useCallback(() => {
        setToggled((prev) => !prev);
    }, [setToggled]);

    React.useEffect(() => {
        setToggled(!!expanded);
    }, [expanded]);

    return {
        handleToggle,
        isToggled,
    };
}
