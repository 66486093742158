import * as React from 'react';
import { Route } from 'type-route';

import { GameStatus } from 'api/types/leagues';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ButtonGoBack from 'components/go-back-button';
import Loading from 'components/loading';
import usePrevious from 'hooks/usePrevious';

import GameOverviewHeader from './components/game-overview-header';
import GameViewTabs from './components/game-view-tabs';
import LiveGameSection from './components/live-game-section';
import { isInLimits, StatusConfigs } from './components/live-game-section/constants';
import PostGameSection from './components/post-game-section';
import useLiveGame from './hooks/useLiveGame';
import { GameContainer } from './styles';
import { GameView } from './types';

type Props = {
    route: Route<typeof routes.gameBoxscoresGame>;
};

const BoxscoreGame = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);

    const liveSource = route.params.liveSource ?? leagueDetails.supportedLiveGameSources[0];

    const [gameView, setGameView] = React.useState<GameView>();

    const limit =
        route.params.liveLimit && isInLimits(route.params.liveStatus, route.params.liveLimit)
            ? route.params.liveLimit
            : StatusConfigs[route.params.liveStatus].defaultLimit;

    const liveGameQuery = useLiveGame(route.params.id, route.params.league, liveSource, {
        seasonYear: route.params.seasonYear,
        seasonType: route.params.seasonType,
        limit: `${limit}`,
        status: route.params.liveStatus ?? 'all',
        context: route.params.liveCategory ?? 'all',
        statName: route.params.liveType ?? 'all',
    });

    // Needed so the whole page doesn't load every time we change React Query key.
    const liveGame = usePrevious(liveGameQuery?.data);

    React.useEffect(() => {
        if (liveGame?.awayTeam?.alias && liveGame?.homeTeam?.alias) {
            window.document.title = `${liveGame?.awayTeam?.alias} @ ${liveGame?.homeTeam?.alias}`;
        }

        return () => {
            window.document.title = 'UAPI Monitor';
        };
    }, [liveGame]);

    const hasGameFinished = liveGame?.status
        ? liveGame?.status === GameStatus.Complete || liveGame?.status === GameStatus.Closed
        : undefined;

    const handleGoBack = React.useCallback(() => {
        routes
            .gameBoxscoresGames({
                league: route.params.league,
                seasonType: route.params.seasonType,
                seasonYear: route.params.seasonYear,
            })
            .push();
    }, [route.params]);

    React.useEffect(() => {
        if (hasGameFinished !== undefined) {
            setGameView(hasGameFinished ? GameView.POST_GAME : GameView.LIVE_GAME);
        }
    }, [hasGameFinished]);

    if (!liveGame) {
        return <Loading mt={10} />;
    }

    return (
        <GameContainer data-testid="game-container">
            <div>
                <ButtonGoBack
                    className="go-back-button"
                    content={`Back to games: ${route.params.seasonYear}`}
                    onClick={handleGoBack}
                />
                <GameOverviewHeader league={route.params.league} game={liveGame} />
            </div>
            {gameView && hasGameFinished && (
                <GameViewTabs gameView={gameView} onChange={setGameView} />
            )}
            <div>
                {liveGameQuery.isLoading && <Loading mt={10} />}
                {!liveGameQuery.isLoading && gameView === GameView.POST_GAME && (
                    <PostGameSection route={route} />
                )}
                {!liveGameQuery.isLoading && gameView === GameView.LIVE_GAME && (
                    <LiveGameSection
                        game={liveGame}
                        liveSource={liveSource}
                        route={route}
                        updatedAt={liveGameQuery.dataUpdatedAt}
                        onRepullClick={liveGameQuery.refetch}
                    />
                )}
            </div>
        </GameContainer>
    );
};

export default BoxscoreGame;
