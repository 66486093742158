import * as React from 'react';

import useOptions from 'hooks/options/useOptions';
import { LeagueDetailsSeason, SeasonYear } from 'types';
import { Option } from 'types/controls';

function isEqual(a: LeagueDetailsSeason, b: LeagueDetailsSeason): boolean {
    return a.seasonYear === b.seasonYear;
}

function getOptions(
    seasons: LeagueDetailsSeason[],
    routeSeasonYear: SeasonYear,
): Option<LeagueDetailsSeason>[] {
    const hasSeasonYear = seasons.map(({ seasonYear }) => seasonYear).includes(routeSeasonYear);

    return seasons.map((season, index) => {
        const isRouteSeason = season.seasonYear === routeSeasonYear;
        const isLastSeason = index === seasons.length - 1;

        return {
            label: season.seasonYear,
            selected: hasSeasonYear ? isRouteSeason : isLastSeason,
            value: season,
        };
    });
}

export default function useSeasonOptions(
    seasons: LeagueDetailsSeason[],
    routeSeasonYear: SeasonYear,
) {
    return useOptions(
        React.useMemo(() => getOptions(seasons, routeSeasonYear), [seasons, routeSeasonYear]),
        isEqual,
    );
}
