import * as React from 'react';

import { DiscrepancyType } from 'api/types/bff';
import { byName } from 'components/flag-accordion/compare-discrepancies';
import useSorting from 'hooks/useSorting';

interface SplitDiscrepanciesByPlayerAndTeam {
    team: DiscrepancyType[];
    player: DiscrepancyType[];
}

export function useSplitDiscrepanciesByPlayerAndTeam(
    discrepancies: DiscrepancyType[],
): SplitDiscrepanciesByPlayerAndTeam {
    const sortDiscrepancies = useSorting(byName);

    return React.useMemo(() => {
        return {
            team: sortDiscrepancies(
                discrepancies.filter((d) => d.contextTeam !== null && d.contextPlayer === null),
            ),
            player: sortDiscrepancies(
                discrepancies.filter((d) => d.contextTeam !== null && d.contextPlayer !== null),
            ),
        };
    }, [discrepancies, sortDiscrepancies]);
}
