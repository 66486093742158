import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';

export interface ModalContainerProps extends HTMLAttributes<HTMLElement> {
    zIndex?: number;
}

export const ModalContainer = styled.div<ModalContainerProps>`
    overflow-y: hidden;

    & > .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-modal-background);
        overflow-y: hidden;
    }

    & > .content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        background-color: var(--color-modal-content-background);
        box-shadow: 0 0.125rem 0.25rem rgba(42, 116, 217, 0.120807);
        padding: ${(props) => props.style?.padding ?? '1.5rem 2rem'};
        min-width: ${(props) => props.style?.minWidth ?? '20rem'};
        border-radius: 0.3125rem;
        ${(props) => props.style?.padding ?? '1.5rem 2rem'};
        z-index: ${(props) => props.zIndex ?? 'unset'};
    }
`;
