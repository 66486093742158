import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Team } from 'api/types/leagues';
import ActionButton from 'components/action-button';
import { IconDecorated } from 'components/icons';
import { SourceId } from 'types';

// eslint-disable-next-line no-restricted-imports
import useColorBar from '../../../../hooks/useBarClass';
// eslint-disable-next-line no-restricted-imports
import useMappingActions from '../../../../hooks/useMappingActions';
// eslint-disable-next-line no-restricted-imports
import { Schedule } from '../../../../hooks/useTeamMappings';
// eslint-disable-next-line no-restricted-imports
import { MappingState } from '../../../../views/mapping';
import { ColorBar } from '../../color-bar/styles';
import { Attribute } from '../../dialog/attributes';
import { MappingDialog } from '../../dialog/map';
import { UnmappingDialog } from '../../dialog/unmap';
import { MappingContainer, MappingInfoContainer } from '../styles';

import { SchedulesInfoContainer } from './styles';

type Props = {
    team: Team;
    schedule: Schedule;
    onChange: (schedule: Schedule) => void;
};

const isMapped = (schedule: Schedule): boolean => !!schedule.id && !!schedule.mapped;

const createAttributes = (
    away: string | undefined,
    home: string | undefined,
    { date, stadium, id, source }: Schedule,
): Attribute[] => {
    return [
        { name: 'Away', value: away ?? 'Away' },
        { name: 'Home', value: home ?? 'Home' },
        { name: 'Date', value: date },
        { name: 'Location', value: stadium },
        { name: 'ID', value: id },
        { name: 'Source', value: source },
    ];
};

const ScheduleMapping = ({ team, schedule, onChange }: Props) => {
    const [action, setAction] = useMappingActions();
    const barColor = useColorBar(schedule.source, isMapped(schedule));

    const { control, setValue } = useFormContext<MappingState>();
    const schedulesState = useWatch({
        control,
        name: 'schedules',
    });

    const options = useMemo(
        () =>
            Object.keys(schedulesState)
                .map((id) => schedulesState[id])
                .filter(({ source }) => source !== schedule.source)
                .filter(({ mapped }) => !mapped)
                .map((p) => ({
                    label: `${p.name} - ${p.date}`.replace('@', ''),
                    value: p,
                    selected: false,
                })),
        [schedule.source, schedulesState],
    );

    const onCancel = useCallback(() => {
        setAction('none');
    }, [setAction]);

    const onRemap = useCallback(() => {
        setAction('remap');
    }, [setAction]);

    const onConfirmMapping = useCallback(
        (selectedSchedule: Schedule) => {
            if (action === 'remap') {
                const previousMappedSchedule = schedulesState[schedule.mapped as string];

                setValue(
                    `schedules.${previousMappedSchedule.id}`,
                    { ...previousMappedSchedule, mapped: null },
                    { shouldDirty: false },
                );
            }

            setAction('none');

            if (schedule.source === SourceId.SR) {
                setValue(
                    `schedules.${schedule.id}`,
                    { ...schedule, mapped: selectedSchedule.id },
                    { shouldDirty: false },
                );
                setValue(
                    `schedules.${selectedSchedule.id}`,
                    { ...selectedSchedule, mapped: schedule.id },
                    { shouldDirty: true },
                );

                return;
            }

            setValue(
                `schedules.${selectedSchedule.id}`,
                { ...selectedSchedule, mapped: schedule.id },
                { shouldDirty: false },
            );
            onChange({ ...schedule, mapped: selectedSchedule.id });
        },
        [action, onChange, schedule, schedulesState, setAction, setValue],
    );

    const onConfirmUnmapping = useCallback(() => {
        setAction('none');
        const mappedSchedule = schedulesState[schedule.mapped as string];

        if (schedule.source === SourceId.SR) {
            setValue(
                `schedules.${mappedSchedule.id}`,
                { ...mappedSchedule, mapped: null },
                { shouldDirty: true },
            );
            setValue(
                `schedules.${schedule.id}`,
                { ...schedule, mapped: null },
                { shouldDirty: false },
            );

            return;
        }

        setValue(
            `schedules.${mappedSchedule.id}`,
            { ...mappedSchedule, mapped: null },
            { shouldDirty: false },
        );
        onChange({ ...schedule, mapped: null });
    }, [onChange, schedule, schedulesState, setAction, setValue]);

    const { home, away } = useMemo(() => {
        if (schedule.name.startsWith('@')) {
            return { home: schedule.name.replace('@', ''), away: team.name };
        }

        return { home: team.name?.replace('@', ''), away: schedule.name };
    }, [schedule, team]);

    return (
        <MappingContainer>
            <ColorBar color={barColor} />
            <MappingInfoContainer>
                <SchedulesInfoContainer className="left">
                    <IconDecorated sizeRem={1} name="CalendarToday" paletteColor="yellow600" />
                    <div className="name">{schedule.name}</div>
                    <div className="date">{schedule.date}</div>
                    <div className="stadium">{schedule.stadium}</div>
                    <div className="id-srid">
                        {schedule.source === SourceId.SR ? 'SRID: ' : 'ID: '}
                        {schedule.id}
                    </div>
                </SchedulesInfoContainer>
                <ActionButton
                    iconName="SyncAlt"
                    content={isMapped(schedule) ? 'Unmap' : 'Map'}
                    onClick={() => setAction(isMapped(schedule) ? 'unmap' : 'map')}
                    variant="default"
                />
            </MappingInfoContainer>
            {action === 'map' && (
                <MappingDialog
                    open
                    isRemap={false}
                    title="Map to Existing Game"
                    leftCard={{
                        title: 'UNMAPPED GAME',
                        name: `${away} @ ${home}`,
                        attributes: createAttributes(away, home, schedule),
                    }}
                    rightCard={{
                        title: 'MAP TO EXISTING GAME',
                        name: ({ name }: Schedule) => name,
                        attributes: (s) => (s ? createAttributes(away, home, s) : []),
                    }}
                    options={options}
                    onConfirm={onConfirmMapping}
                    onCancel={onCancel}
                />
            )}
            {action === 'unmap' && (
                <UnmappingDialog
                    open
                    title="Unmap a Game"
                    card={{
                        title: 'GAME TO BE UNMAPPED',
                        name: schedule.name.replace('@', ''),
                        attributes: createAttributes(away, home, schedule),
                    }}
                    onConfirm={onConfirmUnmapping}
                    onCancel={onCancel}
                    onRemap={onRemap}
                />
            )}
            {action === 'remap' && (
                <MappingDialog
                    open
                    isRemap={true}
                    title="Remap a Game"
                    leftCard={{
                        title: 'GAME TO BE REMAPPED',
                        name: schedule.name.replace('@', ''),
                        attributes: createAttributes(away, home, schedule),
                    }}
                    rightCard={{
                        title: 'MAP TO EXISTING GAME',
                        name: ({ name }: Schedule) => name,
                        attributes: (s) => (s ? createAttributes(away, home, s) : []),
                    }}
                    options={options}
                    onConfirm={onConfirmMapping}
                    onCancel={onCancel}
                />
            )}
        </MappingContainer>
    );
};

export default ScheduleMapping;
