import { iot, mqtt5 } from 'aws-iot-device-sdk-v2';

import Env from '../lib/env';

import { AWSCognitoCredentialsProvider } from './cognito-credentials-provider';

export function createClient(provider: AWSCognitoCredentialsProvider): mqtt5.Mqtt5Client {
    let wsConfig: iot.WebsocketSigv4Config = {
        credentialsProvider: provider,
        region: Env.get('REACT_APP_AWS_REGION'),
    };

    let builder: iot.AwsIotMqtt5ClientConfigBuilder =
        iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
            Env.get('REACT_APP_IOT_ENDPOINT'),
            wsConfig,
        );

    return new mqtt5.Mqtt5Client(builder.build());
}
