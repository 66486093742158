import * as React from 'react';

import { Discrepancy } from '../../../types';
import DiscrepancyListGroup from '../discrepancy-list-group';

import { Props } from './type';

const DefaultDiscrepancyListGroup = ({ discrepancies, ...props }: Props) => {
    const discrepancyGroups = React.useMemo<[null, Discrepancy[]][]>(
        () => (discrepancies?.length ? [[null, discrepancies]] : []),
        [discrepancies],
    );

    return (
        <DiscrepancyListGroup
            {...props}
            discrepancyGroups={discrepancyGroups}
            displayGroupName={true}
        />
    );
};

export default DefaultDiscrepancyListGroup;
