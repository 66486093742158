import styled from '@emotion/styled';

export const StatsContainer = styled.div`
    display: flex;

    box-shadow: 0 0.125rem 0.25rem var(--color-flag-stats-box-shadow);
    border-radius: 0.1875rem;
    border: 0.0625rem solid var(--color-flag-stats-box-border);
    padding: 1.0625rem 0.9375rem;
    gap: 1rem;
`;
