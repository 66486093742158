import * as React from 'react';

import { IconDecorated } from 'components/icons';
import Modal from 'components/modal';

import { IconContainer, SuccessfullyIgnoredModalContainer } from './styles';

type Props = {
    open: boolean;
    onClose: () => void;
};

const SuccessfullyIgnoredModal = (props: Props) => {
    return (
        <Modal open={props.open} onBackDropClick={props.onClose}>
            <SuccessfullyIgnoredModalContainer>
                <IconContainer>
                    <IconDecorated sizeRem={1} name="Texture" paletteColor="gray700" />
                </IconContainer>
                <div className="messages">
                    <span className="title">Issue successfully ignored</span>
                    <span className="description">
                        Filter status to <i>Ignored</i> to find this issue again
                    </span>
                </div>
            </SuccessfullyIgnoredModalContainer>
        </Modal>
    );
};

export default SuccessfullyIgnoredModal;
