import * as React from 'react';

import { Option } from 'types/controls';

export function useWeekOptions(
    weeks: (string | number)[],
    current?: string,
): Option<string | undefined>[] {
    return React.useMemo(
        () => [
            {
                label: 'All',
                value: undefined,
                selected: current === undefined,
            },
            ...weeks.map((week) => ({
                label: week.toString(),
                selected: week.toString() === current,
                value: week.toString(),
            })),
        ],

        [current, weeks],
    );
}
