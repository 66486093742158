import * as React from 'react';

import { Option } from 'types/controls';

export enum MappingType {
    Game = 'schedules',
    Roster = 'players',
    All = 'all',
}

export function isMappingType(type: unknown): type is MappingType {
    return Object.values(MappingType).includes(type as MappingType);
}

const DEFAULT_OPTIONS: Option<MappingType>[] = [
    {
        label: 'ALL',
        value: MappingType.All,
    },
    {
        label: 'GAME',
        value: MappingType.Game,
    },
    {
        label: 'ROSTER',
        value: MappingType.Roster,
    },
];

export function useMappingTypeOptions(current: MappingType) {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: current === option.value,
            })),
        [current],
    );
}
