import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { SourceId } from 'types';

import { teamMappingsKeys } from './queries';

export type Schedule = {
    name: string;
    date: string;
    stadium: string;
    id: string;
    source: SourceId;
    mapped: string | null;
};

export type Player = {
    id: string;
    name: string;
    jersey: number;
    position: string;
    dateOfBirth?: string;
    year?: string;
    height?: string;
    weight?: string;
    birthPlace?: string;
    source: SourceId;
    mapped: string | null;
};

export type TeamMappings = {
    players: Player[];
    schedules: Schedule[];
};

export type TeamMappingsGroup = {
    unmapped?: TeamMappings;
    automatic?: TeamMappings;
    manual?: TeamMappings;
    all?: TeamMappings;
};

type Status = 'unmapped' | 'automatic' | 'manual' | 'all';

async function getMappings(
    league: string,
    source: SourceId,
    teamId: string,
    status: Status,
): Promise<TeamMappings> {
    type Data = {
        players: Player[];
        schedules: Schedule[];
    };

    const response = await client.get<Data>(
        `${API.MAPPINGS_API}/${league}/mappings/${source}/${teamId}?status=${status}`,
    );

    return { players: response.data?.players ?? [], schedules: response.data?.schedules ?? [] };
}

export function useTeamMappingsUnmapped(league: string, source: SourceId, teamId: string) {
    return useQuery(
        teamMappingsKeys.teamMappingsByLeagueSourceUnmapped(league, source, teamId),
        () => getMappings(league, source, teamId, 'unmapped'),
        { refetchOnWindowFocus: false },
    );
}

export function useTeamMappingsAutoMapped(league: string, source: SourceId, teamId: string) {
    return useQuery(
        teamMappingsKeys.teamMappingsByLeagueSourceAutoMapped(league, source, teamId),
        () => getMappings(league, source, teamId, 'automatic'),
        { refetchOnWindowFocus: false },
    );
}

export function useTeamMappingsManuallyMapped(league: string, source: SourceId, teamId: string) {
    return useQuery(
        teamMappingsKeys.teamMappingsByLeagueSourceManuallyMapped(league, source, teamId),
        () => getMappings(league, source, teamId, 'manual'),
        { refetchOnWindowFocus: false },
    );
}
