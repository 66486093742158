import dayjs from 'dayjs';
import * as React from 'react';
import { Route } from 'type-route';

import { FlagsSortingAttribute } from 'api/types/bff';
import { Division, GameStatus } from 'api/types/leagues';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import useLeagueInfo from 'app/useLeagueInfo';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import FlagStats from 'components/flag-stats';
import GameCard from 'components/game-card';
import { IconDecorated } from 'components/icons';
import Loading from 'components/loading';
import DateSelector, { DateSelectorValue } from 'components/primitives/date-selector';
import View from 'components/view';
import ViewHeader from 'components/view-header';
import useCoverageOptions from 'hooks/options/useCoverageOptions';
import { useFlagsSortingOptions } from 'hooks/options/useFlagsSortingOptions';
import { useGameStatusOptions } from 'hooks/options/useGameStatusOptions';
import { useLeagueHierarchyOptions } from 'hooks/options/useLeagueHierarchyOptions';
import { useWeekOptions } from 'hooks/options/useWeekOptions';
import { useChangeParams } from 'hooks/useChangeParams';
import useDivisions from 'hooks/useDivisions';
import useGamesGroupingStrategyLabel from 'hooks/useGameGroupingLabel';
import { Coverage } from 'types/coverage';
import { Sorting } from 'types/sorting';

import Navigation from '../../components/navigation';

import { useAdminLink } from './hooks/useAdminLink';
import { useFavouriteGames } from './hooks/useFavouriteGames';
import { useGames } from './hooks/useGames';
import { useGamesStats } from './hooks/useGamesStats';
import { useLdeLink } from './hooks/useLdeLink';
import { GamesContainer } from './styles';

type Props = {
    route: Route<typeof routes.gameBoxscoresGames>;
};

const Games = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const leagueInfoQuery = useLeagueInfo({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
    });
    const leagueGamesLabel = useGamesGroupingStrategyLabel(route.params.league);

    const statusOptions = useGameStatusOptions(route.params.status);
    const coverageOptions = useCoverageOptions(route.params.coverage);

    const divisionsQuery = useDivisions(
        { league: route.params.league },
        { enabled: leagueDetails.filters.leagueHierarchy },
    );
    const divisionOptions = useLeagueHierarchyOptions(divisionsQuery.data, route.params.division);

    const weekOptions = useWeekOptions(leagueInfoQuery.data?.weeks ?? [], route.params.week);
    const dateFrom = route.params.dateFrom ?? dayjs();
    const dateTo = route.params.dateTo ?? dayjs();
    const dateValues: DateSelectorValue = dateFrom.isSame(dateTo) ? [dateFrom] : [dateFrom, dateTo];

    const sortingOptions = useFlagsSortingOptions(
        [
            FlagsSortingAttribute.DiscrepancyCount,
            FlagsSortingAttribute.DiscrepancyDate,
            FlagsSortingAttribute.EventDate,
        ],
        { attribute: route.params.sortBy, order: route.params.sortOrder },
    );

    const { data: games = [], isLoading } = useGames({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        status: route.params.status,
        coverage: route.params.coverage,
        sortBy: route.params.sortBy,
        sortOrder: route.params.sortOrder,
        ...(leagueDetails.filters.leagueHierarchy && {
            divisionAlias: route.params.division,
        }),
        ...(leagueDetails.filters.gameWeek && {
            week: route.params.week,
        }),
        ...(leagueDetails.filters.gameDate && {
            dateFrom: dateFrom.format('YYYY-MM-DD'),
            dateTo: dateTo.format('YYYY-MM-DD'),
        }),
    });
    const gamesStats = useGamesStats(games);

    const [toggleFavoriteGame, sortedGames, isFavorite] = useFavouriteGames(games);

    const handleAdminClick = useAdminLink(route.params.league);
    const handleLdeClick = useLdeLink(route.params.league);

    const handleMonitorStatsClick = React.useCallback(
        (event) => {
            const srusGameId = event.target.getAttribute('data-game-srus-id');

            if (!srusGameId) {
                throw new Error('data-game-srus-id must be passed');
            }

            window.open(
                routes.gameBoxscoresGame({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: srusGameId,
                    liveSource: leagueDetails.supportedLiveGameSources[0],
                }).href,
                '_blank',
            );
        },
        [
            leagueDetails.supportedLiveGameSources,
            route.params.league,
            route.params.seasonType,
            route.params.seasonYear,
        ],
    );

    const handleParamsChange = useChangeParams(route);

    const handleDateChange = React.useCallback(
        (dates: DateSelectorValue) =>
            handleParamsChange({ dateFrom: dates[0], dateTo: dates[1] ?? dates[0] }),
        [handleParamsChange],
    );
    const handleWeekChange = React.useCallback(
        (week?: string) => handleParamsChange({ week }),
        [handleParamsChange],
    );
    const handleSortingChange = React.useCallback(
        (sorting: Sorting<FlagsSortingAttribute>) =>
            handleParamsChange({ sortBy: sorting.attribute, sortOrder: sorting.order }),
        [handleParamsChange],
    );
    const handleDivisionChange = React.useCallback(
        (division?: Division) => handleParamsChange({ division: division?.alias }),
        [handleParamsChange],
    );
    const handleCoverageChange = React.useCallback(
        (coverage?: Coverage) => handleParamsChange({ coverage }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status?: GameStatus) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    return (
        <View dense route={route}>
            <ViewHeader
                iconElement={
                    <IconDecorated paletteColor="green500" sizeRem={1.5} name="WysiwygRounded" />
                }
                iconFormat="inline"
                titleText="Game Boxscores"
            />
            <Navigation gamesLabel={leagueGamesLabel} route={route} />
            <FlagStats flagged={gamesStats.flagsCreated} ignored={gamesStats.flagsIgnored} />
            <ControlPanel>
                <ControlPanel.Group>
                    {leagueDetails.filters.gameDate && (
                        <DateSelector value={dateValues} onChange={handleDateChange} />
                    )}
                    {leagueDetails.filters.gameWeek && (
                        <Dropdown label="Week" options={weekOptions} onChange={handleWeekChange} />
                    )}
                    <Dropdown
                        label="Sort"
                        options={sortingOptions}
                        onChange={handleSortingChange}
                    />
                    {leagueDetails.filters.leagueHierarchy && (
                        <Dropdown
                            label="Division"
                            options={divisionOptions}
                            onChange={handleDivisionChange}
                        />
                    )}
                    <Dropdown
                        label="Coverage"
                        options={coverageOptions}
                        onChange={handleCoverageChange}
                    />
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        onChange={handleStatusChange}
                    />
                </ControlPanel.Group>
            </ControlPanel>
            {isLoading && <Loading />}
            <GamesContainer data-testid="games-container">
                {sortedGames.map((game) => (
                    <GameCard
                        key={`game-${game.id}`}
                        id={game.id}
                        league={game.league}
                        status={game.status}
                        scheduledDate={game.scheduledDate}
                        subtitle={game.subtitle}
                        coverage={game.coverage?.trim().replaceAll('_', ' ')}
                        awayTeamGlobalId={game.awayTeamGlobalId}
                        awayTeamName={game.awayTeamName}
                        awayTeamAlias={game.awayTeamAlias}
                        homeTeamGlobalId={game.homeTeamGlobalId}
                        homeTeamName={game.homeTeamName}
                        homeTeamAlias={game.homeTeamAlias}
                        ballPossessedBy={game.ballPossessedBy}
                        awayTeamScores={game.awayTeamScores}
                        homeTeamScores={game.homeTeamScores}
                        homeTeamTotalScore={game.homeTeamTotalScore}
                        awayTeamTotalScore={game.awayTeamTotalScore}
                        sequenceInfo={game?.sequenceInfo}
                        flagsCreatedCount={game?.flagsCreated ?? 0}
                        flagsIgnoredCount={game?.flagsIgnored ?? 0}
                        isFavorite={isFavorite(game)}
                        onToggleFavoriteGame={() => toggleFavoriteGame(game)}
                        onMonitorStatsClick={handleMonitorStatsClick}
                        onAdminClick={handleAdminClick}
                        onLDEClick={handleLdeClick}
                    />
                ))}
            </GamesContainer>
        </View>
    );
};

export default Games;
