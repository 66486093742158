import { NullTag } from './styles';

type Props = {
    value?: string | number;
};

const PropertyValue = ({ value }: Props) => {
    if (value === null || value === undefined || value === '') {
        return <NullTag>null</NullTag>;
    }

    return <span>{value}</span>;
};

export default PropertyValue;
