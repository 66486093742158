import { Discrepancy } from '../../types';
import DiscrepancyItem from '../discrepancy-item';

import { DiscrepancyListContainer } from './styles';

type Props = {
    title?: string | null;
    discrepancies: Discrepancy[];
    displayGroupName: boolean;
};

const DiscrepancyList = ({ title, discrepancies, displayGroupName }: Props) => {
    return (
        <DiscrepancyListContainer>
            {title && <p className="title">{`${title} (${discrepancies.length})`}</p>}
            <div data-testid="discrepancy-list">
                {discrepancies.map((discrepancy) => (
                    <DiscrepancyItem
                        key={discrepancy.id}
                        discrepancy={discrepancy}
                        displayGroupName={displayGroupName}
                    />
                ))}
            </div>
        </DiscrepancyListContainer>
    );
};

export default DiscrepancyList;
