import { Admin } from 'api/types/bff';
import { GameStatus } from 'api/types/leagues';
import { FlagType, LeagueType, SeasonType, SeasonYear } from 'types';
import { Coverage } from 'types/coverage';

export type GetDivisionsParameters = {
    league: LeagueType;
};

export type GetGamesParameters = {
    coverage?: Coverage;
    divisionAlias?: string;
    league: LeagueType;
    seasonType: SeasonType;
    seasonYear: SeasonYear;
    status?: GameStatus;
    week?: string;
    dateFrom?: string;
    dateTo?: string;
};

export type GetExternalLinksParameters = {
    league: LeagueType;
    seasonType: SeasonType;
    seasonYear: SeasonYear;
    flagType: FlagType;
    id: string;
    week?: string;
};

export const LeagueKeys = {
    divisions: ({ league }: GetDivisionsParameters) => [league, 'divisions'],
    getGames: ({ league, ...parameters }: GetGamesParameters) => {
        return [league, 'games', parameters];
    },
    flagStats: (league: string, gameId: string) => [league, gameId, 'stats'],
    externalLinks: ({ league, ...parameters }: GetExternalLinksParameters) => {
        return [league, 'external-links', parameters];
    },
};

export type AdminScheduleParams = {
    league: string;
    seasonYear: string;
    seasonType: string;
    id: string;
    scheduleType: Admin.ScheduleTypes;
};

export const AdminScheduleKeys = {
    schedule: ({ league, id, scheduleType }: AdminScheduleParams) => [
        'admin',
        league,
        scheduleType,
        id,
    ],
};
