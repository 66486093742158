import { useMemo } from 'react';

import { FlagsSortingAttribute, Games } from 'api/types/bff';
import { GetGamesParameters as GetGamesQueryParameters } from 'hooks/queries';
import useGamesQuery from 'hooks/useGames';
import { Order } from 'types/sorting';

export type GetGamesParameters = GetGamesQueryParameters & {
    sortBy: FlagsSortingAttribute;
    sortOrder: Order;
};

function compareScheduledDate(a: Games.Game, b: Games.Game) {
    return Date.parse(a.scheduledDate) - Date.parse(b.scheduledDate);
}

function compareDiscrepancyCount(a: Games.Game, b: Games.Game) {
    return (a.flagsCreated ?? 0) - (b.flagsCreated ?? 0);
}

function compareEventDate(a: Games.Game, b: Games.Game) {
    return new Date(a.scheduledDate).getTime() - new Date(b.scheduledDate).getTime();
}

export function useGames({ sortBy, sortOrder, ...parameters }: GetGamesParameters) {
    const { data, isLoading } = useGamesQuery(parameters);

    return useMemo(() => {
        let games = data ?? [];

        const multiplier = sortOrder === Order.Asc ? 1 : -1;

        if (sortBy === FlagsSortingAttribute.DiscrepancyDate) {
            games.sort((a, b) => compareScheduledDate(a, b) * multiplier);
        }

        if (sortBy === FlagsSortingAttribute.DiscrepancyCount) {
            games.sort((a, b) => compareDiscrepancyCount(a, b) * multiplier);
        }

        if (sortBy === FlagsSortingAttribute.EventDate) {
            games.sort((a, b) => compareEventDate(a, b) * multiplier);
        }

        return { data: games, isLoading };
    }, [data, isLoading, sortBy, sortOrder]);
}
