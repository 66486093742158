import styled from '@emotion/styled';

export const HomeContainer = styled.div`
    margin: 0 auto;
    padding: 2.5rem 1.25rem;
`;

export const ListContainer = styled.div`
    width: 39.1875rem;
    max-width: 100%;

    & > .list-title {
        margin-bottom: 1rem;
        font-size: 1.125rem;
        font-weight: 700;
    }

    & > .list {
        display: flex;
        flex-direction: column;
    }
`;
