import { Route } from 'type-route';

import { groups } from 'app/router';

import BoxscoreAll from './views/all';
import BoxscoreGame from './views/game';
import BoxscoreGames from './views/games';

type Props = {
    route: Route<typeof groups.leagueGameBoxscores>;
};

const Boxscore = ({ route }: Props) => {
    return (
        <>
            {route.name === 'gameBoxscores' && <BoxscoreAll route={route} />}
            {route.name === 'gameBoxscoresGame' && <BoxscoreGame route={route} />}
            {route.name === 'gameBoxscoresGames' && <BoxscoreGames route={route} />}
        </>
    );
};

export default Boxscore;
