import * as React from 'react';
import { Route } from 'type-route';

import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import SourceSelector from 'components/source-selector';
import Stat from 'components/stat';
import Stats from 'components/stats';
import View from 'components/view';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import { ExternalSourceId, SourceId } from 'types';
import { Order, Sorting } from 'types/sorting';

import MappingList, { MappingUpdates } from '../components/mappings';
import Navigation from '../components/navigation';
import TeamHeader from '../components/team-header';
import useSourceStatsAggregated from '../hooks/useAggregatedMappingStats';
import { useGoBackToTeams } from '../hooks/useGobackToTeams';
import {
    MappingSortingAttribute,
    useMappingSortingOptions,
} from '../hooks/useMappingSortingOptions';
import { MappingStatus, useMappingStatusOptions } from '../hooks/useMappingStatusOptions';
import { MappingType, useMappingTypeOptions } from '../hooks/useMappingTypeOptions';
import { useMappingsMutation } from '../hooks/useMappingsMutate';
import useMappingsSourceSelector from '../hooks/useMappingsSourceSelector';
import useNavigationStats from '../hooks/useNavigationStats';
import { useTeam } from '../hooks/useTeam';
import {
    Player,
    Schedule,
    TeamMappings,
    useTeamMappingsAutoMapped,
    useTeamMappingsManuallyMapped,
    useTeamMappingsUnmapped,
} from '../hooks/useTeamMappings';

const filter = (teamMappings: TeamMappings, type: MappingType): TeamMappings => {
    if (type === MappingType.Game) {
        return { ...teamMappings, players: [] };
    }

    if (type === MappingType.Roster) {
        return { ...teamMappings, schedules: [] };
    }

    return teamMappings;
};

const sort = (
    teamMappings: TeamMappings,
    sorting: Sorting<MappingSortingAttribute>,
): TeamMappings => {
    const result: TeamMappings = { ...teamMappings };

    if (sorting.attribute === MappingSortingAttribute.Name) {
        result.players.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
        result.schedules.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

        if (sorting.order === Order.Desc) {
            result.players.reverse();
            result.schedules.reverse();
        }

        return result;
    }

    return teamMappings;
};

const getSelectedMappings = (
    status: MappingStatus,
    automatic: TeamMappings,
    manual: TeamMappings,
    unmapped: TeamMappings,
): TeamMappings => {
    switch (status) {
        case MappingStatus.Automatic:
            return automatic;
        case MappingStatus.Manual:
            return manual;
        case MappingStatus.Unmapped:
            return unmapped;
        case MappingStatus.All:
            return {
                players: [...unmapped.players, ...automatic.players, ...manual.players],
                schedules: [...unmapped.schedules, ...automatic.schedules, ...manual.schedules],
            };
    }
};

export type MappingState = {
    players: Record<string, Player>;
    schedules: Record<string, Schedule>;
};

type Props = {
    route: Route<typeof routes.teamsTeamMapping>;
};

const Mapping = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const source = route.params.source ?? leagueDetails.sources[0];

    const navigationStats = useNavigationStats(route.params.league, route.params.id);
    const { data: team, isLoading: isTeamLoading } = useTeam(route.params.id);

    const valuesBySource = useMappingsSourceSelector(
        leagueDetails.sources,
        route.params.league,
        route.params.id,
    );

    const { data: aggregatedStats } = useSourceStatsAggregated(
        route.params.league,
        source,
        route.params.id,
    );

    const sorting = { attribute: route.params.sortBy, order: route.params.sortOrder };
    const sortingOptions = useMappingSortingOptions(sorting);
    const typeOptions = useMappingTypeOptions(route.params.type);
    const statusOptions = useMappingStatusOptions(route.params.status);

    const mappingsMutation = useMappingsMutation(route.params.league, source, route.params.id);
    const automatic = useTeamMappingsAutoMapped(route.params.league, source, route.params.id);
    const manual = useTeamMappingsManuallyMapped(route.params.league, source, route.params.id);
    const unmapped = useTeamMappingsUnmapped(route.params.league, source, route.params.id);

    const updateDate = useDateSnapshot();

    const handleGoBackToTeams = useGoBackToTeams(route);
    const handleParamsChange = useChangeParams(route);

    const handleSourceChange = React.useCallback(
        (sourceId: SourceId) => handleParamsChange({ source: sourceId as ExternalSourceId }),
        [handleParamsChange],
    );
    const handleSortingChange = React.useCallback(
        (sorting: Sorting<MappingSortingAttribute>) =>
            handleParamsChange({ sortBy: sorting.attribute, sortOrder: sorting.order }),
        [handleParamsChange],
    );
    const handleTypeChange = React.useCallback(
        (type: MappingType) => handleParamsChange({ type }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status: MappingStatus) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    const isRefetching = React.useCallback(() => {
        return automatic.isRefetching || manual.isRefetching || unmapped.isRefetching;
    }, [automatic.isRefetching, manual.isRefetching, unmapped.isRefetching]);

    if (isTeamLoading) {
        return <>Loading..</>;
    }

    if (!team) {
        return <>Not Found</>;
    }

    const onSubmit = (updates: MappingUpdates): void => {
        const payload = {
            league: route.params.league,
            source: source,
            ...updates,
        };

        mappingsMutation.mutate(payload);
    };

    return (
        <View route={route}>
            <TeamHeader
                team={team}
                updateDate={updateDate}
                onBackButtonClick={handleGoBackToTeams}
            />
            <Navigation navigationStats={navigationStats} route={route} />
            <SourceSelector
                activeSource={source}
                onSourceClick={handleSourceChange}
                valuesBySource={valuesBySource}
            />
            <Stats data-testid="source-mappings-stats">
                <Stat
                    label="Auto-mapped"
                    value={aggregatedStats.autoMapped}
                    iconKey="IgnoredFlag"
                    opacity={0.1}
                    color="black300"
                    paletteColor="black300"
                />
                <Stat
                    label="Manually Mapped"
                    value={aggregatedStats.manuallyMapped}
                    iconKey="IgnoredFlag"
                    opacity={0.1}
                    color="black300"
                    paletteColor="black300"
                />
                <Stat label="Unmapped" value={aggregatedStats.unmapped} />
            </Stats>
            <ControlPanel>
                <ControlPanel.Group>
                    <Dropdown
                        label="Sort"
                        options={sortingOptions}
                        onChange={handleSortingChange}
                    />
                    <Dropdown label="Type" options={typeOptions} onChange={handleTypeChange} />
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        onChange={handleStatusChange}
                    />
                </ControlPanel.Group>
            </ControlPanel>
            {automatic.data && manual.data && unmapped.data && !isRefetching() && (
                <MappingList
                    key={route.params.source}
                    team={team}
                    onSubmit={onSubmit}
                    allMappings={getSelectedMappings(
                        MappingStatus.All,
                        automatic.data,
                        manual.data,
                        unmapped.data,
                    )}
                    selectedMappings={sort(
                        filter(
                            getSelectedMappings(
                                route.params.status,
                                automatic.data,
                                manual.data,
                                unmapped.data,
                            ),
                            route.params.type,
                        ),
                        sorting,
                    )}
                />
            )}
        </View>
    );
};

export default Mapping;
