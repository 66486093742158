import styled from '@emotion/styled';

export const FlagAccordionContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    & > .flags-list {
        width: 100%;
        flex-direction: column;
    }
`;

export const FlagContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
`;
export const VisibleContent = styled.div<{ visible: boolean }>`
    display: flex;
    user-select: none;
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: ${(props) => (props.visible ? '0.125' : '0.0625')}rem solid
        var(--color-flags-accordion-borderline);
    align-items: center;
`;

export const FlagContent = styled.div`
    display: grid;
    width: 100%;
    padding-left: 1rem;
    padding-right: 3rem;
    font-size: 0.75rem;
    grid-template-columns: 2fr 12fr 1fr;
    & > .flag-type {
        font-weight: bold;
        padding-right: 0.5rem;
    }

    & > .discrepancies {
        margin: auto;
        padding: 0 0.4rem;
        text-align: center;
        background-color: rgba(var(--red500-rgb), 0.1);
    }
`;

export const FlagContentInternal = styled.div`
    width: 100%;
    padding-left: 1rem;
    padding-right: 3rem;
    font-size: 0.75rem;

    display: flex;
    justify-content: space-between;

    & > .team-name {
        font-weight: 700;
        font-size: 0.875rem;
    }

    & > .stats {
        display: flex;
        font-size: 0.875rem;
        gap: 1.625rem;

        .flagged {
            color: var(--red500);
        }

        .ignored {
            color: var(--black300);
        }

        & > div {
            display: flex;
            gap: 0.375rem;
        }
    }
`;

export const Source = styled.div<{ active?: boolean }>`
    display: flex;
    gap: 0.5rem;
    height: min-content;

    font-size: 0.625rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 1.125rem;

    background-color: var(
        ${(props) =>
            props.active
                ? '--color-flags-accordion-source-active-bg-color'
                : '--color-flags-accordion-source-bg'}
    );
    border: 0.0625rem solid
        var(
            ${(props) =>
                props.active
                    ? '--color-flags-accordion-source-active-border-color'
                    : '--color-flags-accordion-source-border-color'}
        );
    padding: 0.4rem;

    & > .counter {
        color: var(--color-flags-accordion-source-amount);
    }
`;

export const DiscrepancyContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: rgba(var(--red500-rgb), 0.1);
    width: 100%;
    padding: 0.2rem 0.4rem;
    border-radius: 0.1875rem;
    justify-content: space-between;

    & > .value {
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        max-width: 27rem;
        text-overflow: ellipsis;
    }

    & > .controls {
        display: flex;
        gap: 0.5rem;
    }
`;

export const Control = styled.div`
    cursor: pointer;
    border-radius: 0.1875rem;
    background-color: var(--gray100);
    padding: 0.2rem;
`;
