import Group from './components/group';
import { ControlPanelContainer } from './styles';

const ControlPanel = ({ children }: React.PropsWithChildren<{}>) => {
    return <ControlPanelContainer data-testid="control-panel">{children}</ControlPanelContainer>;
};

ControlPanel.Group = Group;

export default ControlPanel;
