import useTeamCrest from 'app/hooks/useTeamCrest';
import { Icon } from 'components/icons';
import SportradarImage from 'components/sr-logo';

import { LogoContainer } from './styles';

type Props = {
    srGlobalId?: string | null;
};

const TeamImage = ({ srGlobalId }: Props) => {
    const teamCrest = useTeamCrest(srGlobalId);

    return (
        <LogoContainer data-testid="team-image">
            <SportradarImage
                className="image-container"
                src={teamCrest}
                placeholder={<Icon name="GroupWorkRounded" />}
            />
        </LogoContainer>
    );
};

export default TeamImage;
