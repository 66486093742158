import leaguesDetails from 'constants/leagues-details';

function useLeagueDetails(league: string) {
    const leagueDetails = leaguesDetails.find((details) => details.league === league);

    if (!leagueDetails) {
        throw new Error('League is not supported');
    }

    return leagueDetails;
}

export default useLeagueDetails;
