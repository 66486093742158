import * as React from 'react';

import { Option, OptionControls } from 'types/controls';

function isEqual<T>(a: T, b: T): boolean {
    return a === b;
}

function getSelectedOption<T>(options: Option<T>[]) {
    return options.find((option) => option.selected);
}

export default function useOptions<T>(
    defaultOptions: Option<T>[],
    compareFn: (a: T, b: T) => boolean = isEqual,
): [T, OptionControls<T>] {
    if (!defaultOptions.length) {
        throw new Error('useOptions: options cannot be an empty array.');
    }

    const [value, setValue] = React.useState<T>(
        getSelectedOption(defaultOptions)?.value ?? defaultOptions[0].value,
    );

    return React.useMemo(() => {
        const options = defaultOptions.map((option) => ({
            ...option,
            selected: compareFn(option.value, value),
        }));

        const selectedOption = getSelectedOption(options);

        if (!selectedOption) {
            setValue(options[0].value);
            options[0].selected = true;
        }

        return [selectedOption?.value ?? options[0]?.value, { onChange: setValue, options }];
    }, [compareFn, defaultOptions, value]);
}
