import { useQuery } from 'react-query';

import API from '../../api';
import client from '../../api/client';
import { DiscrepancyType } from '../../api/types/bff';

import { FlagKeys, GetFlagDiscrepanciesParameters } from './queries';

const getFlagDiscrepancies = async ({
    league,
    flagId,
    status,
    seasonYear,
    seasonType,
}: GetFlagDiscrepanciesParameters): Promise<DiscrepancyType[]> => {
    const searchParams = new URLSearchParams({
        ...(status ? { status } : {}),
        seasonYear,
        seasonType,
    });

    const response = await client.get(
        `${API.BFF_API}/${league}/flags/${flagId}/discrepancies?${searchParams}`,
    );

    return response.data;
};

export default function useFlagDiscrepancies(
    parameters: GetFlagDiscrepanciesParameters,
    enabled = false,
) {
    return useQuery(
        FlagKeys.getFlagDiscrepancies(parameters),
        () => getFlagDiscrepancies(parameters),
        {
            refetchOnWindowFocus: false,
            enabled,
        },
    );
}
