import * as React from 'react';

import { ValuesBySource } from 'components/source-selector/types';
import { ExternalSourceId } from 'types';

export function useLiveValuesBySource(sources: ExternalSourceId[]): ValuesBySource {
    return React.useMemo(() => {
        return sources.map((source) => ({
            source: source,
            value: null,
        }));
    }, [sources]);
}
