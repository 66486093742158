import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { Division } from 'api/types/leagues';

import { GetDivisionsParameters, LeagueKeys } from './queries';

const getDivisions = async ({ league }: GetDivisionsParameters): Promise<Division[]> => {
    const response = await client.get(`${API.LEAGUES_API}/${league}/division`);

    return response?.data;
};

export type Options = {
    enabled?: boolean;
};

const defaultOptions: Partial<Options> = {
    enabled: true,
};

export default function useDivisions(
    parameters: GetDivisionsParameters,
    options: Options = defaultOptions,
) {
    const resolvedOptions = { ...defaultOptions, ...options };

    return useQuery(LeagueKeys.divisions(parameters), () => getDivisions(parameters), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: 3 * 60 * 60 * 1000, // 3h
        cacheTime: 10 * 60 * 60 * 1000, // 10h
        enabled: resolvedOptions.enabled,
    });
}
