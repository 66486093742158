import * as React from 'react';

import { routes } from 'app/router';
import { useCurrentUser } from 'auth/use-current-user';
import useSportsMediaAuth from 'auth/use-sports-media-auth';
import { IconDecorated } from 'components/icons';
import Loading from 'components/loading';
import Popover from 'components/popover';

import { AccountDropdownContainer, AccountPlaceHolder } from './styles';

const AccountDropdown = () => {
    const { currentUser } = useCurrentUser();
    const { logout } = useSportsMediaAuth();
    const handleLogoutClick = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            await logout();
            window.location.reload();
        },
        [logout],
    );

    const placeholder = (
        <AccountPlaceHolder data-testid="account-dropdown">
            {currentUser?.email ? (
                <>
                    <span>{currentUser?.email}</span>
                    <IconDecorated sizeRem={1} name="ArrowDropDown" />
                </>
            ) : (
                <div className="loading-container">
                    <Loading mt={1} />
                </div>
            )}
        </AccountPlaceHolder>
    );

    return (
        <Popover
            placeholder={placeholder}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            {() => {
                return (
                    <AccountDropdownContainer data-testid="account-dropdown-content">
                        <ul>
                            <li>
                                <a {...routes.adminTasksListing().link}>Tasks</a>
                            </li>
                            <li>
                                <a href="/logout" onClick={handleLogoutClick}>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </AccountDropdownContainer>
                );
            }}
        </Popover>
    );
};

export default AccountDropdown;
