import * as React from 'react';

import { Option } from 'types/controls';

export function useLimitOptions(options: number[], current?: number): Option<number>[] {
    return React.useMemo(() => {
        const currentValue = current ?? options[0];
        const opts = options.map((option) => ({
            label: `${option}`,
            value: option,
            selected: currentValue === option,
        }));

        return opts;
    }, [current, options]);
}
