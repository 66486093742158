import dayjs from 'dayjs';
import * as React from 'react';

import { Game } from 'api/types/bff';
import { LeagueType } from 'types';

import BasicGameOverviewHeader from './basic';
import { useGameProperties } from './hooks/useGameProperties';

type Props = {
    league: LeagueType;
    game: Game.LiveGame;
};

const GameOverviewHeader = ({ league, game }: Props) => {
    const properties = useGameProperties(league, game);

    const commonProps = React.useMemo(
        (): Partial<React.ComponentProps<typeof BasicGameOverviewHeader>> => ({
            status: game.status,
            scheduledDate: dayjs(game.scheduledDate).format('dddd MMMM D, YYYY'),
            sequenceInfo: game?.sequenceInfo ?? 'N/A',
            properties: properties?.game,
            awayTeamAlias: game?.awayTeam?.alias,
            awayTeamName: `${game?.awayTeam?.market} ${game?.awayTeam?.name}`,
            awayTeamScores: game?.awayTeamScores,
            awayTeamSrGlobalId: game?.awayTeam?.srGlobalId,
            awayTeamTotalScore: game?.awayTeamTotalScore,
            awayTeamProperties: properties?.away,
            homeTeamAlias: game?.homeTeam?.alias,
            homeTeamName: `${game?.homeTeam?.market} ${game?.homeTeam?.name}`,
            homeTeamScores: game?.homeTeamScores,
            homeTeamSrGlobalId: game?.homeTeam?.srGlobalId,
            homeTeamTotalScore: game?.homeTeamTotalScore,
            homeTeamProperties: properties?.home,
        }),
        [game, properties],
    );

    switch (league) {
        case 'cfb':
        case 'ncaafb':
        case 'nfl':
            return <BasicGameOverviewHeader {...commonProps} minColumnCount={4} />;
        case 'ncaamb':
            return <BasicGameOverviewHeader {...commonProps} minColumnCount={2} splitProperties />;
        case 'ncaawb':
            return <BasicGameOverviewHeader {...commonProps} minColumnCount={4} splitProperties />;

        default:
            throw new Error('Given league is not yet supported');
    }
};

export default GameOverviewHeader;
