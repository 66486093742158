import * as React from 'react';

import { useGroupBy } from 'hooks/useGroupBy';

import { Discrepancy } from '../../../types';
import DiscrepancyListGroup from '../discrepancy-list-group';

import { Props } from './type';

enum FootballGroup {
    Defense = 'Defense',
    Efficiency = 'Efficiency',
    FirstDowns = 'First Downs',
    ExtraPoints = 'Extra Points',
    FieldGoals = 'Field Goals',
    Fumbles = 'Fumbles',
    Interceptions = 'Interceptions',
    InterceptionReturns = 'Interception Returns',
    KickReturns = 'Kick Returns',
    Other = 'Other',
    Passing = 'Passing',
    Penalties = 'Penalties',
    PuntReturns = 'Punt Returns',
    Punts = 'Punts',
    Receiving = 'Receiving',
    Rushing = 'Rushing',
}

type FootballGroupTuple = [FootballGroup, Discrepancy[]];

const ORDERED_GROUPS = [
    FootballGroup.Passing,
    FootballGroup.Rushing,
    FootballGroup.Receiving,
    FootballGroup.Fumbles,
    FootballGroup.Defense,
    FootballGroup.Interceptions,
    FootballGroup.InterceptionReturns,
    FootballGroup.KickReturns,
    FootballGroup.PuntReturns,
    FootballGroup.FieldGoals,
    FootballGroup.Punts,
    FootballGroup.Efficiency,
    FootballGroup.ExtraPoints,
    FootballGroup.Penalties,
    FootballGroup.FirstDowns,
];

const FootballDiscrepancyListGroup = ({ discrepancies = [], ...props }: Props) => {
    const { groups, other } = useGroupBy(discrepancies, (discrepancy) =>
        ORDERED_GROUPS.includes(discrepancy.flagGroupName as FootballGroup)
            ? discrepancy.flagGroupName
            : null,
    );

    const discrepancyGroups = React.useMemo<FootballGroupTuple[]>(() => {
        const allGroups = [
            ...ORDERED_GROUPS.map((group) => [group, groups[group]]),
            [FootballGroup.Other, other],
        ];

        return allGroups.filter((group): group is FootballGroupTuple => !!group[1]?.length);
    }, [groups, other]);

    return (
        <DiscrepancyListGroup
            {...props}
            discrepancyGroups={discrepancyGroups}
            displayGroupName={false}
        />
    );
};

export default FootballDiscrepancyListGroup;
