import { SeasonType } from 'types';

export enum CompetitorQualifier {
    Away = 'away',
    Home = 'home',
}

export enum GameStatus {
    Cancelled = 'cancelled',
    Closed = 'closed',
    Complete = 'complete',
    Created = 'created',
    Delayed = 'delayed',
    FlexSchedule = 'flex-schedule',
    Halftime = 'halftime',
    InProgress = 'inprogress',
    Postponed = 'postponed',
    Scheduled = 'scheduled',
    Suspended = 'suspended',
    TimeTbd = 'time-tbd',
}

export const GAME_STATUS_MAP: Record<GameStatus, string> = {
    [GameStatus.Cancelled]: 'Cancelled',
    [GameStatus.Closed]: 'Closed',
    [GameStatus.Complete]: 'Complete',
    [GameStatus.Created]: 'Created',
    [GameStatus.Delayed]: 'Delayed',
    [GameStatus.FlexSchedule]: 'Flex Schedule',
    [GameStatus.Halftime]: 'Halftime',
    [GameStatus.InProgress]: 'In Progress',
    [GameStatus.Postponed]: 'Postponed',
    [GameStatus.Suspended]: 'Suspended',
    [GameStatus.Scheduled]: 'Scheduled',
    [GameStatus.TimeTbd]: 'Time TBD',
};

export function isGameStatus(gameStatus: string): gameStatus is GameStatus {
    return Object.values(GameStatus).includes(gameStatus as GameStatus);
}

export type SeasonBasic = {
    srId: string;
    year: number;
    type: SeasonType;
};

export type ConferenceBasic = {
    srId: string;
    name?: string;
    alias?: string;
};

export type Conference = ConferenceBasic & {
    divisions?: DivisionBasic[];
};

export type DivisionBasic = {
    srId: string;
    name?: string;
    alias?: string;
};

export type Division = DivisionBasic & {
    conferences?: ConferenceBasic[];
};

export type TeamBasic = {
    srId: string;
    srGlobalId?: string;
    name?: string;
    market?: string;
    displayName?: string;
    alias?: string;
    conference?: ConferenceBasic;
    division?: DivisionBasic;
};

export type Team = TeamBasic & {
    founded?: number;
    venue?: Venue;
    season: SeasonBasic;
};

export type Venue = {
    srId: string;
    srGlobalId?: string;
    name: string;
    capacity?: number;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    location?: {
        lat?: string;
        lng?: string;
    };
};

export type LeagueInfoResponse = {
    currentDate?: string | null;
    currentWeek?: number | null;
    weeks?: number[];
};
