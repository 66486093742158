import { LeagueType } from 'types';

import { Discrepancy } from '../../types';

import DefaultDiscrepancyListGroup from './sports/default';
import FootballDiscrepancyListGroup from './sports/football';

type Props = {
    league: LeagueType;
    teamName: string;
    teamGlobalSrId?: string | null;
    context: 'team' | 'player';
    discrepancies?: Discrepancy[];
};

const DiscrepancyListGroupFactory = ({ league, ...props }: Props) => {
    switch (league) {
        case 'cfb':
        case 'ncaafb':
        case 'nfl':
            return <FootballDiscrepancyListGroup {...props} />;
        default:
            return <DefaultDiscrepancyListGroup {...props} />;
    }
};

export default DiscrepancyListGroupFactory;
