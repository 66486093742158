import styled from '@emotion/styled';

export const Button = styled.span`
    cursor: pointer;
    color: var(--color-go-back-button);
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 0.5625rem;
    font-weight: 700;
    line-height: 0.6875rem;
`;
