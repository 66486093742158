import Axios, { AxiosInstance } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { AuthClient } from '../auth/client';
import authConfig from '../auth/config';

const client = Axios.create();

const authClient = new AuthClient({
    clientId: authConfig.clientId,
    cognitoUrl: authConfig.cognitoUrl,
});

export const addRequestInterceptor = (client: AxiosInstance) => {
    client.interceptors.request.use(
        async (req) => {
            if (authClient.hasTokenExpired()) {
                try {
                    await authClient.refreshTokens();
                } catch (e) {
                    console.error(e);
                    authClient.removeSession();
                }
            }

            if (req.headers) {
                req.headers['Authorization'] = authClient.getIdToken();
            }

            return req;
        },
        (error) => Promise.reject(error),
    );
};

export const addResponseInterceptor = (client: AxiosInstance) => {
    client.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response?.status === StatusCodes.UNAUTHORIZED) {
                await authClient.refreshTokens();
            }

            return Promise.reject(error);
        },
    );
};

addRequestInterceptor(client);
addResponseInterceptor(client);

export default client;
