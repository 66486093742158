import * as React from 'react';

import { IconDecorated } from 'components/icons';

import { Discrepancy } from '../../types';
import DiscrepancyActionButton from '../discrepancy-action-button';
import DiscrepancyClock from '../discrepancy-clock';
import IgnoreDialog from '../ignore-dialog';

import { DiscrepancyItemContainer } from './styles';

type Props = {
    discrepancy: Discrepancy;
    displayGroupName: boolean;
};

const DiscrepancyItem = ({ discrepancy, displayGroupName }: Props) => {
    const [showIgnoreDialog, setShowIgnoreDialog] = React.useState(false);

    const isPlayerDiscrepancy = discrepancy.context === 'player';
    const iconColor = isPlayerDiscrepancy ? 'purple' : 'blue300';
    const iconName = isPlayerDiscrepancy ? 'PersonRounded' : 'GroupWorkRounded';
    const contextLabel = isPlayerDiscrepancy ? discrepancy.playerName : discrepancy.teamAlias;
    const flagLabel = displayGroupName ? discrepancy.flagName : discrepancy.flagTypeName;

    const handleIgnoreDiscrepancyClick = React.useCallback(() => {
        setShowIgnoreDialog(true);
    }, []);

    const handleCloseIgnoreDialog = React.useCallback(() => {
        setShowIgnoreDialog(false);
    }, []);

    return (
        <DiscrepancyItemContainer status={discrepancy.status}>
            <DiscrepancyClock createdAt={discrepancy.createdAt} />
            <IconDecorated paletteColor={iconColor} sizeRem={1} name={iconName} />
            <div className="content-container" data-testid="discrepancy-item-content-container">
                <div className="attribute-list">
                    <span className="attribute-item">{contextLabel}</span>
                    <span
                        className="attribute-item discrepancy-issue"
                        data-testid="discrepancy-item-discrepancy-issue"
                    >{`${flagLabel}: ${discrepancy.expectedValue} / ${discrepancy.actualValue}`}</span>
                </div>
                <DiscrepancyActionButton
                    status={discrepancy.status}
                    onClick={handleIgnoreDiscrepancyClick}
                />
            </div>
            <IgnoreDialog
                open={showIgnoreDialog}
                onClose={handleCloseIgnoreDialog}
                discrepancy={discrepancy}
            />
        </DiscrepancyItemContainer>
    );
};

export default DiscrepancyItem;
