import * as React from 'react';

import useLeagueTeams from './useLeagueTeams';

export function useTeam(teamId: string) {
    const { data: teams = [], ...leagueTeamsQuery } = useLeagueTeams();

    return React.useMemo(() => {
        return {
            ...leagueTeamsQuery,
            data: teams.find((t) => t.srId === teamId),
        };
    }, [teams, teamId, leagueTeamsQuery]);
}
