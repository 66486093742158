import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { ExternalLink } from 'api/types/bff';

import { GetExternalLinksParameters, LeagueKeys } from './queries';

const getExternalLinks = async ({
    league,
    flagType,
    seasonYear,
    seasonType,
    id,
    week,
}: GetExternalLinksParameters): Promise<ExternalLink[]> => {
    const searchParams = new URLSearchParams({
        ...(week && { week }),
    });

    const response = await client.get(
        `${API.BFF_API}/${league}/external-links/${flagType}/${seasonYear}/${seasonType}/${id}?${searchParams}`,
    );

    return response?.data;
};

export default function useExternalLinks(parameters: GetExternalLinksParameters, enabled = false) {
    return useQuery(LeagueKeys.externalLinks(parameters), () => getExternalLinks(parameters), {
        refetchOnWindowFocus: false,
        enabled,
    });
}
