import * as React from 'react';

import { DiscrepancyStatus } from 'api/types/flags';
import { useColorSchemeConfig } from 'app/theme/useColorScheme';

import BatchIgnoreDialog from '../discrepancy/batch-ignore-dialog';

import useBulkUpdateDiscrepanciesState from './hooks/use-bulk-update-discrepancies-state';
import { ActionsWrapper, BulkActionsContainer, CheckboxChecked, CheckboxUnchecked } from './styles';

type Props = {
    onToggleSelect: () => void;
    isActive: boolean;
    checkedDiscrepancyIds: Array<string>;
};

const BulkActions = ({ onToggleSelect, isActive, checkedDiscrepancyIds }: Props) => {
    const [colorScheme] = useColorSchemeConfig();
    const [showIgnoreDialog, setShowIgnoreDialog] = React.useState(false);
    const discrepancyStateMutation = useBulkUpdateDiscrepanciesState();

    const handleResolve = React.useCallback(() => {
        discrepancyStateMutation.mutate({
            status: DiscrepancyStatus.RESOLVED,
            ids: checkedDiscrepancyIds,
        });
    }, [checkedDiscrepancyIds, discrepancyStateMutation]);
    const handleIgnore = React.useCallback(() => {
        isActive && setShowIgnoreDialog(true);
    }, [isActive]);
    const handleCloseIgnore = React.useCallback(() => {
        setShowIgnoreDialog(false);
    }, []);

    return (
        <>
            <BulkActionsContainer>
                <div className="select-all-wrapper">
                    {isActive ? (
                        <CheckboxChecked isDark={colorScheme === 'dark'} onClick={onToggleSelect} />
                    ) : (
                        <CheckboxUnchecked
                            isDark={colorScheme === 'dark'}
                            onClick={onToggleSelect}
                        />
                    )}
                </div>
                <ActionsWrapper active={isActive}>
                    <span>BULK ACTIONS:</span>
                    <span onClick={handleResolve} className="action-name">
                        RESOLVE
                    </span>
                    <span onClick={handleIgnore} className="action-name">
                        IGNORE
                    </span>
                </ActionsWrapper>
            </BulkActionsContainer>
            <BatchIgnoreDialog
                open={showIgnoreDialog}
                onClose={handleCloseIgnore}
                discrepancyIds={checkedDiscrepancyIds}
            />
        </>
    );
};

export default BulkActions;
