import * as React from 'react';

import { useCurrentSeason } from 'app/useCurrentSeason';
import { SourceId } from 'types';

import { StatValues } from '../types';
import { useGetFlagStats } from '../useGetFlagStats';

export type TeamsFlagStatsBySource = {
    [key in SourceId as string]?: StatValues;
};

export default function useTeamsFlagStatsBySource(): TeamsFlagStatsBySource {
    const currentSeason = useCurrentSeason();
    const { data: flagStats, isLoading } = useGetFlagStats(currentSeason);

    return React.useMemo(() => {
        const teamsFlagStatsBySource: TeamsFlagStatsBySource = Object.values(
            SourceId,
        ).reduce<TeamsFlagStatsBySource>((acc, source) => {
            return {
                ...acc,
                [source]: {
                    created: 0,
                    ignored: 0,
                },
            };
        }, {});

        if (isLoading || !flagStats) {
            return teamsFlagStatsBySource;
        }

        for (const flagType of Object.keys(flagStats)) {
            for (const contextType of Object.keys(flagStats[flagType])) {
                for (const source of Object.keys(flagStats[flagType][contextType])) {
                    if (teamsFlagStatsBySource[source]) {
                        const sourceStats = teamsFlagStatsBySource[source];

                        if (sourceStats) {
                            sourceStats.ignored +=
                                flagStats[flagType][contextType][source]['IGNORED'] ?? 0;
                            sourceStats.created +=
                                flagStats[flagType][contextType][source]['CREATED'] ?? 0;
                        }
                    }
                }
            }
        }

        return teamsFlagStatsBySource;
    }, [flagStats, isLoading]);
}
