import { ReactNode, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { initialAuthState } from './auth-state';
import { AuthClient } from './client';
import {
    SportsMediaRefreshAuthenticationFailed,
    SportsMediaRefreshLoginFailed,
    SportsMediaRefreshLogoutFailed,
} from './errors';
import { reducer } from './reducer';
import SportsMediaAuthContext, {
    SportsMediaAuthContextInterface,
} from './sports-media-auth-context';
import { hasAuthParams } from './utils';

const restoreLocation = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
};

export interface SportsMediaAuthProviderOptions {
    clientId: string;
    cognitoUrl: string;
    children?: ReactNode;
}

const SportsMediaAuthProvider = (opts: SportsMediaAuthProviderOptions): JSX.Element => {
    const { children } = opts;

    const [client] = useState(
        new AuthClient({
            clientId: opts.clientId,
            cognitoUrl: opts.cognitoUrl,
        }),
    );

    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const didInitialise = useRef(false);

    useEffect(() => {
        if (didInitialise.current) {
            return;
        }

        didInitialise.current = true;

        (async (): Promise<void> => {
            try {
                if (client.hasSession()) {
                    dispatch({ type: 'SESSION_RESTORE', user: client.getUser() });
                } else if (hasAuthParams()) {
                    await client.authenticate();
                    restoreLocation();
                    dispatch({ type: 'LOGIN_COMPLETE', user: client.getUser() });
                } else {
                    dispatch({ type: 'NO_SESSION_TO_RESTORE' });
                }
            } catch (error) {
                dispatch({
                    type: 'ERROR',
                    error: new SportsMediaRefreshAuthenticationFailed(error),
                });
            }
        })();
    }, [client]);

    const loginWithRedirect = useCallback(async (): Promise<void> => {
        try {
            await client.loginWithRedirect();
        } catch (error) {
            dispatch({ type: 'ERROR', error: new SportsMediaRefreshLoginFailed(error) });
        }
    }, [client]);

    const logout = useCallback(async (): Promise<void> => {
        dispatch({ type: 'LOGOUT_STARTED' });

        try {
            await client.logout();
        } catch (error) {
            dispatch({ type: 'ERROR', error: new SportsMediaRefreshLogoutFailed(error) });
        }

        dispatch({ type: 'LOGOUT_COMPLETE' });
    }, [client]);

    const refreshTokens = useCallback(async (): Promise<void> => {
        dispatch({ type: 'REFRESH_TOKENS_STARTED' });

        try {
            await client.refreshTokens();
            dispatch({ type: 'REFRESH_TOKENS_COMPLETE' });
        } catch (error) {
            client.removeSession();
            dispatch({ type: 'REFRESH_TOKENS_FAILED' });
        }
    }, [client]);

    const getIdToken = useCallback((): string => client.getIdToken(), [client]);

    const getAccessToken = useCallback((): string => client.getAccessToken(), [client]);

    const contextValue: SportsMediaAuthContextInterface = useMemo(
        () => ({
            ...state,
            loginWithRedirect,
            logout,
            getIdToken,
            getAccessToken,
            refreshTokens,
        }),
        [state, loginWithRedirect, logout, getIdToken, getAccessToken, refreshTokens],
    );

    return (
        <SportsMediaAuthContext.Provider value={contextValue}>
            {children}
        </SportsMediaAuthContext.Provider>
    );
};

export default SportsMediaAuthProvider;
