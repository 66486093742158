import { ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ModalContainer, ModalContainerProps } from './styles';

interface PortalProperties {
    children: ReactNode;
    containerId?: string;
}

const Portal = ({ children, containerId = 'modal' }: PortalProperties) => {
    const [container, setContainer] = useState<HTMLElement>();

    useLayoutEffect(() => {
        const element = bodyAppend(getElement(containerId) ?? createElement(containerId));

        setContainer(element);
        document.body.style.overflow = 'hidden';

        return () => {
            container?.parentNode?.removeChild(container);
            document.body.style.overflow = 'unset';
        };
    }, [containerId, container]);

    return createPortal(
        children,
        bodyAppend(getElement(containerId) ?? createElement(containerId)),
    );
};

const getElement = (id: string) => document.getElementById(id);

const createElement = (id: string) => {
    const element = document.createElement('div');

    element.setAttribute('id', id);

    return element;
};

const bodyAppend = (element: HTMLElement) => document.body.appendChild(element);

export interface ModalProps extends ModalContainerProps {
    open?: boolean;
    onBackDropClick?: () => void;
    className?: string;
    children: ReactNode;
    zIndex?: number;
}

const Modal = ({ open, onBackDropClick, children, ...props }: ModalProps) => {
    if (!open) {
        return null;
    }

    return (
        <Portal containerId="modal">
            <ModalContainer {...props}>
                <div className="backdrop" onClick={onBackDropClick} />
                <div data-testid="modal-content" className="content">
                    {children}
                </div>
            </ModalContainer>
        </Portal>
    );
};

export default Modal;
