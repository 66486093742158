import { mqtt5 } from 'aws-iot-device-sdk-v2';
import { createContext } from 'react';

export interface WsContextInterface {
    client: mqtt5.Mqtt5Client | null;
    subscribe: (topic: string, qos: mqtt5.QoS) => void;
    unsubscribe: (topic: string) => void;
    didInitialise: boolean;
    connected: boolean;
}

const initialContext: WsContextInterface = {
    client: null,
    subscribe: () => {
        throw new Error('Wrap your component in <WsProvider>');
    },
    unsubscribe: () => {
        throw new Error('Wrap your component in <WsProvider>');
    },
    didInitialise: false,
    connected: false,
};

const WsContext = createContext<WsContextInterface>(initialContext);

export default WsContext;
