import styled from '@emotion/styled';

export const AppContainer = styled.div`
    display: flex;
    height: 100%;

    flex-direction: column;

    & > .content {
        display: flex;
        grid-area: content;
        height: 100%;
    }
`;
