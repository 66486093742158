import { css, Global, SerializedStyles } from '@emotion/react';

export type Size = `${number}rem`; // we want to use only rem in the project

export type SizeScheme = {
    '--p-90': Size;
    '--p-100': Size;
    '--p-200': Size;
    '--p-300': Size;
    '--h-100': Size;
    '--h-200': Size;
    '--h-300': Size;
    '--h-400': Size;
    '--s-100': Size;
    '--s-200': Size;
    '--s-300': Size;
    '--s-400': Size;
    '--s-500': Size;
    '--s-600': Size;
    '--s-700': Size;
    '--s-800': Size;
    '--s-900': Size;
    '--b-100': Size;
    '--b-200': Size;
    '--b-300': Size;
    '--b-400': Size;
    '--b-500': Size;
    '--b-600': Size;
    '--b-700': Size;
    '--b-800': Size;
    '--b-900': Size;
};

const standardScheme: SizeScheme = {
    '--p-90': '0.563rem',
    '--p-100': '0.625rem',
    '--p-200': '0.75rem',
    '--p-300': '0.875rem',
    '--h-100': '1.125rem',
    '--h-200': '1.5rem',
    '--h-300': '2rem',
    '--h-400': '2.5rem',
    '--s-100': '0.125rem',
    '--s-200': '0.25rem',
    '--s-300': '0.5rem',
    '--s-400': '1rem',
    '--s-500': '1.5rem',
    '--s-600': '2rem',
    '--s-700': '2.5rem',
    '--s-800': '3rem',
    '--s-900': '4rem',
    '--b-100': '0.125rem',
    '--b-200': '0.25rem',
    '--b-300': '0.5rem',
    '--b-400': '1rem',
    '--b-500': '1.5rem',
    '--b-600': '2rem',
    '--b-700': '2.5rem',
    '--b-800': '3rem',
    '--b-900': '4rem',
};

const makeSizeVariables = (size: Size, variableBaseName: string): SerializedStyles =>
    css(`
    ${variableBaseName}: ${size};
`);

export const makeSizeStyles = (scheme: SizeScheme): SerializedStyles => css`
    :root {
        ${Object.entries(scheme).map(([variableName, colour]) =>
            makeSizeVariables(colour, variableName),
        )}
    }
`;

export const GlobalSizeScheme = () => (
    <Global
        styles={css`
            ${makeSizeStyles(standardScheme)};
        `}
    />
);
