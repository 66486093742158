import styled from '@emotion/styled';

export const GameContainer = styled.div`
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1.5rem 3rem;
    width: 100%;
    max-width: 85.4375rem;

    .go-back-button {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`;
