import { DiscrepancyType } from 'api/types/bff';
import { DiscrepancySourceType } from 'api/types/flags';
import { SourceId } from 'types';

import { DiscrepanciesBySource } from '../types';

export function useSplitDiscrepanciesBySource(
    discrepancies: DiscrepancyType[],
    sources: SourceId[],
): DiscrepanciesBySource[] {
    return sources.map((source) => ({
        discrepancies: discrepancies.filter((discrepancy) =>
            containsSource(discrepancy.discrepancySources, source),
        ),
        source,
    }));
}

const containsSource = (discrepancySources: DiscrepancySourceType[], source: SourceId): boolean =>
    discrepancySources.some((discrepancySource) => discrepancySource.sourceId === source);
