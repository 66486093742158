import styled from '@emotion/styled';

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2.8125rem;

    & > .header {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }

    & .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .repull {
            display: flex;
            align-items: center;
            column-gap: 1.25rem;
        }
    }
`;
