export type CompareFn<T> = (a: T, b: T) => number;

export enum Order {
    Asc = 'asc',
    Desc = 'desc',
}

export function isOrder(order: unknown): order is Order {
    return Object.values(Order).includes(order as Order);
}

export type Sorting<T> = {
    attribute: T;
    order: Order;
};
