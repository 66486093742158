import React from 'react';

import { FlagType, Context } from '../../../../api/types/bff';

export function useGameContext(flag: FlagType): Context.Game | null {
    return React.useMemo(() => {
        if (!Context.isGame(flag.context)) {
            return null;
        }

        return flag.context;
    }, [flag.context]);
}
