import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import * as React from 'react';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default function useDateSnapshot(date?: Parameters<typeof dayjs>[0]) {
    return React.useMemo(() => dayjs(date).format('MM/DD/YY hh:mm A z'), [date]);
}
