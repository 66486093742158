const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
};

/**
 * Produces a media query for given breakpoint
 *
 * @param minWidth
 * @see https://cherry.design/docs/code/react-components/breakpoints
 *
 * @example
 *
 * ${mq('md')} {
 *     flex-direction: row;
 * }
 *
 * it will apply flex direction row only when minimum size of viewport is matching md breakpoint
 *
 */
function mq(minWidth: keyof typeof breakpoints) {
    return `@media screen and (min-width: ${breakpoints[minWidth]}px)`;
}

export { breakpoints, mq };
