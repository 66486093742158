import { DialogButtons } from 'components/dialog/styles';
import Button from 'components/primitives/button';

import { SubmitDialogContainer } from './styles';

type Props = {
    count?: number;
    countIndicatorLabel?: string;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    onCancel?: () => void;
    onSubmit?: () => void;
};

export const SubmitDialog = ({
    count = 0,
    cancelButtonLabel = 'Cancel',
    confirmButtonLabel = `Submit (${count}) Changes`,
    countIndicatorLabel = `${count} Unsaved Changes`,
    onCancel = () => {},
    onSubmit = () => {},
}: Props) => {
    return (
        <SubmitDialogContainer>
            <div className="count-indicator-label">{countIndicatorLabel}</div>
            <DialogButtons>
                <Button onMouseDown={onCancel} noBorder>
                    {cancelButtonLabel}
                </Button>
                <Button className="submit" onMouseDown={onSubmit} type="submit">
                    {confirmButtonLabel}
                </Button>
            </DialogButtons>
        </SubmitDialogContainer>
    );
};
