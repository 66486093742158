import { DialogButtons } from 'components/dialog/styles';
import Modal from 'components/modal';
import Button from 'components/primitives/button';

import { Attribute, Attributes } from '../attributes';
import { Card, Cards, Info, MappingDialogContainer, Title } from '../styles';

interface UnmappingDialogProps {
    open: boolean;
    title: string;
    card: {
        title: string;
        name: string;
        attributes: Attribute[];
    };
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    remapButtonLabel?: string;
    onConfirm: () => void;
    onCancel: () => void;
    onRemap: () => void;
}

export const UnmappingDialog = ({
    open,
    title,
    card,
    cancelButtonLabel = 'Cancel',
    confirmButtonLabel = 'Confirm Unmapping',
    remapButtonLabel = 'Remap',
    onCancel,
    onConfirm,
    onRemap,
}: UnmappingDialogProps) => {
    return (
        <Modal style={{ padding: 0 }} open={open} onBackDropClick={onCancel}>
            <MappingDialogContainer>
                <Title>{title}</Title>
                <Cards>
                    <Card className="left unmap">
                        <Title>{card.title}</Title>
                        <Info>
                            <Title>{card.name}</Title>
                            <Attributes attributes={card.attributes} />
                        </Info>
                    </Card>
                </Cards>
                <DialogButtons>
                    <Button onMouseDown={onCancel} noBorder>
                        {cancelButtonLabel}
                    </Button>
                    <Button onMouseDown={onRemap} noBorder>
                        {remapButtonLabel}
                    </Button>
                    <Button className="submit" onMouseDown={onConfirm} type="submit">
                        {confirmButtonLabel}
                    </Button>
                </DialogButtons>
            </MappingDialogContainer>
        </Modal>
    );
};
