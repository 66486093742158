import styled from '@emotion/styled';

export const ColorBar = styled.div<{ color: string; width?: string; height?: string }>`
    background-color: var(--${(props) => props.color});
    width: 0.1875rem;
    height: 1.375rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-right: 0.875rem;
`;
