import { css, Global } from '@emotion/react';

import { GlobalColorScheme } from './color-scheme';
import { GlobalAssets } from './images';
import { GlobalSizeScheme } from './size-scheme';

export const GlobalStyles = () => (
    <>
        <GlobalColorScheme />
        <GlobalSizeScheme />
        <GlobalAssets />
        <Global
            styles={css`
                html {
                    height: 100%;
                    line-height: 1.15;
                }
                * {
                    box-sizing: border-box;
                    font-family: 'Roboto', sans-serif;
                }
                body {
                    background-color: var(--color-background);
                    color: var(--color-text);
                    margin: 0;
                    padding: 0;
                    scroll-behavior: smooth;
                    min-height: 100vh;
                }
                #root {
                    height: 100%;
                }
                .MuiPopover-paper {
                    background-color: initial;
                    color: initial;
                    transition: initial;
                    border-radius: initial;
                    box-shadow: initial;
                }
            `}
        />
    </>
);

export const Gradient = 'linear-gradient(125deg, #033477 14%, #12233A 100%)';
