import * as React from 'react';

import { BackButton, LayoutContainer, ViewHeaderContainer } from './styles';

type Props = {
    backButtonText?: string;
    children?: React.ReactNode;
    iconElement: JSX.Element;
    iconFormat?: 'inline' | 'standalone';
    titleText: string;
    onBackButtonClick?: () => void;
};

const ViewHeader = ({
    backButtonText,
    children,
    iconElement,
    iconFormat = 'inline',
    titleText,
    onBackButtonClick,
}: Props) => {
    return (
        <ViewHeaderContainer data-testid="view-header">
            {backButtonText && (
                <BackButton className="back-button" onClick={onBackButtonClick}>
                    {`← ${backButtonText}`}
                </BackButton>
            )}
            <LayoutContainer data-testid="view-header-layout-container">
                {iconFormat === 'standalone' && iconElement}
                <div className="content-container">
                    <span className="title-container" data-testid="view-header-title-container">
                        {iconFormat === 'inline' && iconElement}
                        <span className="title">{titleText}</span>
                    </span>
                    <div>{children}</div>
                </div>
            </LayoutContainer>
        </ViewHeaderContainer>
    );
};

export default ViewHeader;
