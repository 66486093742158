import { PropsWithChildren } from 'react';
import {
    QueryClient as RCQueryClient,
    QueryClientProvider as RCQueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new RCQueryClient();

export const QueryClientProvider = ({ children }: PropsWithChildren<{}>) => (
    <RCQueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {children}
    </RCQueryClientProvider>
);
