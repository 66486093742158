import styled from '@emotion/styled';

export const CheckboxUnchecked = styled.div<{ isDark: boolean }>`
    width: 1rem;
    height: 1rem;
    margin-bottom: 0.08rem;
    cursor: pointer;
    border: 0.0625rem solid var(${({ isDark }) => (isDark ? '--blue300' : '--black400')});
`;

export const CheckedWrapper = styled.div`
    width: calc(1rem + 0.0625rem);
    height: calc(1rem + 0.0625rem);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue2000);
    border-radius: 20%;
    position: relative;

    & > div {
        position: absolute;
        bottom: 0.3125rem;
    }

    cursor: pointer;
`;
