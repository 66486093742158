import styled from '@emotion/styled';

export const DiscrepancyGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6rem;

    & > .discrepancy-column {
        display: inline-flex;
        flex-direction: column;

        & > *:not(:last-child)::after {
            content: '';
            display: block;
            margin: 3.5rem 0;
            border-bottom: 0.0625rem dashed var(--color-live-game-divider);
        }
    }
`;
