import * as React from 'react';

import useTeamCrest from 'app/hooks/useTeamCrest';
import { ReactComponent as IconShield } from 'assets/svg/icon-shield.svg';
import { Icon } from 'components/icons';
import LazyImage from 'components/lazy-image';

import { Discrepancy } from '../../types';
import DiscrepancyList from '../discrepancy-list';

import { DiscrepancyGroupContainer } from './styles';

type Props = {
    teamName: string;
    teamGlobalSrId?: string | null;
    context: 'team' | 'player';
    discrepancyGroups: [string | null, Discrepancy[]][];
    displayGroupName: boolean;
};

const DiscrepancyListGroup = ({
    teamName,
    teamGlobalSrId,
    context,
    discrepancyGroups,
    displayGroupName,
}: Props) => {
    const isPlayerGroup = context === 'player';
    const title = isPlayerGroup ? 'Player Stats' : 'Team Stats';

    const teamCrest = useTeamCrest(teamGlobalSrId);
    const groups = React.useMemo<[string | null, Discrepancy[]][]>(() => {
        if (!isPlayerGroup) {
            return discrepancyGroups;
        }

        return discrepancyGroups.map(([group, discrepancies]) => [
            group,
            discrepancies.sort((a, b) => (a.playerName ?? '').localeCompare(b.playerName ?? '')),
        ]);
    }, [discrepancyGroups, isPlayerGroup]);

    return (
        <DiscrepancyGroupContainer>
            <div className="header">
                <div className="image-container">
                    <LazyImage
                        className="team-logo"
                        data-testid="team-logo"
                        fallback={<IconShield className="team-icon" />}
                        src={teamCrest}
                    />
                </div>
                <div className="content-container">
                    <p className="team-name">{teamName}</p>
                    <p className="title">{title}</p>
                </div>
            </div>
            <div className="discrepancies-container">
                {groups.length ? (
                    groups.map(([group, discrepancies]) => (
                        <DiscrepancyList
                            key={group}
                            title={group}
                            discrepancies={discrepancies}
                            displayGroupName={displayGroupName}
                        />
                    ))
                ) : (
                    <div className="empty-list-container">
                        <div className="icon-container">
                            <Icon name="CheckCircle" />
                        </div>
                        <p className="label">No flags for this team</p>
                    </div>
                )}
            </div>
        </DiscrepancyGroupContainer>
    );
};

export default DiscrepancyListGroup;
