import { TeamSectionContainer } from './styles';
import TeamImage from './team-image';

type Props = {
    isHome: boolean;
    name?: string;
    score?: number;
    srGlobalId?: string | null;
};

const TeamSection = ({ isHome, name = '-', score = 0, srGlobalId }: Props) => {
    return (
        <TeamSectionContainer data-testid="team-section" isHome={isHome}>
            <div className="team-details">
                <div className="team-image-container">
                    <TeamImage srGlobalId={srGlobalId} />
                </div>
                <span className="team-name">{name}</span>
            </div>
            <div className="score">{score}</div>
        </TeamSectionContainer>
    );
};

export default TeamSection;
