import styled from '@emotion/styled';

export const DiscrepancyListContainer = styled.div`
    .title {
        margin-bottom: 0.25rem;
        padding-bottom: 0.375rem;
        border-bottom: 0.125rem solid var(--gray700);
        font-size: 0.625rem;
        font-weight: 700;
        line-height: 0.75rem;
        text-transform: uppercase;
    }
`;
