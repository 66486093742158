import styled from '@emotion/styled';

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2.8125rem;

    & > .header {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;

        & > .controls {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            min-height: 2.125rem;
        }
    }
`;
