import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { Team } from 'api/types/leagues';
import { useCurrentSeason } from 'app/useCurrentSeason';

import { GetLeagueTeamsParameters, leagueTeamsKeys } from './queries';

const getTeams = async ({
    league,
    seasonYear,
    seasonType,
}: GetLeagueTeamsParameters): Promise<Team[]> => {
    const queryParameters = new URLSearchParams({ seasonYear, seasonType });
    const response = await client.get<Team[]>(
        `${API.LEAGUES_API}/${league}/team?${queryParameters.toString()}`,
    );

    return response?.data;
};

export default function useLeagueTeams() {
    const { league, seasonYear, seasonType } = useCurrentSeason();

    return useQuery<Team[]>(
        leagueTeamsKeys.leagueTeams({ league, seasonYear, seasonType }),
        () => getTeams({ league, seasonYear, seasonType }),
        { refetchOnWindowFocus: false },
    );
}
