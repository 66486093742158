import * as React from 'react';
import { Route } from 'type-route';

import { groups, routes } from 'app/router';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';

type Props = {
    route: Route<typeof groups.leagueTeams>;
};

const TeamsTabMenu = ({ route }: Props) => {
    const handleTeamsRedirect = React.useCallback(
        () =>
            routes
                .teams({
                    league: route.params.league,
                    seasonYear: route.params.seasonYear,
                    seasonType: route.params.seasonType,
                })
                .push(),
        [route.params],
    );

    const handleMappingsRedirect = React.useCallback(
        () =>
            routes
                .teamsMappings({
                    league: route.params.league,
                    seasonYear: route.params.seasonYear,
                    seasonType: route.params.seasonType,
                })
                .push(),
        [route.params],
    );

    return (
        <TabMenu>
            <TabButton active={route.name === 'teams'} onClick={handleTeamsRedirect}>
                All Teams
            </TabButton>
            <TabButton active={route.name === 'teamsMappings'} onClick={handleMappingsRedirect}>
                Mapping
            </TabButton>
        </TabMenu>
    );
};

export default TeamsTabMenu;
