import * as React from 'react';

import { LeagueType, SeasonType, SeasonYear } from 'types';

import { useRoute } from './router';

interface CurrentSeasonContextValue {
    league: LeagueType;
    seasonType: SeasonType;
    seasonYear: SeasonYear;
}

const initialContextValue: CurrentSeasonContextValue = {
    league: 'cfb',
    seasonType: SeasonType.Regular,
    seasonYear: '2023',
};

const CurrentSeasonContext = React.createContext<CurrentSeasonContextValue>(initialContextValue);

export const CurrentSeasonProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { params } = useRoute();

    const value = React.useMemo(() => {
        return {
            ...initialContextValue,
            ...('league' in params && { league: params.league }),
            ...('seasonType' in params && { seasonType: params.seasonType }),
            ...('seasonYear' in params && { seasonYear: params.seasonYear }),
        };
    }, [params]);

    return <CurrentSeasonContext.Provider value={value}>{children}</CurrentSeasonContext.Provider>;
};

export function useCurrentSeason() {
    return React.useContext(CurrentSeasonContext);
}
