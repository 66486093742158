import { AuthState } from './auth-state';
import { SportsMediaAuthError } from './errors';
import { User } from './user';

export type Action =
    | { type: 'LOGIN_STARTED' }
    | { type: 'LOGIN_COMPLETE'; user: User }
    | { type: 'LOGOUT_STARTED' }
    | { type: 'LOGOUT_COMPLETE' }
    | { type: 'REFRESH_TOKENS_STARTED' }
    | { type: 'REFRESH_TOKENS_FAILED' }
    | { type: 'REFRESH_TOKENS_COMPLETE' }
    | { type: 'SESSION_RESTORE'; user: User }
    | { type: 'NO_SESSION_TO_RESTORE' }
    | { type: 'ERROR'; error: SportsMediaAuthError };

export const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'LOGIN_STARTED':
        case 'LOGOUT_STARTED':
            return {
                ...state,
                isLoading: true,
                isAuthenticated: false,
                isInitialized: true,
            };
        case 'LOGIN_COMPLETE':
        case 'SESSION_RESTORE':
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.user,
                isInitialized: true,
            };
        case 'LOGOUT_COMPLETE':
            return {
                ...state,
                isLoading: false,
                user: undefined,
                isInitialized: true,
            };
        case 'REFRESH_TOKENS_STARTED':
            return {
                ...state,
            };
        case 'REFRESH_TOKENS_FAILED':
        case 'NO_SESSION_TO_RESTORE':
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                isInitialized: true,
            };
        case 'REFRESH_TOKENS_COMPLETE':
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                isInitialized: true,
            };
        case 'ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                isAuthenticated: false,
                isInitialized: true,
            };
    }
};
