import styled from '@emotion/styled';

import { LiveFlags } from 'api/types/live-flags';

import { DiscrepancyStatus } from '../../types';

type DiscrepancyItemContainerProps = {
    status: DiscrepancyStatus;
};

export const DiscrepancyItemContainer = styled.div<DiscrepancyItemContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.75rem;
    min-height: 2.625rem;

    & > .content-container {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        column-gap: 1rem;
        align-self: stretch;
        border-bottom: 0.0625rem solid var(--color-live-game-discrepancy-item-border);
        background-color: ${({ status }) =>
            status === LiveFlags.FlagStatus.IGNORED
                ? 'var(--color-live-game-discrepancy-item-status-ignored-background)'
                : 'transparent'};
        padding-right: 0.5rem;
        padding-left: 0.375rem;
        color: ${({ status }) =>
            `var(--color-live-game-discrepancy-item-status-${status.toLocaleLowerCase()}-text)`};
        font-size: 0.875rem;
        line-height: 1rem;

        & > .attribute-list {
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            align-items: center;

            & > .attribute-item {
                display: block;
                min-width: fit-content;
            }

            & > .attribute-item:not(:last-of-type)::after {
                content: '\\2022'; // Displays as '•'
                margin: 0 0.5rem;
            }

            & > .discrepancy-issue {
                min-width: 0;
                background-color: ${({ status }) =>
                    status === LiveFlags.FlagStatus.RESOLVED
                        ? 'var(--color-live-game-discrepancy-issue-status-resolved-background)'
                        : 'var(--color-live-game-discrepancy-issue-background)'};
                padding: 0.1875rem 0.25rem;
                font-size: 0.875rem;
                line-height: 1rem;
            }
        }
    }
`;
