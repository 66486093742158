import * as React from 'react';

import { Game } from 'api/types/bff';
import { LeagueType } from 'types';

export function useGameProperties(league: LeagueType, game: Game.LiveGame) {
    return React.useMemo(() => {
        switch (league) {
            case 'cfb':
            case 'ncaafb':
                return {
                    game: {
                        Weather: game?.weather ?? 'N/A',
                        Attendance: game.attendance,
                        Duration: game.duration,
                    },
                };
            case 'ncaamb':
            case 'ncaawb':
                return {
                    away: {
                        'Points in Paint': game.awayTeamPointsInPaint,
                        'Points off turnovers': game.awayTeamPointsOffTurnovers,
                        'Fast Break Points': game.awayTeamFastBreakPoints,
                        'Second Chance Points': game.awayTeamSecondChancePoints,
                    },
                    home: {
                        'Points in Paint': game.homeTeamPointsInPaint,
                        'Points off turnovers': game.homeTeamPointsOffTurnovers,
                        'Fast Break Points': game.homeTeamFastBreakPoints,
                        'Second Chance Points': game.homeTeamSecondChancePoints,
                    },
                    game: {
                        Attendance: game.attendance,
                        'Lead Changes': game.leadChanges,
                        'Times Tied': game.timesTied,
                    },
                };
            default:
                return null;
        }
    }, [league, game]);
}
