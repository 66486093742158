import { IconDecorated } from '../icons';

import { FlagStatsContainer, FlagStatsGroup, IconContainer } from './styles';

type Props = {
    flagged: number;
    ignored: number;
    resolved?: number;
    children?: React.ReactNode;
};

const FlagStats = ({ flagged, ignored, resolved, children }: Props) => {
    return (
        <FlagStatsContainer data-testid="flag-stats">
            <FlagStatsGroup>
                <div className="stat">
                    <IconContainer color="red500" opacity={0.05}>
                        <IconDecorated sizeRem={0.725} name="Flag" paletteColor="red600" />
                    </IconContainer>
                    <span data-testid="stats-flagged">Flagged: {flagged}</span>
                </div>
                <div className="stat">
                    <IconContainer color="black300" opacity={0.1}>
                        <IconDecorated sizeRem={0.725} name="IgnoredFlag" paletteColor="black300" />
                    </IconContainer>
                    <span data-testid="stats-ignored">Ignored: {ignored}</span>
                </div>
                {resolved !== undefined && (
                    <div className="stat">
                        <IconContainer color="green500" opacity={0.1}>
                            <IconDecorated
                                sizeRem={0.725}
                                name="MyLocation"
                                paletteColor="green500"
                            />
                        </IconContainer>
                        <span data-testid="stats-resolved">Resolved: {resolved}</span>
                    </div>
                )}
            </FlagStatsGroup>
            <div>{children}</div>
        </FlagStatsContainer>
    );
};

export default FlagStats;
