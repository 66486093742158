import useTeamCrest from 'app/hooks/useTeamCrest';
import iconShield from 'assets/svg/icon-shield.svg';
import { IconDecorated } from 'components/icons';
import SportradarImage from 'components/sr-logo';

import { FlagContentInternal, VisibleContent } from '../../styles';

import { TeamCrest, TeamCrestPlaceholder } from './styles';

interface HeaderInternalProps {
    awayTeamSrId?: string;
    homeTeamSrId?: string;
    text: string;
    ignoredCount: number;
    createdCount: number;
    isToggled: boolean;
    handleToggle: () => void;
}

const HeaderInternal = ({
    text,
    ignoredCount,
    createdCount,
    isToggled,
    handleToggle,
    awayTeamSrId,
    homeTeamSrId,
}: HeaderInternalProps) => {
    const awayTeamCrest = useTeamCrest(awayTeamSrId);
    const homeTeamCrest = useTeamCrest(homeTeamSrId);

    return (
        <VisibleContent
            visible={isToggled}
            onClick={handleToggle}
            data-testid="flag-boxscore-header-internal"
        >
            <TeamCrest>
                <SportradarImage
                    className={'crest'}
                    src={awayTeamCrest}
                    heightRem={1.5}
                    placeholder={<TeamCrestPlaceholder src={iconShield} />}
                />
            </TeamCrest>
            @
            <TeamCrest>
                <SportradarImage
                    className={'crest'}
                    src={homeTeamCrest}
                    heightRem={1.5}
                    placeholder={<TeamCrestPlaceholder src={iconShield} />}
                />
            </TeamCrest>
            <FlagContentInternal>
                <div className="text">{text}</div>
                <div className="stats">
                    <div className="flagged">
                        <IconDecorated sizeRem={0.75} name="Flag" paletteColor="red600" />
                        <span>{createdCount}</span>
                    </div>
                    <div className="ignored">
                        <IconDecorated sizeRem={0.75} name="Texture" paletteColor="black300" />
                        <span>{ignoredCount}</span>
                    </div>
                </div>
            </FlagContentInternal>
        </VisibleContent>
    );
};

export default HeaderInternal;
