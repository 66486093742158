import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Gradient } from '../../app/theme/global-styles';

export const SidebarContainer = styled.div<{ isDark: boolean }>`
    width: 17rem;
    height: calc(100vh - 4rem); //removes the height of the navigation header
    padding: 1.5rem;
    position: sticky;
    top: 4rem;
    display: flex;
    flex-direction: column;
    min-width: 17rem;

    background: var(--bg-sidebar-bottom-left);
    background-size: contain;

    ${(props) =>
        props.isDark &&
        css`
            width: calc(17rem - 0.0625rem); // calculation so when adding border it doesn't "jump"
            border-right: 0.0625rem solid var(--blue700);
        `}

    & > .header {
        display: block;
        font-size: 0.75rem;
        font-weight: 500;
        color: var(--black300);
        margin-bottom: 0.5rem;
    }
`;

export const MenuElementContainer = styled.div<{ active?: boolean; isDark?: boolean }>`
    height: 2.5rem;

    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    grid-template-rows: 1fr;
    border-radius: 0.1875rem;
    padding: 0.5rem;
    cursor: pointer;
    align-items: center;

    &:hover {
        background-color: var(--color-sidebar-hover-bg);
    }

    & > .title {
        font-family: Roboto, serif;
        color: var(--color-sidebar-title);
        font-size: 0.875rem;
        font-weight: 700;
    }

    & > .counter {
        font-size: 0.75rem;
        text-align: right;
        font-family: Calistoga, serif;
    }

    ${(props) =>
        props.active &&
        css`
            && {
                svg {
                    fill: var(--white);
                }
            }

            background: ${props.isDark ? ' var(--blue500);' : Gradient};

            & > .title,
            & > .counter {
                color: var(--white);
            }
        `}
`;
