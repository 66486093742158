import { useQuery } from 'react-query';

import { LeagueInfoResponse } from 'api/types/leagues';

import API from '../api';
import client from '../api/client';
import { LeagueType, SeasonType } from '../types';

import { LeagueInfoKeys } from './queries';

interface LeagueParams {
    seasonYear: string;
    seasonType: SeasonType;
    league: LeagueType;
}

async function getLeagueInfo(
    league: LeagueType,
    seasonYear: string,
    seasonType: SeasonType,
): Promise<LeagueInfoResponse> {
    const urlSearchParams = new URLSearchParams({
        seasonYear,
        seasonType,
    });

    return client
        .get(`${API.LEAGUES_API}/${league}/info?${urlSearchParams}`)
        .then((response) => response.data);
}

export default function useLeagueInfo(params: LeagueParams) {
    return useQuery(
        LeagueInfoKeys.leagueInfo(params.league, params.seasonType, params.seasonYear),
        () => getLeagueInfo(params.league as LeagueType, params.seasonYear, params.seasonType),
        {
            refetchOnWindowFocus: false,
            staleTime: 2 * 60 * 1000,
            cacheTime: 5 * 60 * 1000,
        },
    );
}
