import * as React from 'react';

import { DiscrepancyType } from 'api/types/bff';
import useCurrentLeagueDetails from 'app/useCurrentLeagueDetails';
import { SourceId } from 'types';

import { useSplitDiscrepanciesBySource } from './useSplitDiscrepanciesBySource';

export default function useDiscrepanciesSource(
    discrepancies: DiscrepancyType[],
): [DiscrepancyType[], SourceId, (source: SourceId) => void] {
    const { sources } = useCurrentLeagueDetails();
    const discrepancyBySources = useSplitDiscrepanciesBySource(discrepancies, sources);

    const firstNonEmptySource =
        discrepancyBySources.find((source) => source.discrepancies.length) ||
        discrepancyBySources[0];

    React.useEffect(() => {
        setActiveSource(firstNonEmptySource.source);
    }, [firstNonEmptySource.source]);

    const [activeSource, setActiveSource] = React.useState(firstNonEmptySource.source);
    const activeDiscrepancies =
        discrepancyBySources.find((source) => source.source === activeSource)?.discrepancies || [];

    const handleSourceClick = React.useCallback((source: SourceId) => {
        setActiveSource(source);
    }, []);

    return [activeDiscrepancies, activeSource, handleSourceClick];
}
