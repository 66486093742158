import styled from '@emotion/styled';
import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

export interface StyledTextareaProps
    extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {}
export const StyledTextarea = styled.textarea<StyledTextareaProps>`
    background-color: var(--white);
    border: 1px solid var(--gray300);
    padding: 0.5rem;
`;
