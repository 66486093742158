import { useContext } from 'react';

import SportsMediaAuthContext, {
    SportsMediaAuthContextInterface,
} from './sports-media-auth-context';

const useSportsMediaAuth = (): SportsMediaAuthContextInterface =>
    useContext(SportsMediaAuthContext);

export default useSportsMediaAuth;
