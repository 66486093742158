import { DiscrepancyType } from 'api/types/bff';
import { CompareFn } from 'types/sorting';

export const byName: CompareFn<DiscrepancyType> = (aDiscrepancy, bDiscrepancy) => {
    return aDiscrepancy.name.localeCompare(bDiscrepancy.name);
};

export const byPlayerName: CompareFn<DiscrepancyType> = (aDiscrepancy, bDiscrepancy) => {
    const aPlayerName = aDiscrepancy.contextPlayer?.name;
    const bPlayerName = bDiscrepancy.contextPlayer?.name;

    if (!aPlayerName && bPlayerName) {
        return -1;
    }

    if (aPlayerName && !bPlayerName) {
        return 1;
    }

    return (aPlayerName ?? '').localeCompare(bPlayerName ?? '');
};
