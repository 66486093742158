import { LocalizationProvider as MUILocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PropsWithChildren } from 'react';
import 'dayjs/locale/en';

export const LocalizationProvider = ({ children }: PropsWithChildren<{}>) => (
    <MUILocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        {children}
    </MUILocalizationProvider>
);
