import * as React from 'react';

import { Option } from 'types/controls';

export enum MappingStatus {
    Automatic = 'automatic',
    Manual = 'manual',
    Unmapped = 'unmapped',
    All = 'all',
}

export function isMappingStatus(status: string): status is MappingStatus {
    return Object.values(MappingStatus).includes(status as MappingStatus);
}

const DEFAULT_OPTIONS: Option<MappingStatus>[] = [
    {
        label: 'ALL',
        value: MappingStatus.All,
    },
    {
        label: 'AUTOMATICALLY MAPPED',
        value: MappingStatus.Automatic,
    },
    {
        label: 'MANUALLY MAPPED',
        value: MappingStatus.Manual,
    },
    {
        label: 'UNMAPPED',
        value: MappingStatus.Unmapped,
    },
];

export function useMappingStatusOptions(current: MappingStatus): Option<MappingStatus>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: current === option.value,
            })),
        [current],
    );
}
