import * as React from 'react';

import useCurrentLeagueDetails from 'app/useCurrentLeagueDetails';
import useExternalLinks from 'hooks/useExternalLinks';
import { FlagType } from 'types';

import { useCurrentSeason } from '../../../app/useCurrentSeason';
import useFlagDiscrepancies from '../../../hooks/useFlagStats/useFlagDiscrepancies';
import { useToggle } from '../hooks/useToggle';

import FlagBoxscore from './boxscore/boxscore';
import FlagContext from './context';
import FlagDefault from './default/flag-default';
import useDiscrepanciesSource from './hooks/useDiscrepanciesSource';
import { useSplitDiscrepanciesBySource } from './hooks/useSplitDiscrepanciesBySource';
import FlagPolls from './polls';
import FlagRoster from './roster/flag-roster';
import FlagSchedule from './schedule';
import FlagSeasonal from './seasonal/flag-seasonal';
import FlagStandings from './standings';
import { FlagProps } from './types';

const getFlagType = (type: string): FlagType | null => {
    switch (type) {
        case 'boxscore':
            return FlagType.Boxscore;
        case 'standings':
            return FlagType.Standings;
        case 'seasonal':
            return FlagType.Seasonal;
        case 'roster':
            return FlagType.Roster;
        case 'schedule':
            return FlagType.Schedule;
        case 'polls':
            return FlagType.Polls;

        default: {
            console.warn(`There is no flag component for given flagType: ${type}`);

            return null;
        }
    }
};

const Flag = (props: FlagProps) => {
    const { isToggled, handleToggle } = useToggle(props.isToggled);
    const { league, seasonYear, seasonType } = useCurrentSeason();
    const { sources } = useCurrentLeagueDetails();
    const { data: discrepancies, isLoading: areDiscrepanciesLoading } = useFlagDiscrepancies(
        { flagId: props.flag.id, league, seasonYear, seasonType, status: props.status },
        isToggled,
    );

    const flagType = getFlagType(props.flag.flagType.key);
    const { data: externalLinks, isLoading: areExternalLinksLoading } = useExternalLinks(
        {
            league,
            seasonYear,
            seasonType,
            id: props.flag.context.srId,
            flagType: flagType!,
            week: props.flag.week,
        },
        isToggled && !!flagType,
    );

    const [activeDiscrepancies, activeSource, setActiveSource] = useDiscrepanciesSource(
        discrepancies ?? [],
    );
    const discrepancyBySources = useSplitDiscrepanciesBySource(discrepancies ?? [], sources);

    const FlagComponent = React.useMemo(() => {
        switch (props.flag.flagType.key) {
            case 'boxscore':
                return <FlagBoxscore {...props} />;
            case 'standings':
                return <FlagStandings {...props} />;
            case 'seasonal':
                return <FlagSeasonal {...props} />;
            case 'roster':
                return <FlagRoster {...props} />;
            case 'schedule':
                return <FlagSchedule {...props} />;
            case 'polls':
                return <FlagPolls {...props} />;
            case 'player':
                return <FlagDefault {...props} />;

            default: {
                console.warn(
                    `There is no flag component for given flagType: ${props.flag.flagType.name}`,
                );

                return <div>There is no flag for "{props.flag.flagType.name}" type</div>;
            }
        }
    }, [props]);

    const context = React.useMemo(
        () => ({
            activeSource,
            setActiveSource,
            activeDiscrepancies,
            discrepancyBySources,
            areDiscrepanciesLoading,
            externalLinks: externalLinks ?? [],
            areExternalLinksLoading,
            isToggled,
            handleToggle,
        }),
        [
            activeDiscrepancies,
            activeSource,
            areDiscrepanciesLoading,
            areExternalLinksLoading,
            discrepancyBySources,
            externalLinks,
            handleToggle,
            isToggled,
            setActiveSource,
        ],
    );

    return <FlagContext.Provider value={context}>{FlagComponent}</FlagContext.Provider>;
};

export default Flag;
