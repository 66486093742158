import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';

import { DiscrepancyType } from 'api/types/bff';
import { SourceId } from 'types';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export function useDiscrepanciesTitle(
    activeSource: SourceId | null,
    includeDiscrepancyName = true,
) {
    return React.useCallback(
        (discrepancy: DiscrepancyType) => {
            const srDiscrepancy = discrepancy.discrepancySources.find(
                (ds) => ds.sourceId === SourceId.SR,
            );
            let discrepancyValues;

            // if there is active source display discrepancies only from there if not map everything but sportradar
            if (activeSource) {
                discrepancyValues = discrepancy.discrepancySources.filter(
                    (ds) => ds.sourceId === activeSource,
                );
            } else {
                console.warn('No active source selected, this should not happen');
                discrepancyValues = discrepancy.discrepancySources.filter(
                    (ds) => ds.sourceId !== srDiscrepancy?.sourceId,
                );
            }

            const discrepancyValuesFiltered = discrepancyValues.map((ds) => ds.value);
            const values = [srDiscrepancy?.value, ...discrepancyValuesFiltered];
            const formattedValues = values.map((value) => value && formatIfIsDate(value));
            const prefix = includeDiscrepancyName ? `${discrepancy.name} ` : '';

            return `${prefix}${formattedValues.join(' / ')}`;
        },
        [activeSource, includeDiscrepancyName],
    );
}

const formatIfIsDate = (value: string): string => {
    if (!isDate(value)) {
        return value;
    }

    return dayjs(value).format('MM-DD-YYYY hh:mm A z');
};

const isDate = (value: string): boolean => {
    const ISODateRegex = /\d{4}-[01]\d-[0-3]\dT/;

    return ISODateRegex.test(value);
};
