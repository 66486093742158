import { LiveFlags } from 'api/types/live-flags';
import { useGroupBy } from 'hooks/useGroupBy';
import { LeagueType } from 'types';

import { Discrepancy } from '../../types';
import DiscrepancyListGroup from '../discrepancy-list-group';

import { DiscrepancyGridContainer } from './styles';

type Props = {
    league: LeagueType;
    awayTeamName: string;
    awayTeamGlobalSrId?: string | null;
    homeTeamName: string;
    homeTeamGlobalSrId?: string | null;
    category?: LiveFlags.LiveFlagCategoryName;
    discrepancies: Discrepancy[];
};

const DiscrepancyGrid = ({
    league,
    awayTeamName,
    awayTeamGlobalSrId,
    homeTeamName,
    homeTeamGlobalSrId,
    category,
    discrepancies,
}: Props) => {
    const { groups } = useGroupBy(discrepancies, (d) => `${d.teamQualifier}.${d.context}`);

    return (
        <DiscrepancyGridContainer>
            <div className="discrepancy-column">
                {category !== LiveFlags.LiveFlagCategoryName.Team && (
                    <DiscrepancyListGroup
                        league={league}
                        teamName={awayTeamName}
                        teamGlobalSrId={awayTeamGlobalSrId}
                        context="player"
                        discrepancies={groups['away.player']}
                    />
                )}
                {category !== LiveFlags.LiveFlagCategoryName.Individual && (
                    <DiscrepancyListGroup
                        league={league}
                        teamName={awayTeamName}
                        teamGlobalSrId={awayTeamGlobalSrId}
                        context="team"
                        discrepancies={groups['away.team']}
                    />
                )}
            </div>
            <div className="discrepancy-column">
                {category !== LiveFlags.LiveFlagCategoryName.Team && (
                    <DiscrepancyListGroup
                        league={league}
                        teamName={homeTeamName}
                        teamGlobalSrId={homeTeamGlobalSrId}
                        context="player"
                        discrepancies={groups['home.player']}
                    />
                )}
                {category !== LiveFlags.LiveFlagCategoryName.Individual && (
                    <DiscrepancyListGroup
                        league={league}
                        teamName={homeTeamName}
                        teamGlobalSrId={homeTeamGlobalSrId}
                        context="team"
                        discrepancies={groups['home.team']}
                    />
                )}
            </div>
        </DiscrepancyGridContainer>
    );
};

export default DiscrepancyGrid;
