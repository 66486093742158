import * as React from 'react';

import Loading from 'components/loading';

import useSportsMediaAuth from '../auth/use-sports-media-auth';

import AuthenticatedApp from './authenticated-app';
import UnauthenticatedApp from './unauthenticated-app';

const MonitoringApp = () => {
    const { isLoading, isAuthenticated, error, isInitialized } = useSportsMediaAuth();

    if (isLoading || !isInitialized) {
        return <Loading mt={10} />;
    }

    if (error) {
        console.error(error);

        return <div>There was an error</div>;
    }

    return isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default MonitoringApp;
