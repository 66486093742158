import styled from '@emotion/styled';

type Props = {
    heightRem?: number;
    widthRem?: number;
};

export const SrLogoContainer = styled.div<Props>`
    & > .image {
        ${({ heightRem }) => (heightRem ? `height:${heightRem}rem;` : '')}
        ${({ widthRem }) => (widthRem ? `width:${widthRem}rem;` : '')}
    }
`;
