import * as React from 'react';

import { CompareFn } from 'types/sorting';

function useSorting<T>(...compareFns: CompareFn<T>[]) {
    return React.useCallback(
        (array: T[]) => {
            return array.sort((a, b) => {
                for (const compareFn of compareFns) {
                    const compareResult = compareFn(a, b);

                    if (compareResult !== 0) {
                        return compareResult;
                    }
                }

                return 0;
            });
        },
        [compareFns],
    );
}

export default useSorting;
