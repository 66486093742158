import styled from '@emotion/styled';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    & > .context {
        display: grid;
        grid-template-columns: 1fr 11fr;
        row-gap: 0.375rem;
        white-space: nowrap;
        font-size: 0.875rem;

        & > .title {
            margin-right: 2rem;
            font-weight: 700;
            line-height: 1rem;
        }

        & > .value {
            text-transform: capitalize;
        }
    }

    & > .discrepancy {
        width: 100%;
        background-color: rgba(var(--red500-rgb), 0.1);
        padding: 0.5rem;
        border-radius: 0.3125rem;
    }
`;

export const DiscrepancyValuesGrid = styled.div`
    & .--header {
        font-weight: 900;
    }

    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    font-size: 0.875rem;

    & > .row {
        display: grid;
        width: 100%;
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr;

        &:first-of-type {
            margin-bottom: 0.5rem;
        }

        & > .value {
            text-align: right;
            font-weight: 900;
        }
    }
`;

export const IgnoreReason = styled.div`
    gap: 1rem;
    display: flex;
    flex-direction: column;

    & > .title {
        font-weight: 700;
        font-size: 0.875rem;
    }

    & > .dropdown-container {
        margin-top: 0.5rem;
    }

    & > .reason {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        & > .title {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 0.625rem;
        }
    }
`;
export const SuccessfullyIgnoredModalContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;

    & > .messages {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        & > .title {
            font-weight: 700;
            font-size: 1.125rem;
        }
        & > .description {
            font-weight: 400;
            font-size: 0.75rem;
        }
    }
`;

export const IconContainer = styled.div`
    display: flex;
    flex: 0;
    background-color: rgba(var(--black300-rgb), 0.1);
    border-radius: 50%;
    padding: 0.7rem;
    width: 2.5rem;
    height: 2.5rem;
    place-items: center;
`;
