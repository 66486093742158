import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonHTMLAttributes } from 'react';

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 0.75rem;
`;

export interface SourceButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    active?: boolean;
}

export const SourceButton = styled.div<SourceButtonProps>`
    display: flex;
    border: 0.0625rem solid var(--blue500);
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: var(--color-discrepancy-source-active-bgcolor);
        `}

    padding: 0.5625rem 0.375rem;
    text-align: center;
    font-family: Roboto, serif;
    font-weight: 900;
    font-size: 0.625rem;
    text-transform: uppercase;
    border-radius: 3.125rem;
    gap: 0.25rem;
    white-space: nowrap;

    & > .value {
        color: var(--color-discrepancy-source-count);
    }
`;
