import * as React from 'react';

import { LiveFlags } from 'api/types/live-flags';
import { Option } from 'types/controls';

export function useLiveFlagCategoryOptions(
    categories?: LiveFlags.LiveFlagCategory[],
    current?: LiveFlags.LiveFlagCategoryName,
): Option<LiveFlags.LiveFlagCategory | undefined>[] {
    return React.useMemo(
        () => [
            {
                label: 'All',
                value: undefined,
                selected: current === undefined,
            },
            ...(categories ?? []).map((category) => ({
                label: category.categoryName,
                value: category,
                selected: category.categoryName === current,
            })),
        ],

        [current, categories],
    );
}
