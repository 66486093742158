import * as React from 'react';

import { IconKeys } from 'components/icons';

export function useLeagueLogo(league: string): IconKeys {
    return React.useMemo(() => {
        switch (league) {
            case 'ncaamb':
            case 'ncaawb':
            case 'nba':
            case 'cbk':
                return 'SportsBasketballRounded';
            case 'nfl':
            case 'cfb':
            case 'ncaafb':
                return 'SportsFootballRounded';
            default:
                return 'SportsFootballRounded';
        }
    }, [league]);
}
