import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GameContextInfo = styled.div`
    display: flex;

    flex-direction: column;

    flex-grow: 1;

    & > .game-date {
        font-size: 0.75rem;
        font-weight: 700;
    }

    & > .game-opponents {
        margin-top: 0.25rem;
        font-size: 0.875rem;
    }
`;

export const FlagDiscrepancies = styled.div<{ layout?: 'columns' }>`
    max-width: 100%;
    overflow: hidden;

    ${({ layout }) =>
        layout === 'columns' &&
        css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 1.5rem;
        `}
`;

export const DiscrepanciesContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    & > .header {
        background-color: var(--color-discrepancy-category-title-bgcolor);
        color: var(--color-discrepancy-category-title-color);
        padding: 0.25rem;
        font-weight: 700;
        font-size: 0.75rem;
        width: inherit;
        margin-top: 0.8rem;
    }

    & > .list {
        display: flex;
        flex-direction: column;
        margin-top: 0.8rem;
        gap: 0.25rem;
    }
`;

export const DiscrepancyRow = styled.div`
    display: grid;
    gap: 1.5rem;
    align-items: center;
    grid-template-columns: 6fr 11fr;

    & > .title-wrapper {
        display: flex;
        justify-content: space-between;

        & > .title {
            white-space: nowrap;
            font-size: 0.875rem;
            font-weight: 700;
        }
    }
`;

export const TeamCrestPlaceholder = styled.img`
    width: 1.5rem;
    margin: 0 0.25rem;
`;

export const TeamCrest = styled.div`
    width: 1.5rem;
    margin: 0 0.25rem;

    & > .crest {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
