import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { User } from './user';
import { base64URLEncode, sha256 } from './utils';

enum LocalStorageKeys {
    IdTokenKey = 'smm:token:id',
    AccessTokenKey = 'smm:token:access',
    RefreshTokenKey = 'smm:token:refresh',
    StateKey = 'smm:state',
}

export interface AuthClientOpts {
    clientId: string;
    cognitoUrl: string;
}

export class AuthClient {
    private opts: AuthClientOpts;

    public constructor(opts: AuthClientOpts) {
        this.opts = opts;
    }

    public async loginWithRedirect(): Promise<void> {
        const state = await this.makeNonce();
        const codeVerifier = await this.makeNonce();

        sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);
        const codeChallenge = base64URLEncode(await sha256(codeVerifier));

        window.location =
            `${this.opts.cognitoUrl}/login?response_type=code&client_id=${this.opts.clientId}&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${window.location.origin}` as string &
                Location;
    }

    public async authenticate(): Promise<void> {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code') ?? '';
        const state = params.get('state') ?? '';

        const codeVerifier = sessionStorage.getItem(`codeVerifier-${state}`);

        sessionStorage.removeItem(`codeVerifier-${state}`);

        if (codeVerifier === null) {
            return;
        }

        const body = Object.entries({
            grant_type: 'authorization_code',
            client_id: this.opts.clientId,
            code: code,
            code_verifier: codeVerifier,
            redirect_uri: window.location.origin,
        })
            .map(([k, v]) => `${k}=${v}`)
            .join('&');

        const result = await axios.post(`${this.opts.cognitoUrl}/oauth2/token`, body, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        });

        const { id_token, access_token, refresh_token } = result.data;

        localStorage.setItem(LocalStorageKeys.IdTokenKey, id_token);
        localStorage.setItem(LocalStorageKeys.AccessTokenKey, access_token);
        localStorage.setItem(LocalStorageKeys.RefreshTokenKey, refresh_token);
        localStorage.setItem(LocalStorageKeys.StateKey, state);
    }

    public async logout() {
        const state = localStorage.getItem(LocalStorageKeys.StateKey) ?? '';

        this.removeSession();

        // TODO: This way should be used once Hosted UI will get fixed
        // window.location =
        //     `${this.opts.cognitoUrl}/logout?client_id=${this.opts.clientId}&redirect_uri=${window.location.origin}&response_type=code&state=${state}` as string &
        //     Location;
        await fetch(
            `${this.opts.cognitoUrl}/logout?client_id=${this.opts.clientId}&redirect_uri=${window.location.origin}&response_type=code&state=${state}`,
            { mode: 'no-cors' },
        );
    }

    public removeSession(): void {
        localStorage.removeItem(LocalStorageKeys.IdTokenKey);
        localStorage.removeItem(LocalStorageKeys.AccessTokenKey);
        localStorage.removeItem(LocalStorageKeys.RefreshTokenKey);
        localStorage.removeItem(LocalStorageKeys.StateKey);
    }

    public async refreshTokens(): Promise<void> {
        const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshTokenKey);

        // if there is no refresh token there's no point to make a request with it that fails
        if (!refreshToken) {
            this.removeSession();
            window.location.reload();
        }

        const body = Object.entries({
            grant_type: 'refresh_token',
            client_id: this.opts.clientId,
            redirect_uri: window.location.origin,
            refresh_token: refreshToken,
        })
            .map(([k, v]) => `${k}=${v}`)
            .join('&');

        const result = await axios.post(`${this.opts.cognitoUrl}/oauth2/token`, body, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        });
        const { id_token, access_token } = result.data;

        localStorage.setItem(LocalStorageKeys.IdTokenKey, id_token);
        localStorage.setItem(LocalStorageKeys.AccessTokenKey, access_token);
    }

    public hasSession(): boolean {
        return (
            !!localStorage.getItem(LocalStorageKeys.IdTokenKey) &&
            !!localStorage.getItem(LocalStorageKeys.AccessTokenKey) &&
            !!localStorage.getItem(LocalStorageKeys.RefreshTokenKey) &&
            !!localStorage.getItem(LocalStorageKeys.StateKey)
        );
    }

    public getIdToken() {
        return localStorage.getItem(LocalStorageKeys.IdTokenKey) ?? '';
    }

    public getAccessToken() {
        return localStorage.getItem(LocalStorageKeys.AccessTokenKey) ?? '';
    }

    public getUser() {
        return jwtDecode<User>(this.getIdToken());
    }

    public getExpirationTime(): number {
        return jwtDecode<{ exp: number }>(this.getIdToken())?.exp ?? -1;
    }

    public hasTokenExpired(): boolean {
        const tokenExpirationTime = this.getExpirationTime() * 1000; //ms

        return tokenExpirationTime < Date.now();
    }

    private async makeNonce(): Promise<string> {
        const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());

        return Array.from(new Uint8Array(hash))
            .map((b) => b.toString(16).padStart(2, '0'))
            .join('');
    }
}
