import { GAME_STATUS_MAP, GameStatus, isGameStatus } from 'api/types/leagues';
import Chip, { ChipVariant } from 'components/chip';

export const GAME_STATUS_VARIANTS: Record<GameStatus, ChipVariant> = {
    [GameStatus.Cancelled]: 'errorSecondary',
    [GameStatus.Closed]: 'errorPrimary',
    [GameStatus.Complete]: 'errorSecondary',
    [GameStatus.Created]: 'warningSecondary',
    [GameStatus.Delayed]: 'errorSecondary',
    [GameStatus.FlexSchedule]: 'warningSecondary',
    [GameStatus.Halftime]: 'successPrimary',
    [GameStatus.InProgress]: 'successPrimary',
    [GameStatus.Postponed]: 'warningPrimary',
    [GameStatus.Suspended]: 'errorSecondary',
    [GameStatus.Scheduled]: 'warningSecondary',
    [GameStatus.TimeTbd]: 'warningSecondary',
};

type Props = {
    status: string;
};

const GameStatusChip = ({ status }: Props) => {
    if (!isGameStatus(status)) {
        return (
            <Chip data-testid="game-status-chip" variant="errorPrimary">
                {status}
            </Chip>
        );
    }

    return (
        <Chip data-testid="game-status-chip" variant={GAME_STATUS_VARIANTS[status]}>
            {GAME_STATUS_MAP[status]}
        </Chip>
    );
};

export default GameStatusChip;
