import * as React from 'react';
import { Route } from 'type-route';

import { Admin } from 'api/types/bff';
import { groups } from 'app/router';

const FLAG_TYPES_MAP: Partial<
    Record<Route<typeof groups.leagueTeamsTeamStatistics>['name'], Admin.ScheduleTypes>
> = {
    teamsTeamRoster: Admin.ScheduleTypes.TeamRoster,
    teamsTeamSeasonal: Admin.ScheduleTypes.TeamSeasonal,
    teamsTeamSchedule: Admin.ScheduleTypes.TeamSchedule,
    teamsTeamStandings: Admin.ScheduleTypes.TeamStandings,
};

export function useRouteScheduleType(
    routeName: Route<typeof groups.leagueTeamsTeamStatistics>['name'],
): Admin.ScheduleTypes | undefined {
    return React.useMemo(() => FLAG_TYPES_MAP[routeName], [routeName]);
}
