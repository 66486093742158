import { styled } from '@mui/material/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import * as React from 'react';

import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';

import { DropdownPlaceholder } from '../../dropdown/styles';
import { IconDecorated } from '../../icons';
import Popover from '../../popover';
import Button from '../button';

import {
    ButtonWrapper,
    DateSelectorContainer,
    ErrorMessage,
    Label,
    RangePicker,
    RangePickerContainer,
    SingleDatePicker,
} from './styles';

export type DateSelectorValue = [dayjs.Dayjs] | [dayjs.Dayjs, dayjs.Dayjs];

interface DateSelectorProps {
    singleDatePickerProps?: DatePickerProps<unknown>;
    rangeFromDatePickerProps?: DatePickerProps<unknown>;
    rangeToDatePickerProps?: DatePickerProps<unknown>;
    value: DateSelectorValue;
    onChange: (newValue: DateSelectorValue) => void;
}

// here you can find the classes that can be styled https://mui.com/material-ui/react-text-field/
const StyledDatePicker = styled(DatePicker)({
    // prettier-ignore
    '.MuiPickersToolbar-root': {
        borderRadius: 6,
        borderWidth: 1,
        border: '1px solid',
    },
    '.MuiInputBase-input': {
        color: 'var(--color-text)',
    },
    '.MuiOutlinedInput-root': {
        borderRadius: 0,
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '.Mui-focused fieldset': {
        border: 'none',
        backgroundColor: 'transparent',
    },
    '.MuiSvgIcon-root': {
        color: 'var(--color-text)',
    },
});

const Dateselector = (props: DateSelectorProps) => {
    const placeholderRef = React.useRef<HTMLDivElement>(null!);
    const [pickerMode, setPickerMode] = React.useState<'date' | 'range'>('date');
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [singleDateValue, setSingleDateValue] = React.useState<dayjs.Dayjs>(dayjs());
    const [fromDateValue, setFromValue] = React.useState<dayjs.Dayjs>();
    const [toDateValue, setToValue] = React.useState<dayjs.Dayjs>();

    const placeHolder = React.useMemo(() => {
        return (
            <DropdownPlaceholder ref={placeholderRef}>
                <Label>
                    DATE:&nbsp;
                    {props.value.length > 1
                        ? `${dayjs(props.value[0]).format('L')} - ${dayjs(props.value[1]).format(
                              'L',
                          )}`
                        : dayjs(props.value[0]).format('L')}
                </Label>
                <IconDecorated sizeRem={1} name="ArrowDropDown" />
            </DropdownPlaceholder>
        );
    }, [props.value]);

    React.useEffect(() => {
        setPickerMode(props.value.length > 1 ? 'range' : 'date');

        if (props.value.length > 1) {
            setFromValue(props.value[0]);
            setToValue(props.value[1]);
        }

        if (props.value.length === 1) {
            setSingleDateValue(props.value[0]);
        }
    }, [props.value]);

    React.useEffect(() => {
        setErrorMessage(null);
    }, [pickerMode]);

    const handleOnClickDone = React.useCallback(
        (onClose: () => void) => {
            setErrorMessage('');

            if (pickerMode === 'range') {
                if (!fromDateValue || !toDateValue) {
                    setErrorMessage('You need to provide both from and to dates');

                    return;
                }

                if (fromDateValue.isAfter(toDateValue)) {
                    setErrorMessage('From date has to be earlier than to date');

                    return;
                }

                props.onChange([fromDateValue, toDateValue]);
            }

            if (pickerMode === 'date') {
                if (!singleDateValue) {
                    setErrorMessage('You need to provide a date');

                    return;
                }

                props.onChange([singleDateValue]);
            }

            onClose();
        },
        [props, pickerMode, toDateValue, fromDateValue, singleDateValue],
    );

    const anchorVerticalOrigin = placeholderRef.current
        ? placeholderRef.current.offsetHeight + 2
        : 'bottom';

    return (
        <div style={{ minWidth: '8rem' }}>
            <Popover
                anchorOrigin={{ horizontal: 'left', vertical: anchorVerticalOrigin }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                placeholder={placeHolder}
            >
                {(onClose) => {
                    return (
                        <DateSelectorContainer
                            placeholderWidth={placeholderRef?.current?.offsetWidth}
                        >
                            <div className="tabs">
                                <TabMenu>
                                    <TabButton
                                        active={pickerMode === 'date'}
                                        onClick={() => setPickerMode('date')}
                                    >
                                        Date
                                    </TabButton>
                                    <TabButton
                                        active={pickerMode === 'range'}
                                        onClick={() => setPickerMode('range')}
                                    >
                                        Range
                                    </TabButton>
                                </TabMenu>
                            </div>
                            {pickerMode === 'range' && (
                                <RangePicker>
                                    <RangePickerContainer>
                                        <span className="label">START</span>
                                        <StyledDatePicker
                                            value={fromDateValue}
                                            onChange={(newDate) =>
                                                setFromValue(newDate as dayjs.Dayjs)
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                            }}
                                        />
                                    </RangePickerContainer>
                                    <div className="separator" />
                                    <RangePickerContainer>
                                        <span className="label">END</span>
                                        <StyledDatePicker
                                            value={toDateValue}
                                            onChange={(newDate) =>
                                                setToValue(newDate as dayjs.Dayjs)
                                            }
                                            slotProps={{
                                                field: { clearable: true },
                                            }}
                                        />
                                    </RangePickerContainer>
                                </RangePicker>
                            )}
                            {pickerMode === 'date' && (
                                <SingleDatePicker>
                                    <StyledDatePicker
                                        slotProps={{
                                            actionBar: {
                                                actions: ['today'],
                                            },
                                        }}
                                        onChange={(newDate) =>
                                            setSingleDateValue(newDate as dayjs.Dayjs)
                                        }
                                        value={singleDateValue}
                                        {...props.singleDatePickerProps}
                                    />
                                </SingleDatePicker>
                            )}
                            <ErrorMessage>{errorMessage}</ErrorMessage>
                            <ButtonWrapper>
                                <Button onClick={() => handleOnClickDone(onClose)}>DONE</Button>
                            </ButtonWrapper>
                        </DateSelectorContainer>
                    );
                }}
            </Popover>
        </div>
    );
};

export default Dateselector;
