import { FlagType, Context } from 'api/types/bff';

export function useFlagTitle(flag: FlagType): string {
    const context = flag.context;

    if (Context.isTeam(context)) {
        return `${context.name}`;
    }

    if (Context.isPlayer(context)) {
        return `${context.name} · ${context.name}`;
    }

    console.error('Could not create a title for the following flag', { flag });

    return 'The title could not be made';
}
