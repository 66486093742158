import styled from '@emotion/styled';

type ButtonContainerProps = {
    variant: 'default' | 'primary';
};

export const ButtonContainer = styled.div<ButtonContainerProps>`
    --color-border: ${({ variant }) => `var(--color-action-button-variant-${variant}-border)`};
    --color-icon: ${({ variant }) => `var(--color-action-button-variant-${variant}-icon)`};
    --color-text: ${({ variant }) => `var(--color-action-button-variant-${variant}-text)`};

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
    padding: 0.25rem 0.4375rem;
    border: 0.0625rem solid var(--color-border);
    border-radius: 0.3125rem;

    & > .icon-container {
        width: 0.875rem;
        height: 0.875rem;
        color: var(--color-icon);
    }

    & > .content {
        color: var(--color-text);
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 0.75rem;
        text-transform: uppercase;
    }
`;
