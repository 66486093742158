import { useMemo } from 'react';

import { Color, colours } from 'app/theme/color-scheme';
import { SourceId } from 'types';

const getColorName = (color: Readonly<Color>): string =>
    Object.entries(colours).find((colorEntry) => colorEntry[1] === color)?.[0] ?? 'none';

export default function useColorBar(source: SourceId, isMapped: boolean): string {
    return useMemo(() => {
        if (!isMapped) {
            if (source === SourceId.SR) {
                return getColorName(colours.blue200);
            }

            return getColorName(colours.yellow500);
        }

        return 'none';
    }, [source, isMapped]);
}
