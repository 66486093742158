import styled from '@emotion/styled';

export const variants = [
    'successPrimary',
    'warningPrimary',
    'warningSecondary',
    'errorPrimary',
    'errorSecondary',
] as const;

export type ChipVariant = (typeof variants)[number];

export const VARIANT_STYLES = {
    successPrimary: { backgroundColor: 'var(--green500)', color: 'var(--white)' },
    warningPrimary: { backgroundColor: 'var(--yellow600)', color: 'var(--black500)' },
    warningSecondary: { backgroundColor: 'var(--yellow200)', color: 'var(--black500)' },
    errorPrimary: { backgroundColor: 'var(--red600)', color: 'var(--white)' },
    errorSecondary: { backgroundColor: 'var(--red500)', color: 'var(--white)' },
};

type Props = {
    variant: ChipVariant;
};

const Chip = styled.div<Props>`
    display: inline-block;
    white-space: nowrap;
    background-color: ${({ variant }) => VARIANT_STYLES[variant].backgroundColor};
    padding: 0.3125rem 0.5rem;
    border-radius: 0.3125rem;
    color: ${({ variant }) => VARIANT_STYLES[variant].color};
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.875rem;
    text-transform: uppercase;
`;

export default Chip;
