import { StatusCodes } from 'http-status-codes';
import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import { DiscrepancyType } from 'api/types/bff';
import { LeagueType } from 'types';

export default function useUpdateDiscrepancyState(league: LeagueType) {
    const queryClient = useQueryClient();

    return useMutation(
        async (discrepancy: DiscrepancyType) => {
            return client.patch(`${API.FLAGS_API}/discrepancy/${discrepancy.id}`, {
                status: discrepancy.status,
                ...(discrepancy.ignore ? { ignoreReason: discrepancy.ignore.reason } : {}),
                ...(discrepancy.ignore ? { ignoreComment: discrepancy.ignore.comment } : {}),
            });
        },
        {
            onSuccess: (data) => {
                if (data.status === StatusCodes.NO_CONTENT) {
                    queryClient.invalidateQueries([league, 'flagStats']);
                    queryClient.invalidateQueries([league, 'flags']);
                }
            },
            onError: async (error) => {
                console.error(error);
            },
            retry: 2,
        },
    );
}
