import { ColorScheme, Table } from './styles';
import TableRow from './table-row';
import { Score } from './types';
import useLabelCells from './useLabelCells';
import useTeamCells from './useTeamCells';

type Props = {
    awayTeamAlias?: string;
    awayTeamScores?: Score[];
    awayTeamTotalScore?: Score;
    colorScheme?: ColorScheme;
    homeTeamAlias?: string;
    homeTeamScores?: Score[];
    homeTeamTotalScore?: Score;
    minColumnCount?: number;
};

const LinescoreTable = ({
    awayTeamAlias = '-',
    awayTeamScores = [],
    awayTeamTotalScore = {},
    colorScheme = 'dark',
    homeTeamAlias = '-',
    homeTeamScores = [],
    homeTeamTotalScore = {},
    minColumnCount = 4,
}: Props) => {
    const columnCount = Math.max(awayTeamScores.length, homeTeamScores.length, minColumnCount);

    const labelCells = useLabelCells(columnCount);
    const homeTeamCells = useTeamCells(
        homeTeamAlias,
        homeTeamScores,
        homeTeamTotalScore,
        columnCount,
    );
    const awayTeamCells = useTeamCells(
        awayTeamAlias,
        awayTeamScores,
        awayTeamTotalScore,
        columnCount,
    );

    return (
        <Table data-testid="linescore-table" colorScheme={colorScheme}>
            <TableRow cells={labelCells} />
            <TableRow cells={awayTeamCells} />
            <TableRow cells={homeTeamCells} />
        </Table>
    );
};

export default LinescoreTable;
