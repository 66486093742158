import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div<{ mt?: number }>`
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;

    ${(props) => props.mt && `margin-top: ${props.mt}rem;`}
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: inline-block;
    border-top: 0.25rem solid var(--blue500);
    border-right: 0.25rem solid transparent;
    box-sizing: border-box;
    animation: ${spin} 1s linear infinite;
    position: relative;

    &:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border-left: 0.25rem solid var(--red500);
        border-bottom: 0.25rem solid transparent;
        animation: ${spin} 0.5s linear infinite reverse;
    }
`;
