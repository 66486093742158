import React from 'react';

import { Game } from 'api/types/bff';
import { LiveFlags } from 'api/types/live-flags';

import { Discrepancy } from '../types';

export default function useDiscrepancies(game: Game.LiveGame | undefined): Discrepancy[] {
    return React.useMemo(() => {
        if (!game) {
            return [];
        }

        return game.liveFlags.map((flag) => {
            const status =
                flag.status === LiveFlags.FlagStatus.AUTOMATICALLY_RESOLVED
                    ? LiveFlags.FlagStatus.RESOLVED
                    : flag.status;

            const hasGroup = flag.name.includes(' - ');
            const flagGroupName = hasGroup ? flag.name.split(' - ')[0] : null;
            const flagTypeName = hasGroup ? flag.name.split(' - ').slice(1).join(' - ') : flag.name;

            const teamQualifier = flag.contextSrIds.team === game.awayTeam?.srId ? 'away' : 'home';
            const team = teamQualifier === 'away' ? game.awayTeam : game.homeTeam;
            const teamAlias = team?.alias ?? null;

            return {
                id: flag.id,
                status,
                createdAt: new Date(flag.timestamp).toISOString(),
                actualValue: flag.externalValue,
                expectedValue: flag.srValue,
                context: flag.context,
                flagName: flag.name,
                flagGroupName,
                flagTypeName,
                teamId: flag.contextSrIds.team,
                teamName: flag.teamName,
                teamAlias,
                teamQualifier,
                playerName: flag.playerName,
            };
        });
    }, [game]);
}
