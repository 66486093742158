import styled from '@emotion/styled';

type LazyImageContainerProps = {
    isReady: boolean;
};

export const LazyImageContainer = styled.div<LazyImageContainerProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .fallback-container {
        display: ${({ isReady }) => (isReady ? 'none' : 'block')};
        width: 100%;
        height: 100%;

        * {
            width: 100%;
            height: 100%;
        }
    }

    & > img {
        display: ${({ isReady }) => (isReady ? 'block' : 'none')};
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
`;
