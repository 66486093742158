import * as React from 'react';

import Button from './components/button';
import Text from './components/text';
import { ControlBarContainer } from './styles';

const ControlBar = (props: React.ComponentProps<typeof ControlBarContainer>) => {
    return <ControlBarContainer {...props} />;
};

ControlBar.Button = Button;
ControlBar.Text = Text;

export default ControlBar;
