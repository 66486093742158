import * as React from 'react';

import { Conference, ConferenceBasic, Division, DivisionBasic } from 'api/types/leagues';
import { Option } from 'types/controls';

export function useLeagueHierarchyOptions<
    TGroup extends Division | DivisionBasic | Conference | ConferenceBasic,
>(groups?: TGroup[], currentAlias?: string): Option<TGroup | undefined>[] {
    return React.useMemo(
        () => [
            {
                label: 'All',
                value: undefined,
                selected: currentAlias === undefined,
            },
            ...(groups ?? []).map((group) => ({
                label: group.alias,
                value: group,
                selected: group.alias === currentAlias,
            })),
        ],

        [currentAlias, groups],
    );
}
