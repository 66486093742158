import { FlagContentInternal, VisibleContent } from 'components/flag-accordion/styles';
import FlagTeamImages from 'components/flag-team-images';
import { IconDecorated } from 'components/icons';

type Props = {
    awayTeamSrId?: string;
    homeTeamSrId?: string;
    text: string;
    ignoredCount: number;
    createdCount: number;
    isToggled: boolean;
    handleToggle: () => void;
};

const HeaderInternal = ({
    awayTeamSrId,
    homeTeamSrId,
    text,
    ignoredCount,
    createdCount,
    isToggled,
    handleToggle,
}: Props) => {
    return (
        <VisibleContent
            visible={isToggled}
            onClick={handleToggle}
            data-testid="flag-schedule-header-internal"
        >
            <FlagTeamImages awayTeamSrId={awayTeamSrId} homeTeamSrId={homeTeamSrId} />
            <FlagContentInternal>
                <div className="text">{text}</div>
                <div className="stats">
                    <div className="flagged">
                        <IconDecorated sizeRem={0.75} name="Flag" paletteColor="red600" />
                        <span>{createdCount}</span>
                    </div>
                    <div className="ignored">
                        <IconDecorated sizeRem={0.75} name="Texture" paletteColor="black300" />
                        <span>{ignoredCount}</span>
                    </div>
                </div>
            </FlagContentInternal>
        </VisibleContent>
    );
};

export default HeaderInternal;
