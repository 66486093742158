import { IconDecorated } from 'components/icons';
import SportradarImage from 'components/sr-logo';

type Props = {
    src: string;
};

const TeamImage = ({ src }: Props) => {
    return (
        <SportradarImage
            src={src}
            heightRem={3.125}
            placeholder={<IconDecorated name="GroupWorkRounded" sizeRem={3.125} />}
        />
    );
};

export default TeamImage;
