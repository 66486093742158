import styled from '@emotion/styled';

import { colours } from 'app/theme/color-scheme';

export const GameCardContainer = styled.div<{ isLiveGame: boolean }>`
    --border-color: ${(props) =>
        props.isLiveGame ? 'var(--green500)' : 'var(--color-borderline)'};
    --background-color: ${(props) =>
        props.isLiveGame ? 'rgba(var(--green500-rgb), 0.05)' : 'transparent'};

    display: flex;
    flex-direction: column;
    background-color: var(---background-color);
    border: 0.0625rem solid var(--border-color);
    padding: 1.5rem 0 0;

    & > .game-coverage {
        text-transform: capitalize;
        margin: 0 1.5rem 1.5rem;
    }

    & > .refresh-status {
        font-size: 0.75rem;
        text-align: right;
        padding: 0.625rem 1.5rem 0;
        min-height: 1.6rem;
    }
`;

export const Header = styled.div`
    display: flex;
    padding: 0 1.5rem;
    justify-content: space-between;

    .sequence {
        font-size: 0.75rem;
        margin-left: 0.5rem;
        font-weight: 700;
    }

    & > .actions {
        display: flex;
        gap: 0.5rem;
        cursor: pointer;
    }
`;

export const Subheader = styled.div`
    margin-top: 0.5rem;
    font-size: 0.75rem;
    padding: 0 1.5rem;
`;

export const TeamsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 3rem);
    font-size: 1.28rem;
    margin-top: 1.5rem;
    padding-bottom: 1.56rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    border-bottom: 0.0625rem solid var(--color-borderline);
    gap: 0.5rem;

    .game-date {
        font-size: 0.75rem;
        font-weight: 600;
    }
    .team-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .team-info {
            display: flex;
            align-items: center;

            .team-crest {
                width: 2rem;
                height: 1.5rem;
            }
        }
    }

    .team-alias {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-weight: 700;
        font-size: 1.125rem;
    }
`;

export const Score = styled.div<{ hasDiscrepancy?: boolean }>`
    padding: 0.25rem;
    width: 2.125rem;
    text-align: right;
    border-radius: 0.1875rem;
    font-size: 1.125rem;
    font-weight: 700;

    background-color: ${(props) =>
        props.hasDiscrepancy
            ? 'var(--color-game-card-score-bg-has-discrepancy)'
            : 'var(--color-game-card-score-bg)'};
`;

export const FlagsContainer = styled.div`
    display: flex;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    align-items: center;
    justify-content: center;
    border-bottom: 0.0625rem solid var(--color-borderline);
    padding-bottom: 0.5rem;

    & > .flag-count {
        font-weight: 700;
        font-size: 0.875rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
`;

export const FlagWrapper = styled.div<{ opacity: number; bgColor: keyof typeof colours }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--${(props) => props.bgColor}-rgb), ${(props) => props.opacity});
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;

    & > * {
        margin-bottom: 0.2rem;
    }
`;

export const ScoreboardContainer = styled.div`
    margin: 1.5rem;
    overflow-x: auto;
`;

export const ActionButtons = styled.div`
    display: flex;
    border-top: 0.0625rem solid var(--color-borderline);
    padding: 1rem;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
        white-space: nowrap;
    }
`;

export const CoverageWrapper = styled.div`
    width: min-content;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
`;
