import * as React from 'react';

import { GAME_STATUS_MAP, GameStatus } from 'api/types/leagues';
import { Option } from 'types/controls';

export const DEFAULT_OPTIONS: Option<GameStatus | undefined>[] = [
    { label: 'All', value: undefined },
    ...Object.values(GameStatus).map((gameStatus) => ({
        label: GAME_STATUS_MAP[gameStatus],
        value: gameStatus,
    })),
];

export function useGameStatusOptions(current?: GameStatus): Option<GameStatus | undefined>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: current === option.value,
            })),
        [current],
    );
}
