import * as React from 'react';

import { IconDecorated } from 'components/icons';

import { DiscrepancyType } from '../../../../api/types/bff';
import Discrepancy from '../../discrepancy';
import { FlagContainer, FlagContent, Source, VisibleContent } from '../../styles';
import FlagContext from '../context';
import { useTotalDiscrepanciesCount } from '../hooks/useTotalDiscrepanciesCount';
import { FlagProps } from '../types';

import { useFlagTitle } from './hooks/useFlagTitle';
import { AccordionContent } from './styles';

const FlagDefault = ({ flag }: FlagProps) => {
    const { activeDiscrepancies, isToggled, handleToggle } = React.useContext(FlagContext);
    const discrepancies: DiscrepancyType[] = activeDiscrepancies;
    const flagContextTitle = useFlagTitle(flag);
    const totalDiscrepancies = useTotalDiscrepanciesCount(flag);

    return (
        <FlagContainer data-testid="flag-container">
            <VisibleContent
                visible={isToggled}
                onClick={handleToggle}
                data-testid="flag-header-standard"
            >
                <IconDecorated
                    sizeRem={1}
                    name={isToggled ? 'KeyboardArrowUp' : 'Flag'}
                    paletteColor="red600"
                />
                <FlagContent>
                    <div className="flag-type">{flag.flagType.name}</div>
                    <div className="flag-context">{flagContextTitle}</div>
                    <div className="discrepancies">{totalDiscrepancies}</div>
                </FlagContent>
            </VisibleContent>
            <AccordionContent visible={isToggled} data-testid="accordion-content">
                <div className="discrepancies">
                    <div className="header">
                        <div className="context">
                            <span className="flag-type">SCHEDULE</span>
                            <span className="context-title">New Mexico State</span>
                        </div>
                        <div className="sources">
                            <Source active>
                                <span className="title">ncaa.org</span>
                                <span className="counter">({totalDiscrepancies})</span>
                            </Source>
                        </div>
                    </div>
                    <div className="list">
                        {discrepancies.map((discrepancy) => (
                            <Discrepancy
                                key={`dl-item-${discrepancy.id}`}
                                discrepancy={discrepancy}
                            />
                        ))}
                    </div>
                </div>
                <div className="links">
                    <span className="title">EXTERNAL LINKS</span>
                    <ul className="list">
                        <li>
                            <a href="https://whatever">LDE Tool</a>
                        </li>
                        <li>
                            <a href="https://whatever">NCAA Site</a>
                        </li>
                        <li>
                            API Feed <a href="https://whatever">XML</a> |{' '}
                            <a href="https://whatever">JSON</a>
                        </li>
                    </ul>
                </div>
            </AccordionContent>
        </FlagContainer>
    );
};

export default FlagDefault;
