import styled from '@emotion/styled';

type DiscrepancyClockLabelProps = {
    isMarked: boolean;
};

export const DiscrepancyClockLabel = styled.span<DiscrepancyClockLabelProps>`
    min-width: 5.0625rem;
    color: ${({ isMarked }) => (isMarked ? 'var(--red600)' : 'var(--black300)')};
    font-size: 0.75rem;
    font-weight: ${({ isMarked }) => (isMarked ? 700 : 400)};
    line-height: 0.875rem;
`;
