import useTeamCrest from 'app/hooks/useTeamCrest';
import { ReactComponent as IconShield } from 'assets/svg/icon-shield.svg';
import LazyImage from 'components/lazy-image';

type Props = {
    srGlobalId?: string | null;
};

const TeamImage = ({ srGlobalId }: Props) => {
    const teamCrest = useTeamCrest(srGlobalId);

    return <LazyImage fallback={<IconShield />} src={teamCrest} />;
};

export default TeamImage;
