import styled from '@emotion/styled';

export const GroupTaskWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    & > div,
    span {
        cursor: auto;
    }
`;

export const TaskContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    min-height: 8rem;

    & > .loading-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`;

export const TaskDetailsContainer = styled.div`
    display: grid;
    width: 100%;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    font-size: 0.875rem;

    & > div {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        &:last-child {
            justify-content: flex-end;
        }
    }

    & > a {
        text-decoration: none;
        color: var(--color-text-link);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;

        &:hover {
            color: var(--color-text-link-hover);
        }
    }

    & > .view-details {
        cursor: pointer;
    }
`;
