import * as React from 'react';

import { useCurrentSeason } from 'app/useCurrentSeason';

import { FlagStatsSummary } from './types';
import { useGetFlagStats } from './useGetFlagStats';

const useFlagStatsSummary = (): FlagStatsSummary => {
    const currentSeason = useCurrentSeason();

    const { data: flagStats = {}, isLoading } = useGetFlagStats(currentSeason);

    return React.useMemo(() => {
        const statsSummary: FlagStatsSummary = {
            all: { created: 0, ignored: 0 },
            boxscore: { created: 0, ignored: 0 },
            player: { created: 0, ignored: 0 },
            roster: { created: 0, ignored: 0 },
            schedule: { created: 0, ignored: 0 },
            seasonal: { created: 0, ignored: 0 },
            standings: { created: 0, ignored: 0 },
            polls: { created: 0, ignored: 0 },
        };

        if (isLoading || !flagStats) {
            return statsSummary;
        }

        for (const flagType of Object.keys(flagStats)) {
            for (const contextType of Object.keys(flagStats[flagType])) {
                for (const source of Object.keys(flagStats[flagType][contextType])) {
                    const currentCreated = flagStats[flagType][contextType][source]['CREATED'] ?? 0;
                    const currentIgnored = flagStats[flagType][contextType][source]['IGNORED'] ?? 0;

                    statsSummary.all.created += currentCreated;
                    statsSummary.all.ignored += currentIgnored;

                    if (statsSummary.hasOwnProperty(flagType)) {
                        statsSummary[flagType as keyof typeof statsSummary].created +=
                            currentCreated;
                        statsSummary[flagType as keyof typeof statsSummary].ignored +=
                            currentIgnored;
                    }
                }
            }
        }

        return statsSummary;
    }, [flagStats, isLoading]);
};

export default useFlagStatsSummary;
