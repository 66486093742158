import * as React from 'react';

import { LiveFlags } from 'api/types/live-flags';
import { Option } from 'types/controls';

const DEFAULT_OPTIONS: Option<LiveFlags.FlagStatus | undefined>[] = [
    { label: 'All', value: undefined },
    ...Object.values(LiveFlags.FlagStatus)
        .filter((status) => status !== LiveFlags.FlagStatus.RESOLVED)
        .map((status) => ({
            label: LiveFlags.FLAG_STATUS_MAP[status],
            value: status,
        })),
];

export function useLiveFlagStatusOptions(
    current?: LiveFlags.FlagStatus,
): Option<LiveFlags.FlagStatus | undefined>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: current === option.value,
            })),
        [current],
    );
}
