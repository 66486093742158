import { LeagueType, SourceId } from '../../types';

export type DiscrepancyType = {
    id: string;
    flagId: string;
    name: string;
    status: DiscrepancyStatus;
    createdAt: string;
    updatedAt: string;
    discrepancySources: DiscrepancySourceType[];
    contextType: string;
    contextTeamSrId: string | null;
    contextPlayerSrId: string | null;
    contextGameSrId: string | null;
    ignore: {
        reason: DiscrepancyIgnoreReason;
        comment: string | null;
    } | null;
};

export enum DiscrepancyIgnoreReason {
    CALCULATED_DIFFERENTLY = 'CALCULATED_DIFFERENTLY',
    NOT_IMPORTANT = 'NOT_IMPORTANT',
    TECHNICAL_ERROR = 'TECHNICAL_ERROR',
    OTHER = 'OTHER',
}

export type DiscrepancySourceType = {
    sourceId: SourceId;
    value: string;
};

export enum DiscrepancyStatus {
    CREATED = 'CREATED',
    RESOLVED = 'RESOLVED',
    IGNORED = 'IGNORED',
}

export type DiscrepancyStatusFilter = DiscrepancyStatus.CREATED | DiscrepancyStatus.IGNORED;

export function isDiscrepancyStatusFilter(status: string): status is DiscrepancyStatusFilter {
    return status === DiscrepancyStatus.CREATED || status === DiscrepancyStatus.IGNORED;
}

export type FlagType = {
    id: string;
    league: LeagueType;
    seasonYear: string;
    seasonType: string;
    contextSrId: string;
    week?: string;
    createdAt: string;
    flagType: FlagTypeType;
    discrepanciesSummary: {
        oldest: string;
        latest: string;
        createdCount: number;
        ignoredCount: number;
        resolvedCount: number;
    };
};

export type FlagTypeType = {
    name: string;
    key: string;
};

export type FlagsStats = { [status in DiscrepancyStatus]: number };

export type BulkUpdateDiscrepanciesRequest = {
    ids: Array<string>;
    status: DiscrepancyStatus;
    ignoreReason?: string;
    ignoreComment?: string;
};
