import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { SourceId } from 'types';

import { teamMappingsKeys } from './queries';

export enum MappingsType {
    Players = 'players',
    Schedules = 'schedules',
}

type SourceStats = {
    [source in SourceId]?: {
        autoMapped: number;
        autoUnmapped: number;
        manuallyMapped: number;
        manuallyUnmapped: number;
    };
};

export type MappingsStats = {
    [league: string]: {
        [mappingsType in MappingsType]: SourceStats;
    };
};

async function getMappinsStats(league: string, teamId: string) {
    const response = await client.get<MappingsStats>(
        `${API.MAPPINGS_API}/${league}/stats/${teamId}`,
    );

    return response?.data;
}

function useMappingStats(league: string, teamId: string) {
    return useQuery(
        teamMappingsKeys.teamMappingsStatsByLeagueTeamId(league, teamId),
        () => getMappinsStats(league, teamId),
        { refetchOnWindowFocus: false },
    );
}

export default useMappingStats;
