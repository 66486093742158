import * as React from 'react';

import ExternalLinks from 'components/external-links';
import { FlagContainer } from 'components/flag-accordion/styles';
import FlagSystemEvents from 'components/flag-system-events';
import Loading from 'components/loading';
import SourceSelector from 'components/source-selector';
import { ValuesBySource } from 'components/source-selector/types';
import { FlagType, flagTypeToContextType, SourceId } from 'types';

import { teamName } from '../../team-name';
import FlagContext from '../context';
import { AccordionContent } from '../default/styles';
import HeaderStandard from '../header-standard';
import { useTeamContext } from '../hooks/useTeamContext';
import { useTotalDiscrepanciesCount } from '../hooks/useTotalDiscrepanciesCount';
import { FlagContentContainer, Header, SourceSelectorContainer } from '../styles';
import { FlagProps } from '../types';

import DiscrepancyCategorized from './discrepancy-categorized';
import HeaderInternal from './header-internal';
import { useSplitDiscrepanciesByPlayerAndTeam } from './hooks/useSplitDiscrepanciesByPlayerAndTeam';

const FlagSeasonal = ({ flag, moduleHeader }: FlagProps) => {
    const {
        discrepancyBySources,
        activeSource,
        setActiveSource: handleSourceClick,
        activeDiscrepancies,
        areDiscrepanciesLoading,
        externalLinks,
        areExternalLinksLoading,
        isToggled,
        handleToggle,
    } = React.useContext(FlagContext);
    const splitDiscrepancies = useSplitDiscrepanciesByPlayerAndTeam(activeDiscrepancies);
    const sourceSelectorValues: ValuesBySource = discrepancyBySources
        .map((discrepancies) => ({
            source: discrepancies.source,
            value: discrepancies.discrepancies.length,
        }))
        .filter((value) => value.source !== SourceId.SR && value.value);

    const teamContext = useTeamContext(flag);
    const getFlagHeader = React.useCallback(() => {
        if (!teamContext) {
            return 'N/A';
        }

        return teamName(teamContext);
    }, [teamContext]);

    const totalDiscrepancies = useTotalDiscrepanciesCount(flag);
    const ignoredCount = flag.discrepanciesSummary.ignoredCount;
    const flaggedCount = flag.discrepanciesSummary.createdCount;

    return (
        <FlagContainer data-testid="flag-seasonal-container">
            {moduleHeader ? (
                <HeaderInternal
                    teamName={getFlagHeader()}
                    srTeamId="123"
                    ignoredCount={ignoredCount}
                    createdCount={flaggedCount}
                    isToggled={isToggled}
                    handleToggle={handleToggle}
                />
            ) : (
                <HeaderStandard
                    text={getFlagHeader()}
                    handleToggle={handleToggle}
                    isToggled={isToggled}
                    iconName="QueryBuilderRounded"
                    iconColor="blue1000"
                    flagType={flag.flagType.name}
                    count={totalDiscrepancies}
                />
            )}
            <AccordionContent visible={isToggled} data-testid="accordion-content">
                <FlagContentContainer>
                    <Header>
                        <ExternalLinks
                            isLoading={areExternalLinksLoading}
                            linksInfo={externalLinks}
                        />
                        <FlagSystemEvents
                            league={flag.league}
                            seasonYear={flag.seasonYear}
                            seasonType={flag.seasonType}
                            context={flagTypeToContextType(flag.flagType.key as FlagType)}
                            contextObjectId={flag.context.srId}
                        />
                    </Header>
                    {areDiscrepanciesLoading ? (
                        <Loading mt={2} />
                    ) : (
                        <>
                            <SourceSelectorContainer>
                                <SourceSelector
                                    valuesBySource={sourceSelectorValues}
                                    onSourceClick={handleSourceClick}
                                    activeSource={activeSource}
                                />
                            </SourceSelectorContainer>
                            <hr className="line" />
                            <DiscrepancyCategorized
                                title="Team Statistics"
                                discrepancies={splitDiscrepancies.team}
                                type="team"
                            />
                            <DiscrepancyCategorized
                                title="Player Statistics"
                                discrepancies={splitDiscrepancies.player}
                                type="player"
                            />
                        </>
                    )}
                </FlagContentContainer>
            </AccordionContent>
        </FlagContainer>
    );
};

export default FlagSeasonal;
