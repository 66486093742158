import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { LiveFlags } from 'api/types/live-flags';

import { LeagueKeys } from './queries';

async function getLiveFlagCategories(
    league: string,
    source: string,
): Promise<LiveFlags.LiveFlagCategory[]> {
    const urlSearchParams = new URLSearchParams({ source });
    const response = await client.get(
        `${API.LIVE_FLAGS_API}/${league}/flag-categories?${urlSearchParams}`,
    );

    return response?.data;
}

export function useLiveFlagCategories(league: string, source: string) {
    return useQuery(
        LeagueKeys.liveFlagCategories(league, source),
        () => getLiveFlagCategories(league, source),
        {
            refetchOnWindowFocus: false,
            staleTime: 60 * 60 * 1000, // 1h
            cacheTime: 2 * 60 * 60 * 1000, // 2h
        },
    );
}
