import styled from '@emotion/styled';

export const Name = styled.div`
    color: var(--color-team-mappings-card-attribute-name);
`;

export const Value = styled.div`
    color: var(--color-team-mappings-card-attribute-value);
    white-space: nowrap;
`;

export const AttributesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1.0625rem;

    & > ${Name}, ${Value} {
        font-size: 0.875rem;
        font-weight: 400;
        padding-top: 0.5rem;
    }
`;
