import { StatusCodes } from 'http-status-codes';
import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import { BulkUpdateDiscrepanciesRequest } from 'api/types/flags';
import { useCurrentSeason } from 'app/useCurrentSeason';

export default function useBulkUpdateDiscrepanciesState() {
    const queryClient = useQueryClient();
    const { league } = useCurrentSeason();

    return useMutation(
        async (discrepancy: BulkUpdateDiscrepanciesRequest) => {
            return client.patch(`${API.FLAGS_API}/discrepancy`, discrepancy);
        },
        {
            onSuccess: (data) => {
                if (data.status === StatusCodes.OK) {
                    queryClient.invalidateQueries([league, 'flagStats']);
                    queryClient.invalidateQueries([league, 'flags']);
                }
            },
            onError: async (error) => {
                console.error(error);
            },
            retry: 2,
        },
    );
}
