import * as React from 'react';
import { Route } from 'type-route';

import { groups, routes } from 'app/router';
import LeagueDropdown from 'components/league-dropdown';
import useLeagueOptions from 'hooks/options/league/useLeagueOptions';
import useSeasonOptions from 'hooks/options/league/useSeasonOptions';
import useSeasonTypeOptions from 'hooks/options/league/useSeasonTypeOptions';

import { LeagueControlsContainer } from './styles';

type Props = {
    route: Route<typeof groups.leagueFull>;
};

const LeagueControls = ({ route: { params } }: Props) => {
    const [leagueDetails, leagueDropdownControls] = useLeagueOptions(params.league);
    const [season, seasonDropdownControls] = useSeasonOptions(
        leagueDetails.seasons,
        params.seasonYear,
    );
    const [seasonType, seasonTypeDropdownControls] = useSeasonTypeOptions(
        season.seasonTypes,
        params.seasonType,
    );

    // Those ref are required for navigation working correctly.
    // Without them, after opening a page with target: "_blank",
    // it would be redirected to league dashboard.
    const leagueDetailsRef = React.useRef(leagueDetails);
    const seasonRef = React.useRef(season);
    const seasonTypeRef = React.useRef(seasonType);

    React.useEffect(() => {
        if (
            leagueDetailsRef.current.league !== leagueDetails.league ||
            seasonRef.current.seasonYear !== season.seasonYear ||
            seasonTypeRef.current !== seasonType
        ) {
            routes
                .dashboard({
                    league: leagueDetails.league,
                    seasonType,
                    seasonYear: season.seasonYear,
                })
                .push();
            leagueDetailsRef.current = leagueDetails;
            seasonRef.current = season;
            seasonTypeRef.current = seasonType;
        }
    }, [params, leagueDetails, season, seasonType]);

    return (
        <LeagueControlsContainer>
            <LeagueDropdown {...leagueDropdownControls} />
            <LeagueDropdown {...seasonDropdownControls} />
            <LeagueDropdown {...seasonTypeDropdownControls} />
        </LeagueControlsContainer>
    );
};

export default LeagueControls;
