import * as React from 'react';
import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { Game } from 'api/types/bff';
import { GameStatus } from 'api/types/leagues';
import { SourceId } from 'types';

import { GetGameQueryParams } from '../types';

import { GameKeys } from './queries';

const GAME_REFETCH_INTERVAL_MS = 1000 * 60;

const getGame = async (
    id: string,
    league: string,
    source: SourceId,
    qp: GetGameQueryParams,
): Promise<Game.LiveGame> => {
    if (qp.context === 'individual') {
        qp.context = 'player';
    }

    const queryParams = new URLSearchParams(qp);
    const response = await client.get<Game.LiveGame>(
        `${API.BFF_API}/${league}/games/${id}/source/${source}?${queryParams}`,
    );

    return response?.data;
};

export default function useLiveGame(
    id: string,
    league: string,
    source: SourceId,
    qp: GetGameQueryParams,
) {
    const [refetchInterval, setRefetchInterval] = React.useState<number | false>(
        GAME_REFETCH_INTERVAL_MS,
    );

    const queryParams = {
        ...qp,
    };

    if (queryParams.status === 'all') {
        delete queryParams.status;
    }

    if (queryParams.context === 'all') {
        delete queryParams.context;
    }

    if (queryParams.statName === 'all') {
        delete queryParams.statName;
    }

    return useQuery(
        GameKeys.game(league, id, source, qp),
        () => getGame(id, league, source, queryParams),
        {
            refetchOnWindowFocus: false,
            refetchInterval,
            onSuccess: (liveGame) => {
                if (
                    liveGame.status === GameStatus.Cancelled ||
                    liveGame.status === GameStatus.Closed
                ) {
                    setRefetchInterval(false);
                }
            },
        },
    );
}
