import * as React from 'react';

import { LeagueType } from 'types';

const URL = `https://admin.{league}.production.sapi.use1.srsmt.io/game/{gameId}/show.html`;
const LEAGUE_MAP: Partial<Record<LeagueType, string>> = {
    cfb: 'ncaafb',
    ncaafb: 'ncaafb',
};

export function useAdminLink(league: LeagueType): React.EventHandler<React.MouseEvent> | undefined {
    const handleClick = React.useCallback(
        (event) => {
            const srusGameId = event.target.getAttribute('data-game-srus-id');
            const adminLeague = LEAGUE_MAP[league];

            if (!srusGameId || !adminLeague) {
                throw new Error('Game ID or League is missing.');
            }

            window.open(URL.replace('{league}', adminLeague).replace('{gameId}', srusGameId));
        },
        [league],
    );

    return Object.keys(LEAGUE_MAP).includes(league) ? handleClick : undefined;
}
