import * as React from 'react';

import leaguesDetails from 'constants/leagues-details';
import useOptions from 'hooks/options/useOptions';
import { LeagueDetails, LeagueType } from 'types';
import { Option } from 'types/controls';

function isEqual(a: LeagueDetails, b: LeagueDetails): boolean {
    return a.league === b.league;
}

function getOptions(routeLeague: LeagueType): Option<LeagueDetails>[] {
    if (!leaguesDetails.map(({ league }) => league).includes(routeLeague)) {
        throw new Error(`(useLeagueOptions::getOptions) Invalid league: ${routeLeague}`);
    }

    return leaguesDetails.map((leagueDetails) => ({
        label: leagueDetails.nameAbbreviation,
        selected: leagueDetails.league === routeLeague,
        value: leagueDetails,
    }));
}

export default function useLeagueOptions(routeLeague: LeagueType) {
    const options = React.useMemo(() => getOptions(routeLeague), [routeLeague]);

    return useOptions(options, isEqual);
}
