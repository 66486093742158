import { DiscrepancyStatus } from 'api/types/flags';
import { LeagueType, SeasonType, SeasonYear } from 'types';
import { Order } from 'types/sorting';

export type GetFlagStatsParameters = {
    contextObjectId?: string;
    league: LeagueType;
    seasonType: SeasonType;
    seasonYear: SeasonYear;
};

export type GetFlagsParameters = {
    conferenceAlias?: string;
    contextObjectId?: string;
    dateFrom?: string;
    dateTo?: string;
    discrepancyStatus?: DiscrepancyStatus;
    divisionAlias?: string;
    flagType?: string;
    league: LeagueType;
    seasonType: SeasonType;
    seasonYear: SeasonYear;
    sortBy?: string;
    sortOrder?: Order;
    week?: string;
    pollsWeek?: string;
};

export type GetFlagDiscrepanciesParameters = {
    flagId: string;
    league: LeagueType;
    seasonYear: SeasonYear;
    seasonType: SeasonType;
    status?: string;
};

export const FlagKeys = {
    getFlagStats: ({ league, contextObjectId, ...parameters }: GetFlagStatsParameters) => {
        return [league, 'flagStats', contextObjectId, parameters];
    },
    getFlags: ({ league, contextObjectId, ...parameters }: GetFlagsParameters) => {
        return [league, 'flags', contextObjectId, parameters];
    },
    getFlagDiscrepancies: ({ league, flagId, ...parameters }: GetFlagDiscrepanciesParameters) => {
        return [league, 'flags', flagId, 'discrepancies', parameters];
    },
};
