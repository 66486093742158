import * as React from 'react';

import { useCurrentSeason } from 'app/useCurrentSeason';
import { StatValues } from 'hooks/useFlagStats/types';
import { useGetFlagStats } from 'hooks/useFlagStats/useGetFlagStats';

export default function useTabFlagStats(teamSrusId: string, flagTypes: string[]): StatValues {
    const currentSeason = useCurrentSeason();
    const { data: flagStats, isLoading } = useGetFlagStats({
        ...currentSeason,
        contextObjectId: teamSrusId,
    });

    return React.useMemo(() => {
        const stats: StatValues = {
            created: 0,
            ignored: 0,
        };

        if (isLoading || !flagStats) {
            return stats;
        }

        const sourceStats = flagTypes.flatMap((flagType) =>
            Object.values(flagStats[flagType]['team']),
        );

        sourceStats.forEach((source) => {
            stats.created += source['CREATED'] ?? 0;
            stats.ignored += source['IGNORED'] ?? 0;
        });

        return stats;
    }, [flagStats, flagTypes, isLoading]);
}
