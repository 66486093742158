import * as React from 'react';

import { StatValues } from 'hooks/useFlagStats/types';
import { useGetFlagStats } from 'hooks/useFlagStats/useGetFlagStats';
import { LeagueType, SeasonType, SeasonYear } from 'types';

type GameFlagStatsParameters = {
    gameId: string;
    league: LeagueType;
    seasonYear: SeasonYear;
    seasonType: SeasonType;
};

export function useGameFlagStats(parameters: GameFlagStatsParameters): StatValues {
    const flagStatsQuery = useGetFlagStats({
        contextObjectId: parameters.gameId,
        league: parameters.league,
        seasonType: parameters.seasonType,
        seasonYear: parameters.seasonYear,
    });

    return React.useMemo(() => {
        return Object.values(flagStatsQuery.data?.boxscore ?? {})
            .flatMap(Object.values)
            .reduce<StatValues>(
                (statValues, source) => ({
                    created: statValues.created + source['CREATED'] ?? 0,
                    ignored: statValues.ignored + source['IGNORED'] ?? 0,
                }),
                { created: 0, ignored: 0 },
            );
    }, [flagStatsQuery.data?.boxscore]);
}
