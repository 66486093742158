import styled from '@emotion/styled';

export const AccountPlaceHolder = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    font-family: Calistoga, Roboto, serif;
    font-size: 0.875rem;
    color: var(--white);
    user-select: none;

    & > .loading-container {
        display: flex;
        height: 2rem;

        justify-content: flex-start;

        & > div {
            transform: scale(50%) translateY(-70%);
        }
    }

    &:hover {
        color: var(--color-text-link);
    }
`;

export const AccountDropdownContainer = styled.div`
    display: flex;
    background-color: var(--color-background);
    color: var(--color-text);
    font-size: 0.875rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    & > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 10rem;
        list-style-type: none;
        padding: 0;
        margin: 0;
        gap: 1rem;

        li {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        a {
            text-decoration: none;
            color: var(--color-text);

            &:hover,
            &:focus,
            &:active {
                color: var(--color-text-link);
            }

            &:visited {
                color: var(--color-text);
            }
        }
    }
`;
