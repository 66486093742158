import styled from '@emotion/styled';

export const TeamFlagsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & .repull {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }
`;
