import * as React from 'react';
import { Route } from 'type-route';

import { groups, routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';
import { FlagType } from 'types';

import { TeamNavigationStats } from '../../hooks/useNavigationStats';

interface Props {
    navigationStats?: TeamNavigationStats;
    route: Route<typeof groups.leagueTeamsTeam>;
}

const Navigation = ({ navigationStats, route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);

    const handleStatsClick = React.useCallback(
        () =>
            routes
                .teamsTeam({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: route.params.id,
                })
                .push(),
        [route.params],
    );
    const handleSeasonalClick = React.useCallback(
        () =>
            routes
                .teamsTeamSeasonal({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: route.params.id,
                })
                .push(),
        [route.params],
    );
    const handleRosterClick = React.useCallback(
        () =>
            routes
                .teamsTeamRoster({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: route.params.id,
                })
                .push(),
        [route.params],
    );
    const handleScheduleClick = React.useCallback(
        () =>
            routes
                .teamsTeamSchedule({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: route.params.id,
                })
                .push(),
        [route.params],
    );
    const handleStandingsClick = React.useCallback(
        () =>
            routes
                .teamsTeamStandings({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: route.params.id,
                })
                .push(),
        [route.params],
    );
    const handlePollsClick = React.useCallback(() => {
        routes
            .teamsTeamPolls({
                league: route.params.league,
                seasonType: route.params.seasonType,
                seasonYear: route.params.seasonYear,
                id: route.params.id,
            })
            .push();
    }, [route.params]);
    const handleMappingsClick = React.useCallback(
        () =>
            routes
                .teamsTeamMapping({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    id: route.params.id,
                })
                .push(),
        [route.params],
    );

    return (
        <TabMenu>
            {leagueDetails.flagTypes?.includes(FlagType.Boxscore) && (
                <TabButton
                    data-testid="stats-tab"
                    onClick={handleStatsClick}
                    active={route.name === 'teamsTeam'}
                    dotted={(navigationStats?.stats ?? 0) > 0}
                >
                    Stats
                </TabButton>
            )}
            {leagueDetails.flagTypes?.includes(FlagType.Seasonal) && (
                <TabButton
                    data-testid="seasonal-tab"
                    onClick={handleSeasonalClick}
                    active={route.name === 'teamsTeamSeasonal'}
                    dotted={(navigationStats?.seasonal ?? 0) > 0}
                >
                    Seasonal
                </TabButton>
            )}
            {leagueDetails.flagTypes?.includes(FlagType.Roster) && (
                <TabButton
                    data-testid="roster-tab"
                    onClick={handleRosterClick}
                    active={route.name === 'teamsTeamRoster'}
                    dotted={(navigationStats?.roster ?? 0) > 0}
                >
                    Roster
                </TabButton>
            )}
            {leagueDetails.flagTypes?.includes(FlagType.Schedule) && (
                <TabButton
                    data-testid="schedule-tab"
                    onClick={handleScheduleClick}
                    active={route.name === 'teamsTeamSchedule'}
                    dotted={(navigationStats?.schedule ?? 0) > 0}
                >
                    Schedule
                </TabButton>
            )}
            {leagueDetails.flagTypes?.includes(FlagType.Standings) && (
                <TabButton
                    data-testid="standings-tab"
                    onClick={handleStandingsClick}
                    active={route.name === 'teamsTeamStandings'}
                    dotted={(navigationStats?.standings ?? 0) > 0}
                >
                    Standings
                </TabButton>
            )}
            {leagueDetails.flagTypes?.includes(FlagType.Polls) && (
                <TabButton
                    data-testid="polls-tab"
                    onClick={handlePollsClick}
                    active={route.name === 'teamsTeamPolls'}
                    dotted={(navigationStats?.polls ?? 0) > 0}
                >
                    Polls
                </TabButton>
            )}
            <TabButton
                data-testid="mapping-tab"
                onClick={handleMappingsClick}
                active={route.name === 'teamsTeamMapping'}
                dotted={(navigationStats?.mapping ?? 0) > 0}
            >
                Mapping
            </TabButton>
        </TabMenu>
    );
};

export default Navigation;
