import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TabButtonContainer = styled.div<{ active?: boolean; dotted?: boolean }>`
    display: inline-block;
    padding-bottom: 0.5rem;
    line-height: 1rem;
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
    border-bottom-style: solid;
    border-bottom-width: 0.1875rem;
    border-bottom-color: transparent;

    &:first-of-type {
        padding-left: 0;
    }
    &:hover {
        color: var(--color-button);
        border-bottom-color: var(--color-button);
    }

    ${(props) =>
        props.active &&
        css`
            color: var(--color-button);
            border-bottom-color: var(--color-button);
        `}

    ${({ dotted, active }) =>
        dotted &&
        !active &&
        css`
            padding-right: 0.625rem;

            ::after {
                content: '';
                width: 0.375rem;
                height: 0.375rem;
                background-color: var(--red600);
                position: absolute;
                margin-left: 0.25rem;
                border-radius: 50%;
            }
        `}
`;
