import '@fontsource/roboto';
import '@fontsource/calistoga';
import './App.css';
import MonitoringApp from './app/monitoring-app';
import { Providers } from './app/providers';
import { GlobalStyles } from './app/theme/global-styles';
import auth from './auth/config';
import SportsMediaAuthProvider from './auth/sports-media-auth-provider';

const App = () => (
    <Providers>
        <SportsMediaAuthProvider clientId={auth.clientId} cognitoUrl={auth.cognitoUrl}>
            <GlobalStyles />
            <MonitoringApp />
        </SportsMediaAuthProvider>
    </Providers>
);

export default App;
