import React from 'react';

import TeamImage from 'components/team-image';

import { FlagTeamImagesContainer } from './styles';

type Props = {
    awayTeamSrId?: string;
    homeTeamSrId?: string;
};

const FlagTeamImages = ({ awayTeamSrId, homeTeamSrId }: Props) => {
    return (
        <FlagTeamImagesContainer>
            <div className="image-container">
                <TeamImage srGlobalId={awayTeamSrId} />
            </div>
            <span className="divider">@</span>
            <div className="image-container">
                <TeamImage srGlobalId={homeTeamSrId} />
            </div>
        </FlagTeamImagesContainer>
    );
};

export default FlagTeamImages;
