import * as React from 'react';

function useTimeDifference(initialDate: string): number {
    const [difference, setDifference] = React.useState(0);

    const handleClockTick = React.useCallback(() => {
        setDifference(new Date(Date.now()).getTime() - new Date(initialDate).getTime());
    }, [initialDate]);

    React.useEffect(handleClockTick, [handleClockTick]);

    React.useEffect(() => {
        const interval = setInterval(handleClockTick, 1000);

        return () => clearInterval(interval);
    }, [handleClockTick]);

    return difference;
}

export default useTimeDifference;
