import {
    Popover as MuiPopover,
    PopoverOrigin,
    PopoverPosition,
    PopoverReference,
} from '@mui/material';
import { ReactNode, useCallback, useRef, useState } from 'react';

import { PopoverContainer } from './styles';

export interface PopoverProps {
    className?: string;
    placeholder: ReactNode;
    children: (onClose: () => void) => ReactNode;
    openOnHover?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    anchorReference?: PopoverReference;
    anchorPosition?: PopoverPosition;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}

const Popover = ({
    children,
    placeholder,
    openOnHover,
    onOpen,
    onClose,
    anchorReference,
    anchorPosition,
    anchorOrigin,
    transformOrigin,
    ...props
}: PopoverProps) => {
    const [open, setOpen] = useState(false);
    const placeholderRef = useRef(null);

    const handleOnClick = useCallback(() => {
        if (!openOnHover && !open) {
            setOpen(true);
            onOpen && onOpen();
        }
    }, [onOpen, openOnHover, open]);

    const handleOnMouseEnter = useCallback(() => {
        if (openOnHover && !open) {
            setOpen(true);
            onOpen && onOpen();
        }
    }, [onOpen, openOnHover, open]);
    const handleOnClose = useCallback(() => {
        if (!openOnHover && open) {
            setOpen(false);
            onClose && onClose();
        }
    }, [onClose, openOnHover, open]);
    const handleOnMouseLeave = useCallback(() => {
        if (openOnHover && open) {
            setOpen(false);
            onClose && onClose();
        }
    }, [onClose, openOnHover, open]);

    return (
        <>
            <PopoverContainer
                ref={placeholderRef}
                onClick={handleOnClick}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                {...props}
            >
                {placeholder}
            </PopoverContainer>
            <MuiPopover
                open={open}
                onClose={handleOnClose}
                anchorEl={placeholderRef.current}
                anchorReference={anchorReference}
                anchorPosition={anchorPosition}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                sx={{
                    pointerEvents: openOnHover ? 'none' : 'auto',
                }}
                PaperProps={{ sx: { background: 'transparent' } }}
            >
                {children(handleOnClose)}
            </MuiPopover>
        </>
    );
};

export default Popover;
