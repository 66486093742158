import leaguesDetails from 'constants/leagues-details';

import LeagueItem from './components/league-item';
import { HomeContainer, ListContainer } from './styles';

const Home = () => {
    return (
        <HomeContainer>
            <ListContainer>
                <div className="list-title">Choose a League</div>
                <div className="list">
                    {leaguesDetails.map((leagueDetails) => (
                        <LeagueItem key={leagueDetails.league} leagueDetails={leagueDetails} />
                    ))}
                </div>
            </ListContainer>
        </HomeContainer>
    );
};

export default Home;
