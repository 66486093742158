import { useMemo } from 'react';

import { Games } from 'api/types/bff';

export type GamesStats = {
    flagsCreated: number;
    flagsIgnored: number;
};

export function useGamesStats(games: Games.Game[]) {
    return useMemo(() => {
        return games.reduce<GamesStats>(
            (accumulator, game) => ({
                flagsCreated: accumulator.flagsCreated + (game.flagsCreated ?? 0),
                flagsIgnored: accumulator.flagsIgnored + (game.flagsIgnored ?? 0),
            }),
            { flagsIgnored: 0, flagsCreated: 0 },
        );
    }, [games]);
}
