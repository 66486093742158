import React from 'react';

import { DiscrepancyType, ExternalLink } from 'api/types/bff';
import { SourceId } from 'types';

import { DiscrepanciesBySource } from './types';

type FlagContextType = {
    activeSource: SourceId | null;
    setActiveSource: (source: SourceId) => void;
    activeDiscrepancies: DiscrepancyType[];
    discrepancyBySources: DiscrepanciesBySource[];
    areDiscrepanciesLoading: boolean;
    externalLinks: ExternalLink[];
    areExternalLinksLoading: boolean;
    isToggled: boolean;
    handleToggle: () => void;
};

const flagContextState: FlagContextType = {
    activeSource: null,
    setActiveSource: () => {},
    activeDiscrepancies: [],
    discrepancyBySources: [],
    externalLinks: [],
    areExternalLinksLoading: false,
    isToggled: false,
    handleToggle: () => undefined,
    areDiscrepanciesLoading: false,
};

const FlagContext = React.createContext<FlagContextType>(flagContextState);

export default FlagContext;
