import { Route } from 'type-route';

import { groups } from '../../app/router';

import TeamView from './team/views';
import TeamMappingView from './team/views/mapping';
import TeamsView from './teams';

type Props = {
    route: Route<typeof groups.leagueTeamsFull>;
};

const Teams = ({ route }: Props) => {
    return (
        <>
            {route.name === 'teams' && <TeamsView route={route} />}
            {route.name === 'teamsMappings' && <div>TEAMS MAPPINGS NOT IMPLEMENTED</div>}
            {groups.leagueTeamsTeamStatistics.has(route) && <TeamView route={route} />}
            {route.name === 'teamsTeamMapping' && <TeamMappingView route={route} />}
        </>
    );
};

export default Teams;
