import styled from '@emotion/styled';

export const TaskDetailModalContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;
    flex-direction: column;

    & .meta-info {
        display: flex;
        gap: 0.5rem;
    }

    & > .json {
        width: 100%;
        display: flex;
        flex: 1;
        max-height: 80vh;
        overflow-y: auto;
    }
`;
