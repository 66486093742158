import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useCallback,
    useState,
} from 'react';

export const ColorSchemeConfigValues = ['dark', 'light', 'system'] as const;
export type ColorSchemeConfig = (typeof ColorSchemeConfigValues)[number];
const ColourSchemeContext = createContext<[ColorSchemeConfig, (c: ColorSchemeConfig) => void]>(
    undefined!,
);

export const setTheme = (theme: ColorSchemeConfig) => {
    document.documentElement.dataset.colorSchemeConfig = theme;
};

export const ColorSchemeConfigProvider = ({ children }: PropsWithChildren<{}>) => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

    const [config, setConfig] = useState<ColorSchemeConfig>(darkThemeMq.matches ? 'dark' : 'light');

    const handleMqChange = useCallback(
        (e) => {
            setConfig(e.matches ? 'dark' : 'light');
        },
        [setConfig],
    );

    useEffect(() => {
        setTheme(config);
    }, [config]);

    useEffect(() => {
        darkThemeMq.addEventListener('change', handleMqChange);

        return () => {
            darkThemeMq.removeEventListener('change', handleMqChange);
        };
    }, [darkThemeMq, handleMqChange]);

    return (
        <ColourSchemeContext.Provider value={[config, setConfig]}>
            {children}
        </ColourSchemeContext.Provider>
    );
};

export const useColorSchemeConfig = () => useContext(ColourSchemeContext);
