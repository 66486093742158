export enum Coverage {
    Full = 'full',
    Boxscore = 'boxscore',
    ExtendedBoxscore = 'extended_boxscore',
}

export function isCoverage(coverage: string): coverage is Coverage {
    return Object.values(Coverage).includes(coverage as Coverage);
}

export const coverageMap: Record<Coverage, string> = {
    [Coverage.Full]: 'Full',
    [Coverage.Boxscore]: 'Boxscore',
    [Coverage.ExtendedBoxscore]: 'Extended Boxscore',
};
