import { ReactEventHandler, useCallback, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ClipLoader from 'react-spinners/ClipLoader';

import { SrLogoContainer } from './styles';

type Props = {
    className?: string;
    placeholder?: JSX.Element;
    heightRem?: number;
    widthRem?: number;
    src: string;
};

const SportradarImage = ({ className, placeholder, heightRem, src, widthRem }: Props) => {
    const [imageExists, setImageExists] = useState(true);

    const handleLoad: ReactEventHandler<HTMLImageElement> = useCallback((e) => {
        const target = e.target as HTMLImageElement;

        if (
            !target.naturalHeight ||
            target.naturalHeight <= 1 ||
            !target.naturalWidth ||
            target.naturalWidth <= 1
        ) {
            setImageExists(false);
        }
    }, []);

    return (
        <SrLogoContainer className={className} heightRem={heightRem} widthRem={widthRem}>
            {imageExists ? (
                <LazyLoadImage
                    className="image"
                    src={src}
                    placeholder={
                        <ClipLoader
                            size={heightRem ? `${heightRem}rem` : undefined}
                            cssOverride={{
                                borderColor: 'var(--color-text) var(--color-text) transparent ',
                            }}
                        />
                    }
                    onLoad={handleLoad}
                />
            ) : (
                placeholder
            )}
        </SrLogoContainer>
    );
};

export default SportradarImage;
