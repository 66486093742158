import * as React from 'react';
import { useSessionStorage } from 'usehooks-ts';

interface Identifiable {
    id: string;
}

export function useFavouriteGames<T extends Identifiable>(
    games: T[],
): [(game: T) => void, T[], (game: T) => boolean] {
    const [favouriteGames, setFavouriteGames] = useSessionStorage<T[]>(
        'weekly-favourite-games',
        games,
    );

    const isFavorite = React.useCallback(
        (game: T) => {
            return favouriteGames.findIndex((fav) => fav.id === game.id) !== -1;
        },
        [favouriteGames],
    );

    const toggleFavouriteGame = React.useCallback(
        (game: T) => {
            setFavouriteGames((prevFavGames) => {
                if (isFavorite(game)) {
                    return prevFavGames.filter((prevFavGame) => prevFavGame.id !== game.id);
                }

                return [game, ...prevFavGames];
            });
        },
        [setFavouriteGames, isFavorite],
    );

    const sortedGames = React.useMemo(() => {
        return games.sort((a, b) => {
            const isAFavorite = favouriteGames.findIndex((fav) => fav.id === a.id) !== -1;
            const isBFavorite = favouriteGames.findIndex((fav) => fav.id === b.id) !== -1;

            if ((isAFavorite && isBFavorite) || (!isAFavorite && !isBFavorite)) {
                return 0;
            }

            if (isAFavorite) {
                return -1;
            }

            return 1;
        });
    }, [favouriteGames, games]);

    return [toggleFavouriteGame, sortedGames, isFavorite];
}
