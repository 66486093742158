import { CognitoIdentityCredentials } from '@aws-sdk/credential-provider-cognito-identity/dist-types/fromCognitoIdentity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { auth } from 'aws-iot-device-sdk-v2';

import { AWSCognitoCredentialOptions } from './types';

// the code below is a workaround for the missing CredentialsProvider in the aws-iot-device-sdk-v2, ts doesn't detect the export correctly
const authModule = auth as unknown as { CredentialsProvider: any };
const CredentialsProvider = authModule?.CredentialsProvider || class {};

export class AWSCognitoCredentialsProvider extends CredentialsProvider {
    private options: AWSCognitoCredentialOptions;
    private cachedCredentials?: CognitoIdentityCredentials;

    constructor(options: AWSCognitoCredentialOptions) {
        super();
        this.options = options;
    }

    getCredentials() {
        return {
            aws_access_id: this.cachedCredentials?.accessKeyId ?? '',
            aws_secret_key: this.cachedCredentials?.secretAccessKey ?? '',
            aws_sts_token: this.cachedCredentials?.sessionToken,
            aws_region: this.options.Region,
        };
    }

    async refreshCredentials() {
        try {
            this.cachedCredentials = await fromCognitoIdentityPool({
                // Required. The unique identifier for the identity pool from which an identity should be
                // retrieved or generated.
                identityPoolId: this.options.IdentityPoolId,
                clientConfig: {
                    region: this.options.Region,
                },
            })();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
