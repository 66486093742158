import { JsonEditor } from 'json-edit-react';
import React from 'react';

import Modal from 'components/modal';

import { useColorSchemeConfig } from '../../../../app/theme/useColorScheme';
import Chip from '../../../chip';
import { DialogButtons } from '../../../dialog/styles';
import Loading from '../../../loading';
import Button from '../../../primitives/button';
import { Task } from '../../hooks/useGroupTasks';
import useSystemEvent from '../../hooks/useSystemEvent';

import { TaskDetailModalContainer } from './styles';

type Props = {
    open: boolean;
    onClose: () => void;
    task: Task | null;
    loading: boolean;
};

const TaskDetailModal = (props: Props) => {
    const { isLoading: loadingSystemEvent, data } = useSystemEvent(props.task?.pk, props.task?.sk);
    const [colorScheme] = useColorSchemeConfig();

    const systemEvent = data;

    return (
        <Modal open={props.open} onBackDropClick={props.onClose} zIndex={1400}>
            <TaskDetailModalContainer>
                <div className="meta-info">
                    <Chip variant="warningPrimary">task: {props.task?.taskId}</Chip>
                    <Chip variant="successPrimary">pk: {props.task?.pk}</Chip>
                    <Chip variant="warningSecondary">sk: {props.task?.sk}</Chip>
                </div>
                {(loadingSystemEvent || props.loading) && <Loading />}
                {systemEvent?.detailsJson && (
                    <div className="json">
                        <JsonEditor
                            minWidth="100%"
                            rootName="details"
                            theme={colorScheme === 'dark' ? 'githubDark' : 'githubLight'}
                            restrictAdd={true}
                            restrictDelete={true}
                            restrictDrag={true}
                            restrictEdit={true}
                            data={systemEvent.detailsJson}
                        />
                    </div>
                )}
                <DialogButtons>
                    <Button
                        noBorder
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onClose();
                        }}
                    >
                        Close
                    </Button>
                </DialogButtons>
            </TaskDetailModalContainer>
        </Modal>
    );
};

export default TaskDetailModal;
