import * as React from 'react';

export default function usePrevious<T>(previousValue: T) {
    const [value, setValue] = React.useState(previousValue);

    React.useEffect(() => {
        if (previousValue !== undefined) {
            setValue(previousValue);
        }
    }, [previousValue]);

    return value;
}
