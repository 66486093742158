import styled from '@emotion/styled';

export const ViewContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ContentContainer = styled.div<{ dense?: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    background: var(--bg-content-top-right);
    background-size: 10%;

    & > .inner-container {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        padding: 1.5rem;

        ${(props) => !props.dense && 'max-width: 64rem;'}
        width: 100%;
    }
`;
