import * as React from 'react';
import { Route } from 'type-route';

import { groups } from 'app/router';

const FLAG_TYPES_MAP: Record<Route<typeof groups.leagueTeamsTeamStatistics>['name'], string[]> = {
    teamsTeam: ['boxscore'],
    teamsTeamRoster: ['roster'],
    teamsTeamSeasonal: ['seasonal'],
    teamsTeamSchedule: ['schedule'],
    teamsTeamStandings: ['standings'],
    teamsTeamPolls: ['polls'],
};

export function useRouteFlagTypes(
    routeName: Route<typeof groups.leagueTeamsTeamStatistics>['name'],
): string[] {
    return React.useMemo(() => FLAG_TYPES_MAP[routeName], [routeName]);
}
