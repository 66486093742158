import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';

import { GameView } from '../../types';

type Props = {
    gameView: GameView;
    onChange: (gameView: GameView) => void;
};

const GameViewTabs = ({ gameView, onChange }: Props) => {
    return (
        <TabMenu>
            <TabButton
                active={gameView === GameView.LIVE_GAME}
                onClick={() => onChange(GameView.LIVE_GAME)}
            >
                Live Game
            </TabButton>
            <TabButton
                active={gameView === GameView.POST_GAME}
                onClick={() => onChange(GameView.POST_GAME)}
            >
                Post Game
            </TabButton>
        </TabMenu>
    );
};

export default GameViewTabs;
