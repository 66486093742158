import styled from '@emotion/styled';

export const Button = styled.span`
    cursor: pointer;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 0.75rem;
`;
