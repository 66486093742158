import * as React from 'react';

import { Option } from 'types/controls';
import { Order, Sorting } from 'types/sorting';

export enum MappingSortingAttribute {
    Name = 'name',
}

export function isMappingSortingAttribute(
    attribute: unknown,
): attribute is MappingSortingAttribute {
    return Object.values(MappingSortingAttribute).includes(attribute as MappingSortingAttribute);
}

const DEFAULT_OPTIONS: Option<Sorting<MappingSortingAttribute>>[] = [
    {
        label: 'ALPHABETICAL (A-Z)',
        value: { attribute: MappingSortingAttribute.Name, order: Order.Asc },
    },
    {
        label: 'ALPHABETICAL (Z-A)',
        value: { attribute: MappingSortingAttribute.Name, order: Order.Desc },
    },
];

export function useMappingSortingOptions(
    current: Sorting<MappingSortingAttribute>,
): Option<Sorting<MappingSortingAttribute>>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected:
                    option.value.attribute === current?.attribute &&
                    option.value.order === current?.order,
            })),
        [current],
    );
}
