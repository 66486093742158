import { useForm } from 'react-hook-form';

import { DialogButtons } from 'components/dialog/styles';
import Modal from 'components/modal';
import Button from 'components/primitives/button';

import { DirectLinkURLs } from '../../types';

import { DirectLinksFormContainer, DirectLinksFormField } from './styles';

const AWAY_URL = 'awayUrl';
const HOME_URL = 'homeUrl';

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: (urls: DirectLinkURLs) => void;
    urls: DirectLinkURLs;
};

const DirectLinksForm = ({ open, onClose, onSubmit, urls: { awayUrl, homeUrl } }: Props) => {
    const { register, handleSubmit, reset } = useForm<DirectLinkURLs>({
        defaultValues: {
            awayUrl,
            homeUrl,
        },
    });

    const handleCancel = () => {
        reset();
        onClose();
    };

    return (
        <Modal
            open={open}
            onBackDropClick={onClose}
            style={{ minWidth: '15.5rem', padding: '1.5rem' }}
        >
            <DirectLinksFormContainer onSubmit={handleSubmit(onSubmit)}>
                <span className="title">Sources: Home & Away</span>
                <div className="fields">
                    <DirectLinksFormField>
                        <label htmlFor={AWAY_URL}>Away URL</label>
                        <input id={AWAY_URL} {...register(AWAY_URL)} />
                    </DirectLinksFormField>
                    <DirectLinksFormField>
                        <label htmlFor={HOME_URL}>Home URL</label>
                        <input id={HOME_URL} {...register(HOME_URL)} />
                    </DirectLinksFormField>
                </div>
                <DialogButtons>
                    <Button onClick={handleCancel} noBorder>
                        Cancel
                    </Button>
                    <Button className="submit" type="submit">
                        Save Changes
                    </Button>
                </DialogButtons>
            </DirectLinksFormContainer>
        </Modal>
    );
};

export default DirectLinksForm;
