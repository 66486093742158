import styled from '@emotion/styled';

import { mq } from 'app/theme/breakpoints';

export const Summary = styled.div`
    width: 100%;
    padding-top: 1.1875rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;

    & > .content-layout {
        position: relative;
        display: flex;
        column-gap: 1.5rem;
        align-items: flex-end;

        ${mq('lg')} {
            align-items: center;
        }
    }
`;

export const CurrentStatus = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.4375rem;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    & > .game-date {
        position: absolute;
        top: -1.1875rem;
        display: block;
        text-align: center;
        min-width: max-content;
    }
`;

export const LogoContainer = styled.div`
    overflow: hidden;
    border-radius: 50%;
    min-width: 3rem;
    max-width: 3rem;
    height: 3rem;

    &:has(img) {
        background-color: var(--white);
    }

    &,
    & .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 100%;
        height: 100%;
        /**
        /* By default, all icons have some padding. This transform, makes the GroupWorkRounded icon
        /* large enought to cover the container entirely. 
        */
        transform: scale(1.21);
    }

    img {
        width: 60%;
        height: 60%;
        object-fit: cover;
    }

    & > .image-container {
        width: 100%;
        height: 100%;
    }

    ${mq('lg')} {
        min-width: 3.8125rem;
        max-width: 3.8125rem;
        height: 3.8125rem;
    }
`;

type TeamSectionContainerProps = {
    isHome: boolean;
};

export const TeamSectionContainer = styled.div<TeamSectionContainerProps>`
    --flex-direction: ${({ isHome }) => (isHome ? 'row-reverse' : 'row')};
    --text-align: ${({ isHome }) => (isHome ? 'left' : 'right')};

    overflow: hidden;
    flex-grow: 1;
    flex-basis: min-content;
    flex-direction: var(--flex-direction);
    display: flex;
    align-items: flex-end;
    align-self: stretch;
    column-gap: 1rem;

    & .team-image-container {
        flex-grow: 1;
    }

    & .team-details {
        display: flex;
        align-items: center;
        row-gap: 0.5rem;
        column-gap: 1rem;
        flex-direction: column;
        align-self: stretch;
        justify-content: space-between;
    }

    & .team-name {
        text-align: center;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.125rem;
    }

    & > .score {
        font-size: 2.5rem;
        line-height: 2.9375rem;
    }

    ${mq('lg')} {
        align-items: center;

        & .team-details {
            flex-direction: var(--flex-direction);
        }

        & .team-name {
            text-align: var(--text-align);
        }

        & .team-image-container {
            flex-grow: 0;
        }

        & .team-details,
        & .team-name {
            flex-grow: 1;
        }
    }
`;
