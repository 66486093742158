import styled from '@emotion/styled';

export const TeamContainer = styled.div`
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.0625rem solid var(--color-flags-accordion-borderline);
`;

export const TeamContent = styled.a`
    display: grid;
    width: 100%;
    font-size: 0.75rem;
    grid-template-columns: 1fr 10fr 1fr 1fr 1fr;
    align-items: center;
    text-decoration: none;
    color: var(--color-text);

    & > .logo {
        margin: auto;
    }

    &:visited,
    &:active {
        color: var(--color-text);
    }

    &:hover {
        opacity: 0.7;
    }
`;
