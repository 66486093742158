import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { useDebounce, useIntersectionObserver } from 'usehooks-ts';

import { Admin } from 'api/types/bff';
import { GameStatus } from 'api/types/leagues';
import { useColorSchemeConfig } from 'app/theme/useColorScheme';
import { useCurrentSeason } from 'app/useCurrentSeason';
import Button from 'components/button';
import Chip from 'components/chip';
import FlagSystemEvents from 'components/flag-system-events';
import GameStatusChip from 'components/game-status-chip';
import { IconDecorated } from 'components/icons';
import LinescoreTable from 'components/linescore-table';
import { ColorScheme } from 'components/linescore-table/styles';
import TeamImage from 'components/team-image';
import useAdminScheduleMutation from 'hooks/useAdminScheduleMutation';
import useGameFlagStats from 'hooks/useGameFlagStats';
import { ContextType } from 'types';

import { GameCardProps } from '../types';

import {
    ActionButtons,
    CoverageWrapper,
    FlagsContainer,
    FlagWrapper,
    GameCardContainer,
    Header,
    Score,
    ScoreboardContainer,
    Subheader,
    TeamsContainer,
} from './styles';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

type Props = GameCardProps & {
    minColumnCount: number;
};

const BasicGameCard = ({
    id,
    awayTeamGlobalId,
    awayTeamName,
    awayTeamAlias,
    homeTeamGlobalId,
    homeTeamName,
    homeTeamAlias,
    sequenceInfo,
    scheduledDate,
    coverage,
    status,
    homeTeamScores,
    homeTeamTotalScore,
    awayTeamScores,
    awayTeamTotalScore,
    ballPossessedBy = 'none',
    subtitle = '',
    flagsCreatedCount = 0,
    flagsIgnoredCount = 0,
    isFavorite = false,
    minColumnCount,
    onMonitorStatsClick,
    onAdminClick,
    onLDEClick,
    onToggleFavoriteGame,
}: Props) => {
    const ref = React.useRef<HTMLDivElement | null>(null);

    const entry = useIntersectionObserver(ref, {});

    const isVisible = !!entry?.isIntersecting;
    const isVisibleDebounce = useDebounce(isVisible, 4000);

    const [theme] = useColorSchemeConfig();
    const discrepancyInAwayTeamScore = !!awayTeamTotalScore?.isMarked;
    const discrepancyInHomeTeamScore = !!homeTeamTotalScore?.isMarked;

    const { data: gameFlagStats } = useGameFlagStats({
        gameId: id,
        enabled: isVisibleDebounce && status === GameStatus.InProgress,
    });

    const { league, seasonYear, seasonType } = useCurrentSeason();

    const gameParams = React.useMemo(
        () => ({
            league,
            seasonYear,
            seasonType,
            scheduleType: Admin.ScheduleTypes.Game,
            id,
        }),
        [id, league, seasonType, seasonYear],
    );

    const adminScheduleMutation = useAdminScheduleMutation();

    const handleGameScheduleRefresh = React.useCallback(() => {
        adminScheduleMutation.mutate(gameParams);
    }, [adminScheduleMutation, gameParams]);

    if (gameFlagStats && Object.keys(gameFlagStats).length) {
        const { CREATED, IGNORED } = gameFlagStats[id].statusCount;

        flagsCreatedCount = CREATED;
        flagsIgnoredCount = IGNORED;
    }

    return (
        <GameCardContainer
            ref={ref}
            isLiveGame={status === GameStatus.InProgress}
            data-testid={'game-card'}
        >
            <Header>
                <div>
                    <GameStatusChip status={status} />
                    <span className="sequence">{sequenceInfo}</span>
                </div>
                <div className="actions">
                    <IconDecorated
                        sizeRem={1.25}
                        name="Refresh"
                        onClick={handleGameScheduleRefresh}
                        data-testid="game-refresh"
                    />
                    <IconDecorated
                        data-testid={`favorite-game-icon-${id}`}
                        sizeRem={1.25}
                        name={isFavorite ? 'Star' : 'StarBorder'}
                        onClick={onToggleFavoriteGame}
                    />
                    <FlagSystemEvents
                        league={league}
                        seasonYear={seasonYear}
                        seasonType={seasonType}
                        contextObjectId={id}
                        context={ContextType.Boxscore}
                    />
                </div>
            </Header>
            <div className="refresh-status"></div>
            <Subheader>{subtitle}</Subheader>
            <TeamsContainer>
                <div className="game-date">
                    {dayjs(scheduledDate).format('ddd, MMM D YYYY h:mm A z')}
                </div>
                <div className="team-row">
                    <div className="team-info">
                        <div className="team-crest">
                            <TeamImage srGlobalId={awayTeamGlobalId} />
                        </div>
                        <span className="team-alias" data-testid="away-team-alias">
                            {awayTeamName}
                        </span>
                        {ballPossessedBy === 'away' && (
                            <IconDecorated
                                data-test-id="ball-possession-away"
                                sizeRem={1.3}
                                name="SportsFootballRounded"
                                paletteColor="blue700"
                            />
                        )}
                    </div>
                    <Score data-testid="away-score" hasDiscrepancy={discrepancyInAwayTeamScore}>
                        {awayTeamTotalScore?.value ?? '--'}
                    </Score>
                </div>
                <div className="team-row">
                    <div className="team-info">
                        <div className="team-crest">
                            <TeamImage srGlobalId={homeTeamGlobalId} />
                        </div>
                        <span className="team-alias" data-testid="home-team-alias">
                            {homeTeamName}
                        </span>
                        {ballPossessedBy === 'home' && (
                            <IconDecorated
                                data-test-id="ball-possession-home"
                                sizeRem={1.3}
                                name="SportsFootballRounded"
                                paletteColor="blue700"
                            />
                        )}
                    </div>
                    <Score data-testid="home-score" hasDiscrepancy={discrepancyInHomeTeamScore}>
                        {homeTeamTotalScore?.value ?? '--'}
                    </Score>
                </div>
            </TeamsContainer>
            <FlagsContainer>
                <FlagWrapper opacity={0.05} bgColor="red600">
                    <IconDecorated sizeRem={0.825} name="Flag" paletteColor="red600" />
                </FlagWrapper>
                <span className="flag-count">{flagsCreatedCount}</span>
                <FlagWrapper opacity={0.1} bgColor="black300">
                    <IconDecorated sizeRem={0.825} name="Texture" paletteColor="black300" />
                </FlagWrapper>
                <span className="flag-count">{flagsIgnoredCount}</span>
            </FlagsContainer>
            <ScoreboardContainer>
                <LinescoreTable
                    homeTeamTotalScore={homeTeamTotalScore}
                    awayTeamTotalScore={awayTeamTotalScore}
                    homeTeamScores={homeTeamScores}
                    awayTeamScores={awayTeamScores}
                    awayTeamAlias={awayTeamAlias}
                    homeTeamAlias={homeTeamAlias}
                    minColumnCount={minColumnCount}
                    colorScheme={theme as unknown as ColorScheme} // this is due to "system" present in state we need to change it
                />
            </ScoreboardContainer>
            <CoverageWrapper>
                <Chip variant="successPrimary">Coverage: {coverage}</Chip>
            </CoverageWrapper>
            <ActionButtons>
                {onAdminClick && (
                    <Button
                        variant="dark-primary"
                        data-game-srus-id={id}
                        data-testid="btn-admin"
                        onClick={onAdminClick}
                    >
                        Admin
                    </Button>
                )}
                {onLDEClick && (
                    <Button
                        variant="dark-primary"
                        data-game-srus-id={id}
                        data-testid="btn-lde"
                        onClick={onLDEClick}
                    >
                        LDE
                    </Button>
                )}
                <Button
                    variant="variant5"
                    data-game-srus-id={id}
                    data-testid="btn-monitor-stats"
                    onClick={onMonitorStatsClick}
                >
                    Monitor Stats
                </Button>
            </ActionButtons>
        </GameCardContainer>
    );
};

export default BasicGameCard;
