import iconShield from 'assets/svg/icon-shield.svg';
import { IconDecorated } from 'components/icons';

import { FlagContentInternal, VisibleContent } from '../../styles';

import { TeamCrestPlaceholder } from './styles';

interface HeaderInternalProps {
    teamName: string;
    srTeamId: string;
    ignoredCount: number;
    createdCount: number;
    isToggled: boolean;
    handleToggle: () => void;
}

const HeaderInternal = ({
    teamName,
    ignoredCount,
    createdCount,
    isToggled,
    handleToggle,
}: HeaderInternalProps) => {
    return (
        <VisibleContent
            visible={isToggled}
            onClick={handleToggle}
            data-testid="flag-seasonal-header-internal"
        >
            {isToggled ? (
                <IconDecorated sizeRem={1.5} name="KeyboardArrowUp" paletteColor="gray300" />
            ) : (
                <TeamCrestPlaceholder alt="team-crest" src={iconShield} />
            )}

            <FlagContentInternal>
                <div className="team-name">{teamName}</div>
                <div className="stats">
                    <div className="flagged">
                        <IconDecorated sizeRem={0.75} name="Flag" paletteColor="red600" />
                        <span>{createdCount}</span>
                    </div>
                    <div className="ignored">
                        <IconDecorated sizeRem={0.75} name="Texture" paletteColor="black300" />
                        <span>{ignoredCount}</span>
                    </div>
                </div>
            </FlagContentInternal>
        </VisibleContent>
    );
};

export default HeaderInternal;
