import styled from '@emotion/styled';

import { DialogButtons } from 'components/dialog/styles';

export const DirectLinksFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    font-weight: 700;

    & .title {
        font-size: 1.125rem;
        font-weight: 700;

        color: var(--color-direct-links-form-title);
    }

    & .fields {
        display: flex;
        flex-direction: column;
        gap: 1.125rem;
    }

    & ${DialogButtons} {
        & > button {
            color: var(--color-direct-links-form-button-label);
        }
        & > .submit {
            border-color: var(--color-direct-links-form-button-border);
        }
    }
`;

export const DirectLinksFormField = styled.div`
    & label {
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1rem;

        color: var(--color-direct-links-form-url-label);
    }

    & input {
        margin-top: 0.25rem;
        padding: 0.6875rem 0.625rem;
        width: 100%;
        border-radius: 0.2rem;
        background-color: transparent;
        border: 0.0625rem solid var(--color-direct-links-form-url-border);

        font-size: 0.75rem;
        line-height: 1rem;

        color: var(--color-direct-links-form-url-text);
    }
`;
