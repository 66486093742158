export default class Env {
    public static get(key: string, defaultValue?: string): string | never {
        const value = process.env[key];

        if (!value && defaultValue) {
            console.warn(
                '(Env::get) Environment variable is missing. Using default value. Key: %s',
                key,
            );

            return defaultValue;
        }

        if (!value) {
            console.warn('(Env::get) Environment variable is missing. Key: %s', key);

            throw new Error(`Environment variable is missing: ${key}`);
        }

        return value;
    }
}
