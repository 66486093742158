import * as React from 'react';
import { Route } from 'type-route';

import { FlagsSortingAttribute } from 'api/types/bff';
import { DiscrepancyStatusFilter } from 'api/types/flags';
import { Conference, Division } from 'api/types/leagues';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ControlBar from 'components/control-bar';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import FlagAccordion from 'components/flag-accordion';
import FlagStats from 'components/flag-stats';
import { IconDecorated } from 'components/icons';
import View from 'components/view';
import ViewHeader from 'components/view-header';
import { useDiscrepancyStatusOptions } from 'hooks/options/useDiscrepancyStatusOptions';
import { useFlagsSortingOptions } from 'hooks/options/useFlagsSortingOptions';
import { useLeagueHierarchyOptions } from 'hooks/options/useLeagueHierarchyOptions';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import useDivisions from 'hooks/useDivisions';
import useFlags from 'hooks/useFlagStats/useFlags';
import useFlagStatsSummary from 'hooks/useFlagStats/useGetFlagStatsSummary';
import { Sorting } from 'types/sorting';

type Props = {
    route: Route<typeof routes.rosters>;
};

const Rosters = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);

    const statusOptions = useDiscrepancyStatusOptions(route.params.status);

    const divisionsQuery = useDivisions(
        { league: route.params.league },
        { enabled: leagueDetails.filters.leagueHierarchy },
    );
    const divisionOptions = useLeagueHierarchyOptions(divisionsQuery.data, route.params.division);
    const conferenceOptions = useLeagueHierarchyOptions(
        divisionsQuery.data?.find(({ alias }) => alias === route.params.division)?.conferences,
        route.params.conference,
    );

    const sortingOptions = useFlagsSortingOptions(
        [
            FlagsSortingAttribute.DiscrepancyDate,
            FlagsSortingAttribute.DiscrepancyCount,
            FlagsSortingAttribute.DisplayName,
        ],
        { attribute: route.params.sortBy, order: route.params.sortOrder },
    );

    const flagsQuery = useFlags({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        flagType: 'roster',
        discrepancyStatus: route.params.status,
        sortBy: route.params.sortBy,
        sortOrder: route.params.sortOrder,
        ...(leagueDetails.filters.leagueHierarchy && {
            divisionAlias: route.params.division,
            conferenceAlias: route.params.conference,
        }),
    });
    const flagStatsSummary = useFlagStatsSummary();

    const updateDate = useDateSnapshot(flagsQuery.dataUpdatedAt);

    const handleParamsChange = useChangeParams(route);

    const handleSortingChange = React.useCallback(
        (sorting: Sorting<FlagsSortingAttribute>) =>
            handleParamsChange({ sortBy: sorting.attribute, sortOrder: sorting.order }),
        [handleParamsChange],
    );
    const handleDivisionChange = React.useCallback(
        (division?: Division) =>
            handleParamsChange({ division: division?.alias, conference: undefined }),
        [handleParamsChange],
    );
    const handleConferenceChange = React.useCallback(
        (conference?: Conference) => handleParamsChange({ conference: conference?.alias }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status: DiscrepancyStatusFilter) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    return (
        <View route={route}>
            <ViewHeader
                iconElement={
                    <IconDecorated paletteColor="blue400" sizeRem={1.5} name="PersonRounded" />
                }
                iconFormat="inline"
                titleText="Rosters"
            >
                <ControlBar spacing="small">
                    <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                    <ControlBar.Button>Repull</ControlBar.Button>
                </ControlBar>
            </ViewHeader>
            <FlagStats
                flagged={flagStatsSummary.roster.created}
                ignored={flagStatsSummary.roster.ignored}
            />
            <ControlPanel>
                <ControlPanel.Group>
                    <Dropdown
                        label="Sort"
                        options={sortingOptions}
                        onChange={handleSortingChange}
                    />
                    {leagueDetails.filters.leagueHierarchy && (
                        <React.Fragment>
                            <Dropdown
                                label="Division"
                                options={divisionOptions}
                                onChange={handleDivisionChange}
                            />
                            <Dropdown
                                label="Conference"
                                options={conferenceOptions}
                                onChange={handleConferenceChange}
                            />
                        </React.Fragment>
                    )}
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        onChange={handleStatusChange}
                    />
                </ControlPanel.Group>
            </ControlPanel>
            <FlagAccordion
                loading={flagsQuery.isLoading}
                flags={flagsQuery.data}
                status={route.params.status}
            />
        </View>
    );
};

export default Rosters;
