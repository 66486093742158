import styled from '@emotion/styled';

export const DropdownPlaceholder = styled.div`
    display: flex;
    place-items: center;
    border-radius: 0.1875rem;
    height: 1.5rem;
    background-color: var(--white);
    padding: 0 0.125rem 0 0.5rem;
    color: var(--black500);

    .dropdown-label {
        font-weight: 700;
        font-size: 0.75rem;
    }

    .dropdown-icon-container {
        // Even if perfectly aligned, the icon seems to be a few pixel to low. This fixes it.
        transform: translateY(-3%);
    }
`;

export const DropdownOptionsContainer = styled.div<{
    placeholderWidth?: number;
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    border-radius: 0.1875rem;
    border: 0.0625rem solid var(--black200);
    background-color: var(--white);
    padding: 0.3125rem 0.5rem;
    font-size: 0.625rem;
    ${(props) => props?.placeholderWidth && `min-width: ${props.placeholderWidth / 16}rem;`}

    & > .option-item {
        color: var(--black500);
        font-size: 0.75rem;
        cursor: pointer;
    }
`;
