import Modal, { ModalProps } from '../modal';
import Button from '../primitives/button';

import { DialogContent, DialogTitle, DialogContainer, DialogButtons } from './styles';

export interface DialogProps extends ModalProps {
    title?: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const Dialog = ({
    open,
    onBackDropClick,
    className,
    children,
    title,
    confirmButtonLabel = 'Confirm',
    cancelButtonLabel = 'Cancel',
    onCancel,
    onConfirm,
    ...rest
}: DialogProps) => {
    return (
        <Modal open={open} onBackDropClick={onBackDropClick} className={className}>
            <DialogContainer {...rest}>
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent>{children}</DialogContent>
                <DialogButtons>
                    <Button data-testid="cancel-button" onMouseDown={onCancel} noBorder>
                        {cancelButtonLabel}
                    </Button>
                    <Button data-testid="confirm-button" onMouseDown={onConfirm}>
                        {confirmButtonLabel}
                    </Button>
                </DialogButtons>
            </DialogContainer>
        </Modal>
    );
};

export default Dialog;
