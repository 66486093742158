import styled from '@emotion/styled';

export const Header = styled.div`
    border: 0.0625rem solid var(--blue500);
    border-radius: 0.5rem;
    min-width: fit-content;
    width: 100%;
    background-color: var(--blue700);
    padding: 1.5rem;
    color: var(--blue200);

    & > .inner-container {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 51.0625rem;

        & > .divider {
            margin: 1rem 0;
            width: 100%;
            height: 0.0625rem;
            background-color: var(--blue500);
        }

        & > .game-properties-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

export const GameOverviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2.125rem;

    & > .linescore-table-container[data-has-overtime='true'] {
        min-width: 11rem;
        max-width: 11rem;
        overflow-y: auto;
    }
`;
