import * as React from 'react';

import useTeamsFlagStatsBySource from 'hooks/useFlagStats/team/useTeamsFlagStatsBySource';
import { ExternalSourceId } from 'types';

export function useTeamsSourceSelector(sources: ExternalSourceId[]) {
    const teamsFlagStats = useTeamsFlagStatsBySource();

    return React.useMemo(
        () =>
            sources.map((source) => ({
                source,
                value:
                    (teamsFlagStats[source]?.created ?? 0) + (teamsFlagStats[source]?.ignored ?? 0),
            })),
        [teamsFlagStats, sources],
    );
}
