import { useMutation } from 'react-query';

import API from 'api';
import client from 'api/client';
import { LiveFlags } from 'api/types/live-flags';
import { useCurrentSeason } from 'app/useCurrentSeason';

export default function useUpdateDiscrepancyState() {
    const { league } = useCurrentSeason();

    return useMutation(async (discrepancy: LiveFlags.DiscrepancyStatusUpdateRequest) => {
        return client.patch(`${API.BFF_API}/${league}/games/discrepancy`, discrepancy);
    });
}
