import * as React from 'react';
import { Route } from 'type-route';

import { groups, routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';

export function useGoBackToTeams(route: Route<typeof groups.leagueTeamsTeam>) {
    const leagueDetails = useLeagueDetails(route.params.league);

    return React.useCallback(
        () =>
            routes
                .teams({
                    league: route.params.league,
                    seasonYear: route.params.seasonYear,
                    seasonType: route.params.seasonType,
                    source: leagueDetails.sources[0],
                })
                .push(),
        [route.params, leagueDetails],
    );
}
