import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Team } from 'api/types/leagues';
import { SourceId } from 'types';

import { Mapping } from '../../hooks/useMappingsMutate';
import { Player, Schedule, TeamMappings } from '../../hooks/useTeamMappings';
import { SubmitDialog } from '../mapping/dialog/submit';
import PlayerMapping from '../mapping/row/players';
import ScheduleMapping from '../mapping/row/schedules';

import { MappingsContainer } from './styles';

export type MappingState = {
    players: Record<string, Player>;
    schedules: Record<string, Schedule>;
};

export type MappingUpdates = {
    players: Mapping[];
    schedules: Mapping[];
};

type Props = {
    team: Team;
    allMappings?: TeamMappings;
    selectedMappings?: TeamMappings;
    onSubmit: (updates: MappingUpdates) => void;
};

const createDefaultState = ({ players, schedules }: TeamMappings): MappingState => {
    return {
        players: players.reduce((acc, item) => {
            acc[item.id] = { ...item };

            return acc;
        }, {} as Record<string, Player>),
        schedules: schedules.reduce((acc, item) => {
            acc[item.id] = { ...item };

            return acc;
        }, {} as Record<string, Schedule>),
    };
};

const MappingList = ({
    team,
    onSubmit,
    allMappings = { players: [], schedules: [] },
    selectedMappings = { players: [], schedules: [] },
}: Props) => {
    const formOptions = useForm<MappingState>({
        defaultValues: createDefaultState(allMappings),
    });

    const {
        reset,
        control,
        handleSubmit,
        formState: { isDirty, dirtyFields },
    } = formOptions;

    const processSubmitData = (data: MappingState): void => {
        const createMapping = (item: { id: string; mapped: string | null; source: SourceId }) =>
            item.source === SourceId.SR
                ? { id: item.mapped as string, srId: item.id }
                : { id: item.id, srId: item.mapped };

        reset(createDefaultState(allMappings));

        onSubmit({
            players: Object.keys(dirtyFields?.players ?? {}).map((id) =>
                createMapping(data.players[id]),
            ),
            schedules: Object.keys(dirtyFields?.schedules ?? {}).map((id) =>
                createMapping(data.schedules[id]),
            ),
        });
    };

    return (
        <FormProvider {...formOptions}>
            <form onSubmit={handleSubmit(processSubmitData)}>
                <MappingsContainer>
                    {[
                        ...selectedMappings.schedules.map(({ id }) => (
                            <Controller
                                key={id}
                                name={`schedules.${id}`}
                                control={control}
                                rules={{ required: false }}
                                render={({ field: { value, onChange } }) => (
                                    <ScheduleMapping
                                        key={`schedule-${id}`}
                                        team={team}
                                        schedule={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        )),
                        ...selectedMappings.players.map(({ id }) => (
                            <Controller
                                key={id}
                                name={`players.${id}`}
                                control={control}
                                rules={{ required: false }}
                                render={({ field: { value, onChange } }) => (
                                    <PlayerMapping
                                        key={`player-${id}`}
                                        player={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        )),
                    ]}
                </MappingsContainer>
                {isDirty && Object.keys(dirtyFields).length > 0 && (
                    <SubmitDialog
                        onCancel={() => {
                            reset(createDefaultState(allMappings));
                        }}
                        count={
                            Object.keys(dirtyFields.players ?? {}).length +
                            Object.keys(dirtyFields.schedules ?? {}).length
                        }
                    />
                )}
            </form>
        </FormProvider>
    );
};

export default MappingList;
