import styled from '@emotion/styled';

export const AccordionContent = styled.div<{ visible: boolean }>`
    display: ${(props) => (props.visible ? 'flex' : 'none')};

    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 3rem;
    flex-direction: column;

    & > .discrepancies {
        display: flex;
        flex: 2 1 65%;
        flex-direction: column;
        font-size: 0.875rem;

        & > .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 6rem;
            padding-right: 1rem;
            & > .context {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                & > .flag-type {
                    color: var(--color-text-link);
                    font-size: 0.625rem;
                }

                & > .context-title {
                    color: var(--color-text-link);
                    font-weight: bold;
                    font-size: 0.7rem;
                }
            }

            & > .sources {
                display: flex;
                gap: 0.5rem;
                align-items: end;
            }
        }

        & > .list {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-top: 1rem;
            padding-right: 1rem;
        }
    }

    & > .links {
        flex: 1 2 35%;
        border-left: 0.0625rem solid var(--color-flags-accordion-borderline);
        padding-left: 2rem;
        font-size: 0.875rem;

        & > .title {
            font-size: 0.625rem;
            font-weight: bold;
        }

        & > .list {
            list-style: none;
            margin-left: 0;
            padding-left: 0;

            li {
                color: var(--blue500);
                &::before {
                    content: '• ';
                    color: var(--blue500);
                }

                :not(:last-child) {
                    margin-bottom: 0.2rem;
                }

                a {
                    color: var(--blue500);
                    text-decoration: none;
                }
            }
        }
    }
`;
