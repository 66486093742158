import * as React from 'react';

import useTimeDifference from '../../hooks/useTimeDifference';

import { DiscrepancyClockLabel } from './styles';

type Props = {
    createdAt: string;
};

const DiscrepancyClock = ({ createdAt }: Props) => {
    const difference = useTimeDifference(createdAt);

    const [differenceLabel, setDifferenceLabel] = React.useState('Just now');
    const [isMarked, setIsMarked] = React.useState(false);

    React.useEffect(() => {
        const minuteDifference = Math.floor(difference / 60000);

        if (minuteDifference > 0) {
            setDifferenceLabel(`${minuteDifference} min ago`);
        }

        if (minuteDifference >= 30) {
            setIsMarked(true);
        }
    }, [difference]);

    return <DiscrepancyClockLabel isMarked={isMarked}>{differenceLabel}</DiscrepancyClockLabel>;
};

export default DiscrepancyClock;
