import * as React from 'react';

import { FourZeroFour } from 'components/error-page/FourZeroFour';
import Header from 'components/header';
import Route from 'components/route';
import Boxscore from 'modules/game-boxscores';
import Home from 'modules/home';
import Polls from 'modules/polls';
import Rosters from 'modules/rosters';
import Schedule from 'modules/schedule';
import Seasonal from 'modules/seasonal';
import Standings from 'modules/standings';
import Teams from 'modules/teams';

import FullScreenLoading from '../components/loading-page/fullscreen-loading';
import WsProvider from '../ws/ws-provider';

import { groups } from './router';
import { AppContainer } from './styles';

const Dashboard = React.lazy(() => import('modules/dashboard'));
const Admin = React.lazy(() => import('modules/admin/admin'));

const AuthenticatedApp = () => {
    return (
        <WsProvider>
            <AppContainer>
                <Header />
                <div className="content">
                    <React.Suspense fallback={<FullScreenLoading />}>
                        <Route name="home" component={Home} />
                        <Route name="dashboard" component={Dashboard} />
                        <Route name="seasonal" component={Seasonal} />
                        <Route name="schedule" component={Schedule} />
                        <Route name="standings" component={Standings} />
                        <Route name="rosters" component={Rosters} />
                        <Route name="polls" component={Polls} />
                        <Route name={groups.leagueGameBoxscores.routeNames} component={Boxscore} />
                        <Route name={groups.leagueTeamsFull.routeNames} component={Teams} />
                        <Route name={groups.admin.routeNames} component={Admin} />
                        <Route name={false} component={FourZeroFour} />
                    </React.Suspense>
                </div>
            </AppContainer>
        </WsProvider>
    );
};

export default AuthenticatedApp;
