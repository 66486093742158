import { colours } from 'app/theme/color-scheme';

import { IconDecorated, IconKeys } from '../icons';

import { MenuElementContainer } from './styles';

type Props = {
    title: string;
    counter?: number;
    active?: boolean;
    iconName: IconKeys;
    color: keyof typeof colours;
    isDark?: boolean;
    onClick?: () => void;
};

const MenuElement = ({ title, counter, active, iconName, color, isDark, onClick }: Props) => (
    <MenuElementContainer active={active} isDark={isDark} onClick={onClick}>
        <IconDecorated sizeRem={1} name={iconName} paletteColor={color} />
        <span className="title">{title}</span>
        {counter !== undefined && <span className="counter">{counter}</span>}
    </MenuElementContainer>
);

export default MenuElement;
