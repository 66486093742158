import { css } from '@emotion/react';

import { DialogButtons } from 'components/dialog/styles';
import Dropdown from 'components/dropdown';
import Modal from 'components/modal';
import Button from 'components/primitives/button';
import { Option } from 'types/controls';

// eslint-disable-next-line no-restricted-imports
import { useSelector } from '../../../../hooks/useSelector';
import { Attribute, Attributes } from '../attributes';
import { Card, Cards, Info, MappingDialogContainer, Title } from '../styles';

export interface MappingDialogProps<T> {
    isRemap: boolean;
    open: boolean;
    title: string;
    leftCard: {
        title: string;
        name: string;
        attributes: Attribute[];
    };
    rightCard: {
        title: string;
        name: (param: T) => string;
        attributes: (param: T | undefined) => Attribute[];
    };
    options: Option<T>[];
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    onConfirm: (param: T) => void;
    onCancel: (param: T | undefined) => void;
}

export const MappingDialog = <T extends object>({
    open,
    title,
    leftCard,
    options,
    confirmButtonLabel = 'Confirm Mapping',
    onConfirm,
    onCancel,
    cancelButtonLabel = 'Cancel',
    rightCard,
    isRemap,
}: MappingDialogProps<T>) => {
    const [selectedOption, opts, setSelectedOption] = useSelector({ options });

    const onSelectOption = (option: T) => {
        if (option) {
            const selected = options.find(({ value }) => value === option);

            selected && setSelectedOption(selected);
        }
    };

    return (
        <Modal
            style={{ padding: 0 }}
            open={open}
            onBackDropClick={() => {
                setSelectedOption(undefined);
                onCancel(selectedOption?.value);
            }}
        >
            <MappingDialogContainer>
                <Title>{title}</Title>
                <Cards>
                    <Card className={`left ${isRemap ? 'remap' : 'map'}`}>
                        <Title>{leftCard.title}</Title>
                        <Info>
                            <Title>{leftCard.name}</Title>
                            <Attributes attributes={leftCard.attributes} />
                        </Info>
                    </Card>
                    <Card className={`right ${selectedOption && 'selected'}`}>
                        <Title>{rightCard.title}</Title>
                        <Info>
                            <Dropdown
                                options={opts}
                                onChange={onSelectOption}
                                customStyles={css`
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 0.875rem;
                                    line-height: 1rem;
                                    text-transform: none;
                                    border: 0.0625rem solid
                                        var(--color-team-mappings-right-card-border);
                                `}
                                placeholderName={'-- Select --'}
                            />
                            <Attributes attributes={rightCard.attributes(selectedOption?.value)} />
                        </Info>
                    </Card>
                </Cards>
                <DialogButtons>
                    <Button
                        onMouseDown={() => {
                            setSelectedOption(undefined);
                            onCancel(selectedOption?.value);
                        }}
                        noBorder
                    >
                        {cancelButtonLabel}
                    </Button>
                    <Button
                        className="submit"
                        onMouseDown={() => {
                            if (selectedOption) {
                                onConfirm(selectedOption.value);
                                setSelectedOption(undefined);
                            }
                        }}
                        type="submit"
                    >
                        {confirmButtonLabel}
                    </Button>
                </DialogButtons>
            </MappingDialogContainer>
        </Modal>
    );
};
