import styled from '@emotion/styled';

export const FlagStatsContainer = styled.div`
    display: flex;
    justify-content: space-between;

    box-shadow: 0 0.125rem 0.25rem var(--color-flag-stats-box-shadow);
    border-radius: 0.1875rem;
    border: 0.0625rem solid var(--color-flag-stats-box-border);
    padding: 0.9375rem 1.0625rem;
`;

export const FlagStatsGroup = styled.div`
    display: flex;
    gap: 1rem;

    & > .stat {
        display: flex;
        gap: 0.25rem;
        align-items: center;

        font-weight: 700;
        font-size: 0.875rem;
    }
`;

export const IconContainer = styled.div<{ color: string; opacity: number }>`
    display: flex;
    background-color: rgba(var(--${(props) => props.color}-rgb), ${({ opacity }) => opacity});
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.15rem;
`;

export const RefreshAction = styled.div`
    display: flex;
    gap: 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    align-items: center;
`;

export const Refresh = styled.div`
    cursor: pointer;
    border-radius: 0.1875rem;
    border: 0.0625rem solid var(--color-flag-stats-refresh-border);
    padding: 0.2rem;
`;
