import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

export const DropdownPlaceholder = styled.div`
    display: flex;
    place-items: center;
    color: var(--color-text);
    border-radius: 0.2rem;
    padding: 0.5rem;
    width: 100%;
    font-weight: 700;
    font-size: 0.625rem;
    border: 0.0625rem solid var(--color-dropdown-border);
    user-select: none;
    text-transform: uppercase;
`;

export const DropdownOptionsContainer = styled.div<{
    placeholderWidth?: number;
    css: SerializedStyles;
}>`
    position: relative;
    cursor: pointer;
    max-height: 18rem;
    overflow-y: auto;
    color: var(--color-text);
    background-color: var(--color-background);
    border-radius: 0.2rem;
    border: 0.0625rem solid var(--color-dropdown-border);
    padding: 0 0.5rem;
    font-size: 0.625rem;
    text-transform: uppercase;
    ${(props) => props?.placeholderWidth && `min-width: ${props.placeholderWidth / 16}rem;`}
    ${(props) => props.css};

    .input-container {
        position: sticky;
        top: 0;
        width: 100%;
        padding: 0.5rem 0;
        margin-bottom: -0.5rem;
        background: inherit;
    }

    .input-container > .input {
        border: none;
        width: inherit;
        color: inherit;
        outline: none;
        border-bottom: 1px solid var(--color-dropdown-border);
        background: inherit;
        font-family: inherit;
        font-size: inherit;
    }

    .option-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem 0;
    }
`;
