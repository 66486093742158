import { FlagType } from 'api/types/bff';
import { DiscrepancyStatus } from 'api/types/flags';

import Loading from '../loading';

import Flag from './flag';
import { FlagAccordionContainer } from './styles';

type Props = {
    flags?: FlagType[];
    loading: boolean;
    moduleHeader?: boolean;
    status?: DiscrepancyStatus;
};

const FlagAccordion = ({ loading, moduleHeader, flags, status }: Props) => {
    if (loading) {
        return <Loading mt={2} />;
    }

    return (
        <FlagAccordionContainer data-testid="flag-accordion-container">
            <div className="flags-list" data-testid='"flag-accordion-container-flags-list'>
                {flags?.map((flag) => (
                    <Flag
                        key={`flag-${flag.id}`}
                        flag={flag}
                        moduleHeader={moduleHeader}
                        status={status}
                    />
                ))}
            </div>
        </FlagAccordionContainer>
    );
};

export default FlagAccordion;
