import { SourceId } from 'types';

import useMappingStats, { MappingsStats, MappingsType } from './useMappingsStats';

const getAutoMappedCount = (
    league: string,
    source: SourceId,
    type: MappingsType,
    stats: MappingsStats,
) => stats[league]?.[type]?.[source]?.autoMapped ?? 0;

const getManuallyMappedCount = (
    league: string,
    source: SourceId,
    type: MappingsType,
    stats: MappingsStats,
) => stats[league]?.[type]?.[source]?.manuallyMapped ?? 0;

const getAutoUnmappedCount = (
    league: string,
    source: SourceId,
    type: MappingsType,
    stats: MappingsStats,
) => stats[league]?.[type]?.[source]?.autoUnmapped ?? 0;

const getManuallyUnmappedCount = (
    league: string,
    source: SourceId,
    type: MappingsType,
    stats: MappingsStats,
) => stats[league]?.[type]?.[source]?.manuallyUnmapped ?? 0;

export type AggregatedMappingStats = {
    autoMapped: number;
    manuallyMapped: number;
    unmapped: number;
};

function useSourceStatsAggregated(
    league: string,
    source: SourceId,
    teamId: string,
): { data: AggregatedMappingStats } {
    const { data } = useMappingStats(league, teamId);

    if (!data) {
        return {
            data: {
                autoMapped: 0,
                manuallyMapped: 0,
                unmapped: 0,
            },
        };
    }

    return {
        data: {
            autoMapped:
                getAutoMappedCount(league, source, MappingsType.Players, data) +
                getAutoMappedCount(league, source, MappingsType.Schedules, data),
            manuallyMapped:
                getManuallyMappedCount(league, source, MappingsType.Players, data) +
                getManuallyMappedCount(league, source, MappingsType.Schedules, data),
            unmapped:
                getAutoUnmappedCount(league, source, MappingsType.Players, data) +
                getAutoUnmappedCount(league, source, MappingsType.Schedules, data) +
                getManuallyUnmappedCount(league, source, MappingsType.Players, data) +
                getManuallyUnmappedCount(league, source, MappingsType.Schedules, data),
        },
    };
}

export default useSourceStatsAggregated;
