import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ThemeIconContainer = styled.div`
    display: flex;

    height: 2rem;
    gap: 0.7rem;
    background-color: var(--black400);
    border-radius: 1rem;
    align-items: center;
    padding: 1rem 0.5rem;
`;

export const ThemeOption = styled.div<{ active?: boolean }>`
    border-radius: 1rem;
    width: 2.5rem;
    padding: 0.2rem 0.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        margin-top: 0.1rem;
        width: inherit;
        height: 0.8rem;
        fill: var(--black100);
    }

    & > .icon-wrapper {
        width: 100%;
    }

    ${(props) =>
        props.active &&
        css`
            background-color: var(--gray100);

            svg {
                fill: var(--color-theme-switch-icon-active);
            }
        `}
`;
