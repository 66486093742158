import {
    Accordion as MUIAccordion,
    AccordionDetails as MUIAccordionDetails,
    AccordionSummary as MUIAccordionSummary,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Accordion = styled(MUIAccordion)({
    backgroundColor: 'var(--color-accordion-bg)',
    boxShadow: 'none',
    color: 'var(--color-text)',
    '&:before': {
        backgroundColor: 'var(--color-flags-accordion-borderline)',
    },
});

export const AccordionDetails = styled(MUIAccordionDetails)({});

export const AccordionSummary = styled(MUIAccordionSummary)({
    backgroundColor: 'transparent',
});
