import styled from '@emotion/styled';

import { ColorScheme } from 'app/theme/color-scheme';

type Props = {
    color: keyof ColorScheme;
};

export const FlagCountContainer = styled.div<Props>`
    display: flex;
    & > .text {
        padding: 0 0.2rem;
        text-align: center;
        font-weight: 700;
        color: var(${({ color }) => color});
    }
`;
