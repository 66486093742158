import { mqtt5 } from 'aws-iot-device-sdk-v2';
import * as React from 'react';

import Env from '../lib/env';
import WsContext from '../ws/ws-context';

/**
 * Subscribe to a topic
 * @param league
 * @param seasonYear
 * @param seasonType
 * @param topic must start with `/
 * @param qos how mqtt handles the message 0 pretty fast but less risk of delivery, 1 at least once, 2 exactly once
 *
 * @see https://docs.oasis-open.org/mqtt/mqtt/v5.0/os/mqtt-v5.0-os.html#_Toc3901234
 */

export function useSubscribeTopic(
    league: string,
    seasonYear: string,
    seasonType: string,
    topic: string,
    qos: mqtt5.QoS,
) {
    const { subscribe, unsubscribe, connected } = React.useContext(WsContext);
    const stage = Env.get('REACT_APP_STAGE');

    const topicWithStage = React.useMemo(
        () => `/${stage}/${league}/${seasonYear}/${seasonType}${topic}`,
        [league, seasonType, seasonYear, stage, topic],
    );

    React.useEffect(() => {
        if (!connected) {
            return;
        }

        subscribe(topicWithStage, qos);

        return () => {
            unsubscribe(topicWithStage);
        };
    }, [subscribe, unsubscribe, connected, topicWithStage, qos]);
}

export function useSubscribeTopicLazy() {
    const { subscribe, unsubscribe, connected } = React.useContext(WsContext);
    const stage = Env.get('REACT_APP_STAGE');

    const subscribeTopic = React.useCallback(
        (league: string, seasonYear: string, seasonType: string, topic: string, qos: mqtt5.QoS) => {
            if (!connected) {
                return;
            }

            const topicWithStage = `/${stage}/${league}/${seasonYear}/${seasonType}${topic}`;

            subscribe(topicWithStage, qos);
        },
        [connected, stage, subscribe],
    );

    const unsubscribeTopic = React.useCallback(
        (league: string, seasonYear: string, seasonType: string, topic: string) => {
            if (!connected) {
                return;
            }

            const topicWithStage = `/${stage}/${league}/${seasonYear}/${seasonType}${topic}`;

            unsubscribe(topicWithStage);
        },
        [connected, stage, unsubscribe],
    );

    return { subscribeTopic, unsubscribeTopic };
}
