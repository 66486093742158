import styled from '@emotion/styled';

export const DiscrepancyGroupContainer = styled.div`
    & > .header {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        margin-bottom: 1rem;

        & > .image-container {
            width: 3rem;
            height: 3rem;
        }

        & > .content-container {
            display: flex;
            flex-direction: column;
            row-gap: 0.24rem;
            justify-content: center;

            .team-icon {
                transform: scale(0.8);
            }

            .team-logo {
                transform: scale(1.2);
            }

            & > .team-name {
                margin: 0;
                font-size: 0.625rem;
                line-height: 0.75rem;
                font-weight: 700;
                text-transform: uppercase;
            }

            & > .title {
                margin: 0;
                font-size: 1.125rem;
                line-height: 1.3125rem;
                font-weight: 700;
            }
        }
    }

    & > .discrepancies-container {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        & > .empty-list-container {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            & > .icon-container {
                width: 1.25rem;
                height: 1.25rem;
                color: var(--green500);
            }

            & > .label {
                color: var(--color-live-game-no-flags-label);
                font-size: 0.875rem;
                line-height: 1rem;
            }
        }
    }
`;
