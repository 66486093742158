import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { SystemEvents } from 'api/types/system-events';

import { SystemEventsKeys } from '../queries';

const getSystemEvent = async (pk?: string, sk?: string): Promise<SystemEvents.SystemEvent> => {
    const endpoint = `${API.SYSTEM_EVENTS_API}/events/pk/${encodeURIComponent(pk ?? '')}/sk/${sk}`;
    const response = await client.get(endpoint);

    return response?.data;
};

export default function useSystemEvent(pk?: string, sk?: string) {
    return useQuery(SystemEventsKeys.getSystemEvent(pk, sk), () => getSystemEvent(pk, sk), {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60,
        cacheTime: 1000 * 60 * 5,
        enabled: !!pk && !!sk,
    });
}
