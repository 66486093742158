import { useQuery } from 'react-query';

import API from '../api';
import client from '../api/client';

import { UserManagementKeys } from './queries';
import { User } from './user';

const fetchCurrentUser = async (): Promise<User> => {
    const response = await client.get<User>(`${API.USER_MANAGEMENT_API}/user/current`);

    return response?.data;
};

export function useCurrentUser() {
    const queryResult = useQuery(UserManagementKeys.currentUser(), () => fetchCurrentUser(), {
        refetchOnWindowFocus: false,
    });

    return {
        currentUser: queryResult.data,
        loading: queryResult.isLoading,
    };
}
