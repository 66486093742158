import { SourceId } from 'types';

import useMappingStats, { MappingsStats, MappingsType } from './useMappingsStats';

export const getSources = (league: string, stats: MappingsStats): SourceId[] =>
    Array.from(
        new Set(
            Object.keys(stats[league]?.schedules ?? {}).concat(
                Object.keys(stats[league]?.players ?? {}),
            ),
        ),
    ) as SourceId[];

const getUnmappedCount = (
    league: string,
    source: SourceId,
    type: MappingsType,
    stats: MappingsStats,
): number =>
    (stats[league]?.[type]?.[source]?.autoUnmapped ?? 0) +
    (stats[league]?.[type]?.[source]?.manuallyUnmapped ?? 0);

export type SourceStatsUnmapped = {
    source: SourceId;
    value: number;
};

function useSourceStatsUnmapped(league: string, teamId: string) {
    const { data, isLoading, error } = useMappingStats(league, teamId);

    if (!data) {
        return { data: [] as SourceStatsUnmapped[], isLoading, error };
    }

    const result: SourceStatsUnmapped[] = [];

    getSources(league, data)
        .reduce((acc, current) => {
            acc.set(
                current,
                acc.get(current) ??
                    0 +
                        getUnmappedCount(league, current, MappingsType.Players, data) +
                        getUnmappedCount(league, current, MappingsType.Schedules, data),
            );

            return acc;
        }, new Map<SourceId, number>())
        .forEach((value, source) => {
            result.push({ source, value });
        });

    return { data: result, isLoading, error };
}

export default useSourceStatsUnmapped;
